<app-uni-page [parent]="this" pageTitle="Vouchers and Invoices" [bodyTemplate]="bodyTemplate"
  [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <as-split unit="percent" direction="vertical">
    <as-split-area [size]="50">
      <app-ag-grid [gridOptions]="invhGridOptions"></app-ag-grid>
    </as-split-area>

    <as-split-area [size]="50">
      <app-ag-grid [gridOptions]="invdGridOptions" [rowData]="invoiceDetails"></app-ag-grid>
    </as-split-area>
  </as-split>
</ng-template>

<ng-template #footerTemplate>
  <mat-spinner class="mt-12 ml-12" diameter="30" *ngIf="busyService.isBusy | async; else notBusy"></mat-spinner>
  <ng-template #notBusy>
    <div class="left-buttons">
    </div>
    <div>
      <button mat-raised-button (click)="goShipping()">Go to Shipping and Invoicing</button>
      <button mat-raised-button (click)="goBack()">
        <span>Go Back</span>
      </button>
    </div>
  </ng-template>
</ng-template>