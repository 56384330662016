import { GridOptions, RowDoubleClickedEvent, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ProductType, Product, Manufacturer } from 'app/model/entities/entity-model';
import { AgFns, ISortModel } from 'app/shared/ag-fns';
import { DbQueryService } from 'app/shared/db-query.service';
import { DialogService } from 'app/shared/dialog.service';
import { UtilFns } from 'app/shared/util-fns';

export interface ProductFinderDialogComponentData {
  // only one or the other should be set 
  // if manufacturer is set - the base query is changed
  // if productType is set only the filter is changed
  manufacturer?: Manufacturer;
  productType?: ProductType;
}


@Component({
  templateUrl: './product-finder-dialog.component.html'
})
export class ProductFinderDialogComponent {
  targetProductType: ProductType;
  targetManufacturer: Manufacturer;
  issGridOptions: GridOptions;
  selectedProduct: Product;

  static async show(matDialog: MatDialog, data: ProductFinderDialogComponentData) {
    const r = await matDialog.open(ProductFinderDialogComponent, {
      disableClose: true,
      height: '530px',
      width: '800px',
      data: data
    })
      .afterClosed().toPromise();
    return <Product>r;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: ProductFinderDialogComponentData, public dialogRef: MatDialogRef<ProductFinderDialogComponent>,
    private dbQueryService: DbQueryService, private dialogService: DialogService) {

    this.targetProductType = data.productType;
    this.targetManufacturer = data.manufacturer;

    this.prepare();

  }

  async prepare() {
    this.issGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onIssGridReady,
      onRowSelected: this.onIssRowSelected,
      onRowDoubleClicked: this.onIssRowDoubleClick,
      rowModelType: 'serverSide',
      singleClickEdit: true,
      stopEditingWhenCellsLoseFocus: true,
    })
  }

  onIssGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'productType.manufacturer.name' },
      { headerName: 'Description', field: 'productType.description', filter: 'agTextColumnFilter' },
      { headerName: 'SKU', field: 'productType.style', filter: 'agTextColumnFilter' },
      { headerName: 'Features', field: 'featureChoicesExtract', filter: 'agTextColumnFilter' },

    ];
    if (this.targetManufacturer == null) {
      colDefs[0].filter = 'agTextColumnFilter';
    }
    const sortModel = [
      { colId: 'productType.manufacturer.name', sort: 'asc' },
      { colId: 'productType.description', sort: 'asc' },
      { colId: 'productType.style', sort: 'asc' },
      
    ] as ISortModel;
    AgFns.initGrid(this.issGridOptions, colDefs, sortModel);
    this.filterByProductType();

    this.updateDatasource();
  }

  updateDatasource() {
    const gridApi = this.issGridOptions && this.issGridOptions.api;
    if (gridApi == null) {
      return;
    }

    let q = this.dbQueryService.createProductQuery();
    if (this.targetManufacturer != null) {
      q = q.where({ 'productType.manufacturerId': this.targetManufacturer.id });
    }
    const ds = AgFns.buildDatasource(() => q);
    gridApi.setServerSideDatasource(ds);
  }

  private async filterByProductType() {
    if (this.targetProductType == null) {
      return;
    }
    const gridApi = this.issGridOptions && this.issGridOptions.api;
    if (gridApi == null) {
      return;
    }

    let filter = gridApi.getFilterInstance('productType.manufacturer.name');
    filter.setModel({
      filterType: 'text',
      type: 'equals',
      filter: this.targetProductType.manufacturer.name
    });
    filter = gridApi.getFilterInstance('productType.style');
    filter.setModel({
      filterType: 'text',
      type: 'equals',
      filter: this.targetProductType.style
    });
    gridApi.onFilterChanged();
    await UtilFns.wait(0)
  }

  async onIssRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    this.selectedProduct = e.data as Product;
  }

  onIssRowDoubleClick(e: RowDoubleClickedEvent) {
    if (!e.node.isSelected()) {
      return;
    }
    const iss = e.data as Product;
    this.dialogRef.close(iss);
  }

  ok() {
    this.dialogRef.close(this.selectedProduct);
  }

  cancel() {
    this.dialogRef.close(null);
  }

}
