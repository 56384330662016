<app-uni-page  [parent]="this" pageTitle="User Accounts" [bodyTemplate]="bodyTemplate"
  [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <div *ngIf="isPageReady" class="content-card" > 
    <app-ag-grid [gridOptions]="userGridOptions" [rowData]="users" ></app-ag-grid>
    <ng-template #editButtonsCell let-row>
      <button (click)="editUser(row)">Edit</button>
    </ng-template>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <button mat-raised-button (click)="addUser()">
      <span>Add User</span>
    </button>
  </div>
  <button mat-raised-button (click)="goBack()">
    <span>Go Back</span>
  </button>
</ng-template>