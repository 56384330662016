<app-uni-page  *ngIf="isPageReady" [parent]="this" pageTitle="Job Orders {{getTitle()}}" 
  [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <as-split  unit="percent" direction="vertical">

    <as-split-area [size]="50">

      <app-ag-grid #gridJoHeaders [gridOptions]="johGridOptions"></app-ag-grid>

      <!-- <ng-template #addRemoveBatchCell let-row>
        <button *ngIf="canAddToBatch(row)" (click)="addToBatch(row)">Add</button>
        <button *ngIf="canRemoveFromBatch(row)" (click)="removeFromBatch(row)">Remove</button>
      </ng-template>

      <ng-template #editBatchCell let-row>
        <button *ngIf="canEditBatch(row)" (click)="editBatch(row)">Edit -
          {{ row.joHeaderPull.joPullBatchId }}</button>
      </ng-template> -->

      <ng-template #invoiceCell let-row>
        <button *ngIf="canEditInvoices(row)" class="mr-4" (click)="goInvoicesForJo(row, 'E')">Edit</button>
        <button *ngIf="canViewInvoices(row)" class="mr-4" (click)="goInvoicesForJo(row, 'V')">View</button>
        
      </ng-template>

      <ng-template #auditJoCell let-row>
        <button (click)="goJoAudit(row)">Audit JO</button>
      </ng-template>

      <ng-template #editJohNotesCell let-row>
        <button [ngClass]="{'btn-has-data': hasJohNotes(row)}" (click)="editJohNotes(row)">Notes</button>
      </ng-template>

      <ng-template #addReturnRequestCell let-row>
        <button *ngIf="canAddEditReturnRequest(row)" (click)="addEditReturnRequest(row)">Add/Edit Return Request</button>
      </ng-template>

      <ng-template #viewDocCell let-row>
        <button *ngIf="hasAddonDocs(row)" (click)="viewAddonDocs(row)">View Documents</button>
      </ng-template>

    </as-split-area>

    <as-split-area [size]="50">
      <mat-tab-group (selectedTabChange)="onTabChanged($event)">
        <mat-tab label="Details" class="h-80">
          <app-ag-grid #gridJoDetails [gridOptions]="jodGridOptions" [rowData]="joDetails" ></app-ag-grid>
          <ng-template #editJodNotesCell let-row>
            <button [ngClass]="{'btn-has-data': hasJodNotes(row)}" (click)="editJodNotes(row)">Notes</button>
          </ng-template>
        </mat-tab>
        <mat-tab label="Information">
          <app-jo-info [entity]="currentJoHeader"></app-jo-info>
        </mat-tab>
        <mat-tab label="Billing Address">
          <app-address-component [entity]="currentJoHeader" [prefix]="'billing'"></app-address-component>
        </mat-tab>
        <mat-tab label="Shipping Address">
          <app-address-component [entity]="currentJoHeader" [prefix]="'shipping'"></app-address-component>
        </mat-tab>


        <mat-tab label="Purchase Orders">
          <app-ag-grid [gridOptions]="podGridOptions" [rowData]="poDetails"></app-ag-grid>
        </mat-tab>


        <mat-tab *ngIf="!isModeIncomplete()" label="Invoices">
          <app-ag-grid [gridOptions]="invhGridOptions" [rowData]="invoiceHeaders"></app-ag-grid>

          <ng-template #invdButtonsCell let-row>
            <button (click)="goPrintableInvoice(row)">View Invoice</button>
          </ng-template>
        </mat-tab>
        <mat-tab label="Returns">
          <app-ag-grid [gridOptions]="rradGridOptions" [rowData]="returnRequestDetails"></app-ag-grid>
        </mat-tab>
      </mat-tab-group>

    </as-split-area>
  </as-split>

</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <mat-button-toggle-group [(value)]="currentJoStatus" class="ml-12" aria-label="Job Order Status">
      <mat-button-toggle *ngFor="let joStatus of allStatuses" [value]="joStatus">{{joStatus.name}}
      </mat-button-toggle>
    </mat-button-toggle-group>

    <button *ngIf='isModeIncomplete()' class="ml-24" [disabled]="joPullBatchId==null" mat-raised-button
      (click)="createNewBatch()">
      <span>New Batch</span>
    </button>
  </div>
  <div>
    <button mat-raised-button (click)="goManifests()">
      <span>Manifests</span>
    </button>
    <!-- <button mat-raised-button (click)="goAddonWIP()">
      <span>Addons in Progress</span>
    </button>
    <button mat-raised-button (click)="goInvoices()">
      <span>Invoices</span>
    </button>
    <button id="shipping" mat-raised-button (click)="goShipping()">
      <span>Shipping</span>
    </button> -->
    <button id="return-request-auth" mat-raised-button (click)="goReturnRequest()">
      <span>Return Requests</span>
    </button>
    <button id="jo-order-audit" mat-raised-button (click)="goAudit()">
      <span>Job Order Audit</span>
    </button>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>
