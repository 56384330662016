// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ItemDetailHist extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: string;
  accountId: number;
  barcodePrintStatusId: number;
  crtnTs: Date;
  invoiceHeaderId: string;
  itemBinId: number;
  itemDetailStatusId: number;
  joDetailId: number;
  lastScanTs: Date;
  poDetailId: number;
  poTrxId: number;
  productId: number;
  rowVersion: any;
}

