<app-uni-page  [parent]="this" *ngIf="isPageReady" pageTitle="Purchase Orders {{ getTitle() }}" 
  [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <as-split unit="percent" direction="vertical">
    
    <as-split-area [size]="50">
      <app-ag-grid [gridOptions]="poGridOptions"></app-ag-grid>
    </as-split-area>

    <as-split-area [size]="50">
      <mat-tab-group (selectedTabChange)="onTabChanged($event)">
        <mat-tab label="Purchase Order Details">
          <app-ag-grid [gridOptions]="podGroupGridOptions" [rowData]="podGroups"></app-ag-grid>
          <ng-template #editPodCell let-row>
            <button *ngIf="canEditPod(row)" (click)="editPod(row)">Edit</button>
            <button *ngIf="canDeletePod(row)" (click)="deletePod(row)">Delete</button>
          </ng-template>
          <ng-template #editPodNotesCell let-row>
            <button [ngClass]="{'btn-has-data': hasPodNotes(row)}" (click)="editPodNotes(row)">Notes</button>
          </ng-template>
        </mat-tab>
        <mat-tab label="Job Orders">
          <app-ag-grid [gridOptions]="joGridOptions" [rowData]="jobOrders"></app-ag-grid>
        </mat-tab>
      </mat-tab-group>
    </as-split-area>

  </as-split>
</ng-template>

<ng-template #footerTemplate>

  <div class="left-buttons">
    <mat-button-toggle-group [(value)]="buttonPoStatus" class="ml-12" aria-label="Purchase Order Status">
      <mat-button-toggle *ngFor="let poStatus of allStatusesExt" [value]="poStatus">{{poStatus.name}}
      </mat-button-toggle>
    </mat-button-toggle-group>
    <button  *ngIf="isUnpostedOnly" mat-raised-button (click)="createNewPo()">
      Create New 'Stock' PO
    </button>
  </div>
  <div>
    <!-- <button mat-raised-button (click)="createPurchaseOrders()">
      Import
    </button> -->
    <button mat-raised-button (click)="viewBackorders()">
      Backorders
    </button>
    <button mat-raised-button (click)="viewEOQPurchaseOrdersNeeded()">
      EOQ Purchase Orders needed
    </button>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>

</ng-template>
