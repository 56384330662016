<app-uni-page  [parent]="this" pageTitle="Back Orders for Manufacturer: {{ getTitle() }}"
  [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <div *ngIf="!isPageReady" class="content-card" style="flex-grow: 1">
    <mat-spinner class="mt-12 ml-12" diameter="50"></mat-spinner>
  </div>
  <div *ngIf="isPageReady" class="content-card" style="flex-grow: 1">
    <app-ag-grid [gridOptions]="pombGridOptions" [rowData]="pombItems" ></app-ag-grid>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <button [disabled]="isBusy" *ngIf="this.uow.hasChanges()" mat-raised-button (click)="saveChanges()">
      <span>Save Changes</span>
    </button>
    <button [disabled]="isBusy" *ngIf="this.uow.hasChanges()" mat-raised-button (click)="cancelChanges()">
      <span>Cancel Changes</span>
    </button>
  </div>
  <div>
    <button [disabled]="isBusy" mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>