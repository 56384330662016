<app-uni-page  [parent]="this" pageTitle="Job Order Shipping - {{ title }}" [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <as-split unit="percent" direction="vertical">

    <as-split-area [size]="50">
      <div uni-flex-row class="my-4" >
        <div class="h2 ml-12">Shipping Bins - by Addresses</div>
        <mat-checkbox class="ml-8" [(ngModel)]="showCurrentBinsOnly" [ngModelOptions]="{standalone: true}"
          (ngModelChange)="refreshAndUpdate()">
          Show Only Bins Shipping Today
        </mat-checkbox>
      </div>
      <div style="width: 100%; height: calc(100% - 28px);">
        <app-ag-grid [gridOptions]="shipBinGridOptions" [rowData]="shipBins">></app-ag-grid>
      </div>
    </as-split-area>

    <as-split-area [size]="50">
      <div class="h2 ml-12">Vouchers</div>
      <div style="width: 100%; height: calc(100% - 28px);">
        <app-ag-grid [gridOptions]="invhGridOptions" [rowData]="invoiceHeaders">></app-ag-grid>
      </div>

    </as-split-area>

  </as-split>
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <button [disabled]="(!canShip()) || currentShipment==null" mat-raised-button (click)="setNewShipment()">
      <span>New Shipment</span>
    </button>
    <button [disabled]="(!canShip()) || !canAddAllToCurrentShipment()" mat-raised-button (click)="addAllToCurrentShipment()">
      <span>Add all available to current shipment</span>
    </button>
  </div>
  <div>
    <button mat-raised-button (click)="editShipFrequencies()">
      <span>Ship Frequencies</span>
    </button>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>
