<app-uni-page [parent]="this" *ngIf="isPageReady" pageTitle="Features" [bodyTemplate]="bodyTemplate"
  [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <!-- <mat-spinner class="mt-12 ml-12" diameter="30" *ngIf="busyService.isBusy | async;"></mat-spinner> -->
  <as-split unit="percent" direction="vertical">

    <as-split-area [size]="50">
      <app-ag-grid [gridOptions]="featureGridOptions" [rowData]="features"></app-ag-grid>
    </as-split-area>

    <as-split-area [size]="50">
      <mat-tab-group (selectedTabChange)="onTabChanged($event)">
        <mat-tab label="Feature Choices">
          <as-split unit="percent" direction="horizontal">
            <as-split-area [size]="40">
              <h3 class="ml-8 mb-2">Sizes
                <mat-checkbox [disabled]="!user.isProductAdmin" style="width: 250px;" placeholder="Allow Combination" [(ngModel)]="allowCombination"  [ngModelOptions]="{standalone: true}" 
                (ngModelChange)="onAllowCombinationChange()" >
                  Allow Combination
                </mat-checkbox>
              </h3>
              <app-ag-grid [gridOptions]="featureChoiceGridOptions" [rowData]="featureChoices"  style="height: calc(100% - 60px);" ></app-ag-grid>
            </as-split-area>
            <as-split-area [size]="60">
              <h3 class="ml-8 mb-2">Products with Selected Feature Choice</h3>
              <app-ag-grid [gridOptions]="productGridOptions"  style="height: calc(100% - 55px);" ></app-ag-grid>    
            </as-split-area>
          </as-split>

        </mat-tab>
        <mat-tab label="Product Types">
          <app-ag-grid [gridOptions]="productTypeGridOptions" ></app-ag-grid>
        </mat-tab>
      </mat-tab-group>
    </as-split-area>

  </as-split>
</ng-template>

<ng-template #footerTemplate>
  
  <div class="left-buttons">
    <button [disabled]="isBusy || !this.user.isProductAdmin" mat-raised-button (click)="editFeature()">
      <span>Add Feature</span>
    </button>
    <button [disabled]="isBusy" *ngIf="this.uow.hasChanges()" mat-raised-button (click)="saveChanges()">
      <span>Save Changes</span>
    </button>
    <button [disabled]="isBusy" *ngIf="this.uow.hasChanges()" mat-raised-button (click)="cancelChanges()">
      <span>Cancel Changes</span>
    </button>
  </div>
  <div>
    <button [disabled]="isBusy" mat-raised-button (click)="goProductTypes()">
      <span>Product Types</span>
    </button>
    <button [disabled]="isBusy" mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>