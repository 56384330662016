import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { RowSelectedEvent, GridOptions, CellClickedEvent } from '@ag-grid-community/core';
import { Feature, FeatureChoice, ProductType, Product, ProductFeatureChoice, ProductTypeFeatureMap, _MigrationFeatureChoiceCombine } from 'app/model/entities/entity-model';
import { AgFns, ISortModel } from 'app/shared/ag-fns';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { takeUntil } from 'rxjs/operators';
import { OneFieldDialogComponent } from 'app/shared/one-field-dialog.component';

import { Entity } from 'breeze-client';
import { UtilFns } from 'app/shared/util-fns';
import { FeatureChoiceDialogParams, FeatureDialogParams } from './edit-generic-entity-dialog.component';
import { EditFeatureChoiceDialogComponent } from './edit-feature-choice-dialog.component';
import { FeatureChangeDialogComponent } from './feature-change-dialog.component';
import { FeatureFinderDialogComponent } from './feature-finder-dialog.component';
import * as _ from 'lodash';
import { EditFeatureDialogComponent } from './edit-feature-dialog.component';

@Component({
  selector: 'feature',
  templateUrl: './feature.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class FeatureComponent extends DomainBaseComponent implements OnInit {
  @ViewChild('templateButtonsCell') templateButtonsCell: TemplateRef<any>;
  @ViewChild('templateMappingButtonsCell') templateMappingButtonsCell: TemplateRef<any>;
  @ViewChild('productTypeButtonsCell') productTypeButtonsCell: TemplateRef<any>;

  featureGridOptions: GridOptions;
  features: Feature[];
  selectedIst: Feature;

  featureChoiceGridOptions: GridOptions;
  featureChoices: FeatureChoice[] = [];
  selectedFeatureChoice?: FeatureChoice;
  allowCombination: boolean;

  productGridOptions: GridOptions;
  migrationProductTypeIdSet: Set<number> = new Set();
  
  productTypeGridOptions: GridOptions;

  shortBtnProps = { maxWidth: 60, minWidth: 60 }

  constructor(
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    protected matDialog: MatDialog,
    protected location: Location
  ) {
    super(domainService);
    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    this.featureGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onFeatureGridReady,
      onRowSelected: this.onFeatureRowSelected,
    });
    AgFns.captureGridRouteParams(this.featureGridOptions, this.route, 'id');

    this.featureChoiceGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onFeatureChoiceGridReady,
      onRowSelected: this.onFeatureChoiceRowSelected,
      rowSelection: 'multiple',
    });

    this.productGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onProductGridReady,
      rowModelType: 'serverSide'
    });

    this.productTypeGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onProductTypeGridReady,
      rowModelType: 'serverSide'
    });

    this.features = await this.dbQueryService.getFeatures();
    this.isPageReady = true;
  }

  async canDeactivate() {
    if (this.isBusy) return false;
    this.featureChoiceGridOptions.api.stopEditing();
      if (this.uow.hasChanges()) {
      const ynResult = await this.dialogService.askYesNo('Changes have not been saved', 'Do you want to save before exiting?');
      if (ynResult.index == 0) {
        await this.dbSaveService.saveChanges();
      } else {
        this.dbSaveService.rejectChanges();
      }
    }
    
    return this.busyService.busy(async () => {
      await UtilFns.wait(1);  
      this.uow.clearEntities(Feature);
      this.uow.clearEntities(FeatureChoice);
      this.uow.clearEntities(ProductType); 
      this.uow.clearEntities(Product); // lots of these could have been queried.
      this.uow.clearEntities(ProductFeatureChoice); // lots of these could have been queried.
      this.uow.clearEntities(ProductTypeFeatureMap);
    });
    return true;
  }

  async onFeatureGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Feature Name', field: 'name', filter: 'agTextColumnFilter' },
      { headerName: 'Document Name', valueGetter: params => params.data.docName ? params.data.docName : params.data.name },
      { headerName: 'Status', field: 'activeStatus.name', filter: 'agTextColumnFilter' },
      { headerName: 'Feature Id', field: 'id', filter: 'agNumberColumnFilter' },
    ];
    // show buttons iff user has Inventory role
    
    if (this.user.isProductAdmin) {
      const invColDefs = [
        { ...AgFns.createButtonProps('', this.addFeatureChoice.bind(this), { label: 'Add Feature Choice'} ) },
        { ...AgFns.createButtonProps('', this.editFeature.bind(this), { label: 'Edit Feature'} ) },
        { ...AgFns.createButtonProps('', this.updateFeatureChangeMapping.bind(this), { label: 'Update Feature Conversion Table'} ) },
        // No longer supported
        // { ...AgFns.createButtonProps('', this.deleteFeature.bind(this), { label: 'Delete Template'} ) },
      ]
      colDefs.splice(1, 0, ...invColDefs as any[]);
    }

    const sortModel = [
        { colId: 'name', sort: 'asc' as const },
      ];
    
    AgFns.initGrid(this.featureGridOptions, colDefs, sortModel, true);
    await AgFns.applyGridRouteParams(this.featureGridOptions);
    AgFns.selectDefaultRow(this.featureGridOptions);
    
  }

  async onFeatureChoiceGridReady(evt: any) {
    const cellStyle = this.user.isProductAdmin ? AgFns.setEditableStyle : ((p) => null);
    const colDefs = [
      { headerName: 'Order', field: 'displayOrder', filter: 'agTextColumnFilter', editable: this.user.isProductAdmin,  cellStyle: cellStyle, minWidth: 80, maxWidth: 80 },
      { headerName: 'Value', field: 'choiceValue', filter: 'agTextColumnFilter' },
      { headerName: 'Reorder Value', field: 'reorderFeatureChoice.choiceValue', filter: 'agTextColumnFilter' },
      { ...AgFns.createButtonProps('', this.migrateProductTypes.bind(this), { label: 'Migrate To..' }) ,  minWidth: 120 },
      { headerName: 'Status', field: 'activeStatus.name', filter: 'agTextColumnFilter', }
    ];
    // show buttons iff user has Inventory role
    if (this.user.isProductAdmin) {
      const invColDefs = [
        { ...AgFns.createButtonProps('Combine', this.onCombine.bind(this), { label: 'Combine', canDisplay: this.canCombine.bind(this) }), 
          colId: 'combine', checkboxSelection: true, width: 150 
        },
        { ...AgFns.createButtonProps('', this.editFeatureChoice.bind(this), { label: 'Edit'} ), ...this.shortBtnProps },
        // No longer supported
        // { ...AgFns.createButtonProps('', this.deleteFeatureChoice.bind(this), { label: 'Delete'}), ...this.shortBtnProps },
      ]
      colDefs.splice(3, 0, ...invColDefs as any[]);
    }
    const sortModel = [
      { colId: 'displayOrder', sort: 'asc' as const },
    ];
    AgFns.initGrid(this.featureChoiceGridOptions, colDefs, sortModel, true);
    this.onAllowCombinationChange();
  }

  

  async onProductGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'productType.manufacturer.name', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'productType.description', filter: 'agTextColumnFilter' },
      { headerName: 'SKU', field: 'productType.style', filter: 'agTextColumnFilter' },
      { headerName: 'Features', field: 'featureChoicesExtract',  },
      { ...AgFns.createButtonProps('', this.editProductType.bind(this), 
        { label: 'Edit Product Type', calcClass: (row) => this.hasMigrationData(row) ? 'btn-migration-in-process' : '' }) , minWidth: 135
      },
      { headerName: 'Status', field: 'activeStatus.name',  },
      { headerName: 'Product Type Id', field: 'productTypeId', filter: 'agNumberColumnFilter' },
    ]
    
    const sortModel = [
      { colId: 'productType.manufacturer.name', sort: 'asc' },
      { colId: 'productType.description', sort: 'asc' },
      { colId: 'productType.style', sort: 'asc' },
    ] as ISortModel;
    AgFns.initGrid(this.productGridOptions, colDefs, sortModel, true);
  }

  hasMigrationData(product: Product) {
    return this.migrationProductTypeIdSet.has(product.productTypeId);
  }

  async migrateProductTypes(featureChoice: FeatureChoice) {

    const mfccs = await this.dbQueryService.getMigrationFeatureChoiceChanges(featureChoice.feature._origName);
    var origToFeatureNames = _.uniq(mfccs.map(x => x.toOrigFeatureName));
    var includedFeatures =  origToFeatureNames.map(n => this.features.find(f => n == f._origName));
    if (includedFeatures.length == 0) {
      this.dialogService.showOkMessage("Feature has no registered feature conversions", "There are no registered feature conversions for this feature. Please add these conversions first.");
      return;
    }
    const features = await FeatureFinderDialogComponent.show(this.matDialog, { 
      includedFeatureIds: includedFeatures.map(x => x.id),
      rowSelection: 'single'
    });
    if (features.length != 1) return;
    const count = await this.dbSaveService.migrateFeatureChoice(featureChoice.id, features[0].id);
    this.dialogService.toast(`${count} Product Types Migrated`);
    await this.refreshFeatureChoice(featureChoice);
  }

  async onProductTypeGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'manufacturer.name', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'description', filter: 'agTextColumnFilter' },
      { headerName: 'SKU', field: 'style', filter: 'agTextColumnFilter' },
      { headerName: 'Status', field: 'activeStatus.name',  },
      { headerName: 'Feature Names', field: 'featureNamesExtract'},
      { headerName: 'Product Type Id', field: 'id', filter: 'agNumberColumnFilter' },
      { ...AgFns.createButtonProps('', this.editProductType.bind(this), { label: 'Edit Product Type'} ) },
    ];
    const sortModel = [
      { colId: 'manufacturer.name', sort: 'asc' },
      { colId: 'description', sort: 'asc' },
      { colId: 'style', sort: 'asc' },
    ] as ISortModel;
    AgFns.initGrid(this.productTypeGridOptions, colDefs, sortModel, true);
  }

  async onFeatureRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    const template = e.data as Feature;
    if (!template) {
      return;
    }
    this.selectedIst = template;
    this.updateLocation(template.id);
    
    await AgFns.busyGrid([this.featureChoiceGridOptions, this.productTypeGridOptions], this.busyService, async() => {
      this.featureChoices = await this.dbQueryService.getFeatureChoices(template.id);
    });
    this.setProductTypesDatasource();

    await UtilFns.wait(100);
    AgFns.selectDefaultRow(this.featureChoiceGridOptions);
  }

  private setProductTypesDatasource() {
    const ds = AgFns.buildDatasource(() => this.dbQueryService.createProductTypesForFeatureQuery(this.selectedIst.id));
    this.productTypeGridOptions.api.setServerSideDatasource(ds);
  }

  onAllowCombinationChange() {
    this.featureChoiceGridOptions.columnApi?.setColumnsVisible(['combine'], this.allowCombination);
    this.featureChoiceGridOptions.columnApi?.setColumnWidth('combine', 115);
  }

  async onFeatureChoiceRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    const fc = e.data as FeatureChoice;
    if (!fc) {
      return;
    }
    await this.refreshFeatureChoice(fc);

  }

  private async refreshFeatureChoice(fc: FeatureChoice) {
    this.selectedFeatureChoice = fc;
    this.setFeatureChoiceQueryDatasource();
    const productTypeIds = await this.dbQueryService.getProductTypeIdsWithMigrationsForFeatureChoice(fc.id);
    this.migrationProductTypeIdSet = new Set(productTypeIds);
  }

  private setFeatureChoiceQueryDatasource() {
    const ds = AgFns.buildDatasource(() => this.dbQueryService.createProductsForFeatureChoiceQuery(this.selectedFeatureChoice.id));
    this.productGridOptions.api.setServerSideDatasource(ds);
  }

  canCombine(combineIstm: FeatureChoice) {
    if (this.featureChoiceGridOptions.api == null) {
      return false;
    }
    if (this.featureChoiceGridOptions.api.getSelectedRows().length > 2) {
      this.dialogService.toast('Only two features may be combined at one time.');
      this.featureChoiceGridOptions.api.deselectAll();
      return false;
    }

    return combineIstm == this.selectedFeatureChoice && this.featureChoiceGridOptions.api.getSelectedRows().length == 2;
  }

  async onCombine(combineFc: FeatureChoice, event?: CellClickedEvent, origEvent?: UIEvent) {
    // Needed to stop onRowSelected call from being made right after this call OR during any await call within this method. 
    // The askIfOk call below is async and the OnRowSelected will get called during its execution which will in turn
    // deselect all of the rows except the current one.
    origEvent?.stopPropagation();

    // if (this.hasChanges()) {
    //   this.toastr.warning('Please save or undo before attempting to combine');
    //   return;
    // }

    if (combineFc == null) {
      return;
    }
    const fromFcs = (this.featureChoiceGridOptions.api?.getSelectedRows() as FeatureChoice[])
      .filter(x => x != combineFc);
    if (fromFcs.length != 1) {
      return;
    }

    const numProductsAffected = await this.dbQueryService.getProductsCountWithFeatureChoice(fromFcs[0].id);
    if (numProductsAffected > 100) {
      await this.dialogService.showOkMessage('Too many products affected', `This operation will change ${numProductsAffected} products.
      This will take too long to process interactively here - please contact your admin`);
      return;
    }
    
    const ynResult = await this.dialogService.askYesNo('Combine Feature Choices', 'Are you sure that you want to combine these Feature Choices?');
    if (ynResult.index != 0) {
      return;
    }

    const fromFcsIds = fromFcs.map(x => x.id);
    

    const count = await this.dbSaveService.combineFeatureChoices(fromFcsIds[0], combineFc.id);
    if (count > 0) {
      const mfcComb = this.dbSaveService.createEntity(_MigrationFeatureChoiceCombine,  {
        origFeatureName: fromFcs[0].feature._origName,
        fromOrigFeatureChoiceName: fromFcs[0]._origChoiceValue,
        toOrigFeatureChoiceName: combineFc._origChoiceValue
      });
      await this.dbSaveService.saveSelectedChanges([mfcComb]);
    }

    this.featureChoices = await this.dbQueryService.getFeatureChoices(this.selectedIst.id);
    AgFns.refreshGrid(this.featureChoiceGridOptions, this.featureChoices);

    this.uow.clearEntities(Product);
    this.uow.clearEntities(ProductFeatureChoice);
    this.uow.clearEntities(ProductTypeFeatureMap);
    
    this.productGridOptions.api.refreshServerSide();
    

    this.dialogService.toast(`${count} Products(s) updated as a result of combining these feature choices`);
  }


  async onTabChanged(evt: any) {
    // var tabName =  evt.tab.textLabel;
    // hack: because of ag-grid issues with button redraw on ngIf'd tab.
    this.featureChoiceGridOptions.columnApi.autoSizeAllColumns();
    this.productGridOptions.columnApi.autoSizeAllColumns();
    this.productTypeGridOptions.columnApi.autoSizeAllColumns();
  }

  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/feature']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.featureGridOptions, key && key.toString());
    this.domainService.location.replaceState(url);
  }

  // also handles adds
  async editFeature(feature?: Feature) {
    const r = await EditFeatureDialogComponent.show(this.matDialog,  { feature: feature });
     if (r) {
      if (feature == null) {
        this.features = await this.dbQueryService.getFeatures();
      }
      AgFns.refreshGrid(this.featureGridOptions, this.features);
     }
  }

  async updateFeatureChangeMapping(feature: Feature) {
    const x = await FeatureChangeDialogComponent.show(this.matDialog,    { fromFeatureId: feature.id  }  );
  }

  async addFeatureChoice(feature: Feature) {
    const r = await EditFeatureChoiceDialogComponent.show(this.matDialog, { feature: feature });
     if (r) {
      this.featureChoices = await this.dbQueryService.getFeatureChoices(feature.id);
      AgFns.refreshGrid(this.featureChoiceGridOptions, this.featureChoices);
     }
  }

  async editFeatureChoice(featureChoice: FeatureChoice) {
     const r = await EditFeatureChoiceDialogComponent.show(this.matDialog, { featureChoice: featureChoice });

     if (r) {
      AgFns.refreshGrid(this.featureChoiceGridOptions, this.featureChoices);
     }
  }

  

  async editProductType(x: ProductType | Product) {
    
    const productType = (x instanceof ProductType) ? x : x.productType;
    if (productType?.id) {
      this.router.navigate(['/product-type', productType.id]);
    }
    
  }

  goProductTypes() {
    this.router.navigate(['/product-types']);
  }

  async cancelChanges() {
    this.featureChoiceGridOptions.api.stopEditing();
    this.uow.rollback();
    this.featureChoiceGridOptions.api.redrawRows();
  }

  async saveChanges() {
    this.featureChoiceGridOptions.api.stopEditing();
    const sr = await this.dbSaveService.saveChanges();
    AgFns.refreshGrid(this.featureChoiceGridOptions, this.featureChoices);
    this.dialogService.toast('Changes saved');
  }


  // No longer supported
  // async deleteFeature(template: Feature) {
  //   if (template.activeStatusId >= 1) {
  //     this.dialogService.showOkMessage('Cannot delete',
  //       `This Item Size Template cannot be deleted once it has become active. `
  //     );
  //     return;
  //   }
  //   const isEmpty = await this.dbQueryService.isEmptyFeature(template.id);
  //   if (!isEmpty) {
  //     this.dialogService.showOkMessage(
  //       'Cannot delete',
  //       `This Item Size Template cannot be deleted because it is still referenced by at least one ProductType. `
  //     );
  //     return;
  //   }
  //   const yn = await this.dialogService.askYesNo('Delete?', 'Are you sure?');
  //   if (yn.index === 1) {
  //     return;
  //   }
  //   template.featureChoices.slice().forEach(tm => tm.entityAspect.setDeleted());
  //   template.entityAspect.setDeleted();
  //   await this.saveSingleChange(template);
  // }

  // No longer supported.
  // async deleteFeatureChoice(featureChoice: FeatureChoice) {
  //   if (featureChoice.activeStatusId >= 1) {
  //     this.dialogService.showOkMessage('Cannot delete',
  //       `This Item Size Template cannot be deleted once it has become active. `
  //     );
  //     return;
  //   }
  //   const isInUse = await this.dbQueryService.checkIfInUse(featureChoice, Product, 'featureChoiceId');
  //   if (isInUse) {
  //     this.dialogService.showOkMessage(
  //       'Cannot delete',
  //       `This Item Size cannot be deleted from this template because it is still referenced by at least one ProductType/Size record. `
  //     );
  //     return;
  //   }
  //   const template = featureChoice.feature;
  //   featureChoice.entityAspect.setDeleted();
  //   await this.saveMappingChanges(template);
  //   this.dialogService.toast('Item Size Template "Size" deleted.');
  // }

  // private async saveSingleChange(entity: Entity, clearMappings = true) {
  //   const sr = await this.dbSaveService.saveSelectedChanges([entity]);
  //   this.features = await this.dbQueryService.getFeatures();
  //   this.featureGridOptions.api.redrawRows();
  //   if (clearMappings) {
  //     this.featureChoices = [];
  //     this.featureChoiceGridOptions.api.redrawRows();
  //   }
  // }

  // private async saveMappingChanges(template: Feature) {
  //   await this.dbSaveService.saveChanges();
  //   this.featureChoices = await this.dbQueryService.getFeatureChoices(template.id);
  //   this.featureChoiceGridOptions.api.redrawRows();
  // }

  // private async checkIfTemplateExists(templateName: string) {
  //   const foundItem = this.features.find(x => x.name === templateName );
  //   if (foundItem != null) {
  //     await this.dialogService.showOkMessage('This Template name already exists', 'Templates cannot share the same name');
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // private async checkIfFeatureChoiceExists(choiceValue: string) {
  //   const foundItem = this.featureChoices.find(x => x.choiceValue === choiceValue );
  //   if (foundItem != null) {
  //     await this.dialogService.showOkMessage(`This 'Use Size' already exists in this template`, 'Duplicate Use Sizes are not allowed');
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

}
