import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EntityManagerProviderGuard } from 'app/shared/entity-manager-guard';
import { AuthGuardService } from 'app/shared/auth-guard.service';

import { JobOrdersComponent } from './job-orders.component';
import { JoPullToWipComponent } from './jo-pull-to-wip.component';
import { JoPullApplyComponent } from './jo-pull-apply.component';
import { SharedModule } from 'app/shared/shared.module';
import { JoShipComponent } from './jo-ship.component';
import { AskShipInfoDialogComponent } from './ask-ship-info-dialog.component';
import { PrintableInvoiceComponent } from './printable-invoice.component';
import { PrintableManifestComponent } from './printable-manifest.component';
import { JoAuditComponent } from './jo-audit.component';
import { CanDeactivateGuard } from 'app/shared/can-deactivate-guard.service';
import { InvoicePrintDialogComponent } from './invoice-print-dialog.component';
import { JoReturnRequestComponent } from './jo-return-request.component';
import { ReturnRequestDetailDialogComponent } from './return-request-detail-dialog.component';
import { JoInvoiceComponent } from './jo-invoice.component';
import { EventScheduleDialogComponent } from './event-schedule-dialog.component';
import { ManifestComponent } from './manifest.component';
import { PrintableInvoiceContentComponent } from './printable-invoice-content.component';
import { ManifestPrintDialogComponent } from './manifest-print-dialog.component';
import { ChangeJoPullBatchDialogComponent } from './change-jo-pull-batch-dialog.component';
import { UnapplyJodDialogComponent } from './unapply-jod-dialog.component';
import { FindJoHeaderDialogComponent } from './find-jo-header-dialog.component';
import { InvoicesComponent } from './invoices.component';
import { PrintableJoWipComponent } from './OLD/printable-jo-wip.component';
import { AddonWipComponent } from './addon-wip.component';
import { AddonBinComponent } from './addon-bin.component';
import { AddonBinSplitDialogComponent } from './addon-bin-split-dialog.component';
import { AddonBinProcessComponent } from './addon-bin-process.component';
import { JoInProcessComponent } from './jo-in-process.component';
import { JoShipPrepComponent } from './jo-ship-prep.component';
import { JoShipScanDialog } from './jo-ship-scan-dialog.component';
import { JoShipPrepScanDialog } from './jo-ship-prep-scan-dialog.component';
import { ShipFrequencyDialogComponent } from './ship-frequency-dialog.component';
import { JobOrderInfoComponent } from './jo-info.component';


const routes = [
  {
    path: 'job-orders',
    component: JobOrdersComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'jo-pull-to-wip/:joPullBatchId',
    component: JoPullToWipComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard],
    data: { 
      title: 'Job Order - Pull to Work in Process' },
    },
  {
    path: 'jo-pull-apply/:joPullBatchId/:joDetailId/:shouldPrintBarcodes',
    component: JoPullApplyComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard],
    data: { 
      title: 'Job Order - Pull Application' 
    },
  },
  {
    path: 'jo-in-process',
    component: JoInProcessComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'Job Orders - In Process' },
  },
  {
    path: 'jo-invoice/:joHeaderId/:invoiceId',
    component: JoInvoiceComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'Job Order - Invoice' },
  },
  {
    path: 'jo-ship',
    component: JoShipComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'Job Orders - Shipping' },
  },
  {
    path: 'jo-ship/:shipBinId',
    component: JoShipComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'Job Orders - Shipping' },
  },
  {
    path: 'jo-audit',
    component: JoAuditComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'Job Orders - Audit' },
  },
  {
    path: 'printable-invoice/:invoiceId',
    component: PrintableInvoiceComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    data: { title: 'Printable Invoice' },
  },
  {
    path: 'printable-manifest/:manifestId',
    component: PrintableManifestComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    data: { title: 'Printable Manifest' },
  },
  {
    path: 'printable-manifest-group/:manifestGroupId',
    component: PrintableManifestComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    data: { title: 'Printable Manifest' },
  },
  {
    path: 'jo-return-req-auth',
    component: JoReturnRequestComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    data: { title: 'Return Request' },
  },
  {
    path: 'manifests',
    component: ManifestComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    data: { title: 'Manifests' },
  },
  {
    path: 'invoices',
    component: InvoicesComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    data: { title: 'Invoices' },
  },
  { 
    path: 'addons-wip',
    component: AddonWipComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    data: { title: 'Addon Work In Progress' },
  },
  {
    path: 'addon-bins',
    component: AddonBinComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    data: { title: 'Addon Bins' },
  },
  {
    path: 'addon-bin/:addonBinId',
    component: AddonBinProcessComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    data: { title: 'Addon Bin Process' },
  },
  {
    path: 'jo-ship-prep',
    component: JoShipPrepComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    data: { title: 'Job Orders in Shipping process' },
  }
];
routes.forEach( (r: any) => {
  r.data =  Object.assign(r.data ?? {}, { icon: 'assignment'});
});

@NgModule({
  declarations: [
    JobOrdersComponent,
    JoPullToWipComponent,
    JoPullApplyComponent,
    JoInProcessComponent,
    JoShipPrepComponent,
    JoShipScanDialog,
    JoShipPrepScanDialog,
    JoInvoiceComponent,
    JoShipComponent,
    JoAuditComponent,
    JoReturnRequestComponent,
    ChangeJoPullBatchDialogComponent,
    AskShipInfoDialogComponent,
    EventScheduleDialogComponent,
    ManifestComponent,
    InvoicesComponent,
    ManifestPrintDialogComponent,
    PrintableInvoiceComponent,
    PrintableInvoiceContentComponent,
    PrintableManifestComponent,
    PrintableJoWipComponent,
    InvoicePrintDialogComponent,
    ReturnRequestDetailDialogComponent,
    UnapplyJodDialogComponent,
    FindJoHeaderDialogComponent,
    AddonWipComponent,
    AddonBinComponent,
    AddonBinProcessComponent,
    AddonBinSplitDialogComponent,
    ShipFrequencyDialogComponent,
    JobOrderInfoComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
  ],
})
export class JobOrdersModule {}
