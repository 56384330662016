<app-uni-page [parent]="this" pageTitle="Add-On Bins" [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <as-split unit="percent" direction="vertical">
    <as-split-area [size]="50">
      <div uni-flex-row class="my-4" >
        <mat-checkbox class="ml-8" [(ngModel)]="shouldShowClosedBins" [ngModelOptions]="{standalone: true}"
          (ngModelChange)="refreshAndUpdate()">
          Show Closed Bins
        </mat-checkbox>

        <mat-form-field class="ml-8" style="width: 250px;">
          <mat-label>Select Addon Station</mat-label>
          <mat-select placeholder="Select Station" [(ngModel)]="selectedAddonStationId" 
            (ngModelChange)="refreshAndUpdate()"
          >
            <mat-option [value]="addonStation.id" *ngFor="let addonStation of this.addonStations">
              {{addonStation.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="ml-8" style="width: 200px;">
          <mat-label>Refresh Page</mat-label>
          <mat-select placeholder="Select Station" [(ngModel)]="refreshSeconds" 
            (ngModelChange)="setupRefresh()"
          >
            <mat-option [value]="refreshOption.refreshSeconds" *ngFor="let refreshOption of this.refreshOptions">
              {{refreshOption.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <app-ag-grid [gridOptions]="addonBinGridOptions" [rowData]="addonBins"
        style="height: calc(100% - 75px)"></app-ag-grid>
    </as-split-area>
    <as-split-area [size]="50">
      <as-split unit="percent" direction="vertical">
        <as-split-area [size]="33">
          <app-ag-grid [gridOptions]="addonGridOptions" [rowData]="addons"></app-ag-grid>
        </as-split-area>
        <as-split-area [size]="67">
          <app-ag-grid [gridOptions]="itemDetailGridOptions" [rowData]="itemDetails"></app-ag-grid>
        </as-split-area>
      </as-split>
    </as-split-area>
  </as-split>
</ng-template>

<ng-template #footerTemplate>
  <mat-spinner class="mt-12 ml-12" diameter="30" *ngIf="busyService.isBusy | async; else notBusy"></mat-spinner>
  <ng-template #notBusy>
    <div class="left-buttons">
      <button [disabled]="isBusy" *ngIf="this.uow.hasChanges()" mat-raised-button (click)="saveChanges()">
        <span>Save Changes</span>
      </button>
      <button [disabled]="isBusy" *ngIf="this.uow.hasChanges()" mat-raised-button (click)="cancelChanges()">
        <span>Cancel Changes</span>
      </button>
    </div>
    <div>
      <button [disabled]="isBusy" mat-raised-button (click)="goBack()">
        <span>Go Back</span>
      </button>
    </div>
  </ng-template>
</ng-template>