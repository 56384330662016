<app-uni-page  [parent]="this" *ngIf="isPageReady" pageTitle="Product Type Tags"
  [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate"></app-uni-page>
<ng-template #bodyTemplate>
  <as-split unit="percent" direction="vertical">

    <as-split-area [size]="50">
      <app-ag-grid  [gridOptions]="isTagGridOptions" [rowData]="productTypeTags"></app-ag-grid>
    </as-split-area>

    <as-split-area [size]="50">
      <div class="panel-hdr">Associated Item Styles
      </div>
      <app-ag-grid [gridOptions]="isTagMapGridOptions" [rowData]="productTypeTagMaps" style="height: calc(100% - 52px);" ></app-ag-grid>
    </as-split-area>

  </as-split>
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <button mat-raised-button (click)="addProductTypeTag()">
      <span>Add Product Type Tag</span>
    </button>
    <button [disabled]="selectedProductTypeTag == null" mat-raised-button (click)="attachProductTypes()">
      <span>Attach Product Types</span>
    </button>
  </div>
  <div>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>