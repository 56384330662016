import { Injectable } from '@angular/core';
import { EntityIssue, Manifest } from 'app/model/entities/entity-model';
import { DataProperty, Entity, EntityState } from 'breeze-client';
import * as _ from 'lodash';
import { AuthService } from './auth.service';
import { BusyService } from './busy.service';
import { DbQueryService } from './db-query.service';
import { DialogService } from './dialog.service';
import { UnitOfWork } from './unit-of-work';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export type EntityValues<T extends Entity> = Partial<Omit<T, keyof Entity>>;

export interface ProximityPostResult {
  HttpStatusCode: number;
  ErrorMessage?: string;
  ConfirmationKey?: string
}

@Injectable({providedIn: 'root'})
export class DbSaveService {
  constructor(
    public uow: UnitOfWork,
    private http: HttpClient,
    public dbQueryService: DbQueryService,
    public dialogService: DialogService,
    public authService: AuthService,
    public busyService: BusyService
  ) {}

  public isSaving = false;

  async updateProximitySupplierData() {
    const r = await this.postData<string>('UpdateProximitySupplierData', null, {  });
    return r;
  }

  async sendProximityShipment(shipmentId: number) {
    const r = await this.postData<ProximityPostResult>('AddProximityShipment', {  id: shipmentId }, null);
    return r;
  }

  async sendProximityInvoice(invoiceHeaderId: string) {
    const r = await this.postData<ProximityPostResult>('AddProximityInvoice', {  id: invoiceHeaderId }, null);
    return r;
  }

  async combineFeatureChoices(fromFeatureChoiceId: number, toFeatureChoiceId: number) {
    const q = this.uow
      .createQuery(null, 'CombineFeatureChoices') // method on server
      .withParameters({ fromFeatureChoiceId, toFeatureChoiceId });
    const r = await q.execute();
    return _.first(r) as unknown as number;
  }

  async combineProducts(fromProductIds: number[], toProductId: number) {
    const q = this.uow
      .createQuery(null, 'CombineProducts') // method on server
      .withParameters({ fromProductIds, toProductId });
    const r = await q.execute();
    return _.first(r) as unknown as number;
  }
  
  async addEntityIssue(issueStruct: EntityIssue ) {
    issueStruct.crtnTs = new Date();
    issueStruct.crtnUserInit = this.authService.getUser().initials;
    const issue = this.uow.createEntity(EntityIssue, issueStruct);
    await this.saveChanges();
  }

  async migrateFeatureChoice(fromFeatureChoiceId: number, toFeatureId: number) {
    const q = this.uow
      .createQuery(null, 'MigrateFeatureChoice') // method on server
      .withParameters({ fromFeatureChoiceId, toFeatureId });
    const r = await q.execute();
    return _.first(r) as unknown as number;
  }
  

  // the manifest itself may have a rule change at this point - so we don't want to query it. 
  async updateManifestAndRelated(manifest: Manifest) {
    const nextProcessDate = await this.dbQueryService.getManifestNextProcessDate(manifest);
    manifest.nextProcessDate = nextProcessDate;
    const entityIssues = await this.dbQueryService.getEntityIssues('Manifest', manifest.proximityManifestId);
    // check if EntityIssue exists for missing schedule and delete it.
    // If new entityIssue records are needed they will be added later.
    entityIssues.forEach(ei => {
      ei.entityAspect.setDeleted();
    });

    await this.saveChanges();
    
    const newEntityIssues = await this.updateManifestIssues();
  }

  async updateManifestIssues() {
    const q = this.uow
      .createQuery(EntityIssue, 'UpdateManifestIssues');

    const r = await q.execute();
    return r;
  }

  createEntity<T extends Entity>(type: { new(): T; }, initalValues?: EntityValues<T>, entityState?: EntityState): T {
    return this.uow.createEntity(type, initalValues, entityState );
  }

  createOrUndoEntity<T extends Entity>(type: { new(): T; }, keyObj: EntityValues<T>): T {
    const kps = type.prototype.entityType.keyProperties as DataProperty[];
    const keyValues = kps.map(kp => keyObj[kp.name])
    
    let ent = this.uow.undoIfDeleted( type, keyValues);
    if (ent == null) {
      ent = this.createEntity(type, keyObj);
    }
    return ent;
  }

  async saveChangesWrapBusy(shouldRollbackOnError = true) {
    return await this.busyService.busy( async () => {
      return await this.saveChanges(shouldRollbackOnError);
    });
  }

  async saveChanges(shouldRollbackOnError = true) {

    try {
      this.isSaving = true;
      return await this.uow.saveChanges();
    } catch (e) {
      if (e.entityErrors) {
        await this.dialogService.showSaveValidationErrors(e.entityErrors);
      } else {
        // await this.dialogService.showOkMessage('Save Error', 'Unable to save: ' + e.message);
      }
      if (shouldRollbackOnError) {
        this.uow.rollback();
      }
      throw e;
    } finally {
      this.isSaving = false;
    }
    
  }

  async saveSelectedChanges(entities: Entity[], shouldRollbackOnError = true) {
    try {
      this.isSaving = true;
      return await this.uow.saveChanges(entities);
    } catch (e) {
      if (e.entityErrors) {
        await this.dialogService.showSaveValidationErrors(e.entityErrors);
      } else {
        await this.dialogService.showOkMessage('Save Error', 'Unable to save: ' + e.message);
      }
      if (shouldRollbackOnError) {
        this.uow.rollback();
      }
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  rejectChanges() {
    return this.uow.manager.rejectChanges();
  }

  postData<TResponse>(action: string, body: any, params: any): Promise<TResponse> {
    const url = this.uow.manager.serviceName + action;
    return this.busyService.busy(async () => {
      return firstValueFrom(this.http.post<TResponse>(url, body, { params: params}));
    });
  }

  // async updateManifestEntityIssues() {
  //   const manifests = await this.dbQueryService.getManifestsThatAreCurrentlyDue();
  //   const promises = manifests.map(m => {
  //     const issues  = this.dbQueryService.getEntityIssues('Manifest', m.proximityManifestId);
  //   });
  // }

  // createManifestIssue(manifest: Manifest) {
  //   const issueStruct = <EntityIssue> {};
  //   issueStruct.entityTypeName = 'Manifest';
  //   issueStruct.entityId =  manifest.proximityManifestId;
  //   issueStruct.accountId = manifest.joHeaders[0].accountId;
  //   issueStruct.description = 'This Manifest is now due for invoicing, as of: ' + UtilFns.fmtDate4(manifest.nextProcessDate);
  //   issueStruct.crtnTs = new Date();
  //   issueStruct.crtnUserInit = this.authService.getUser().initials;
  //   issueStruct.warningTypeId = WarningTypeCode.Warning;
  //   const issue = this.uow.createEntity(EntityIssue, issueStruct);
  // }


  
}
