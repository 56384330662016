import { GridOptions, RowSelectedEvent, ColDef, } from '@ag-grid-community/core';
import { Component, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { InvoiceDetail, InvoiceHeader, ItemDetail, JoDetail, JoHeader, Shipment, ShippingBox } from 'app/model/entities/entity-model';
import { InvoiceStatusCode } from 'app/model/enums/invoice-status-code';
import { ItemDetailStatusCode } from 'app/model/enums/item-detail-status-code';
import { AddressDialogComponent } from 'app/shared/address-dialog.component';
import { AgFns } from 'app/shared/ag-fns';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { NavFns } from 'app/shared/nav-fns';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { ShippingService } from 'app/shipping/shipping.service';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { InvoiceShipmentService } from './invoice-shipment.service';
import { DateFns } from 'app/shared/date-fns';

@Component({
  selector: 'app-jo-invoice',
  templateUrl: './jo-invoice.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class JoInvoiceComponent extends DomainBaseComponent {
  @ViewChild('shipLabelCell') shipLabelCell: TemplateRef<any>;
  joHeaderId: number;
  joHeader: JoHeader;
  joHeaders: JoHeader[]; // there will be only one - need an array for grid
  johGridOptions: GridOptions;

  invoiceHeaders: InvoiceHeader[];
  invoiceHeader: InvoiceHeader;
  invhGridOptions: GridOptions;
  initInvoiceId: string;

  invoiceDetails: InvoiceDetail[];
  invdGridOptions: GridOptions;
  
  itemDetails: ItemDetail[];
  itdGridOptions: GridOptions;

  shippingBoxes: ShippingBox[] = [];
  sboxGridOptions: GridOptions;

  vouchersWithSameAddress: InvoiceHeader[];
  shippingIdKey: string;

  uomMap = {
    oz: 'Ounces',
    lb: 'Pounds',
    gr: 'Grams',
    kg: 'Kilograms'
  };

  constructor(
    public uow: UnitOfWork,
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    protected shippingService: ShippingService,
    protected invoiceShipmentService: InvoiceShipmentService,
    private matDialog: MatDialog
  ) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    this.joHeaderId = +this.route.snapshot.params['joHeaderId'];
    this.initInvoiceId = this.route.snapshot.params['invoiceId'];

    this.johGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJohGridReady,
    });

    this.invhGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onInvhGridReady,
      onRowSelected: this.onInvhRowSelected,
      onFirstDataRendered: (params) => this.AgFns.selectFirstRow(this.invhGridOptions)
    });

    this.itdGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onItdGridReady,
    });

    this.invdGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onInvdGridReady,
    }, { detailProperty: 'joDetail.joDetailAddons' });
    
    this.sboxGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onSboxGridReady,
    });


    const p1 = this.dbQueryService.getJoHeaderWithInvInfoById(this.joHeaderId).then(r => {
      this.joHeader = r;
      this.joHeaders = r != null ? [r] : [];
      this.invoiceHeaders = this.joHeader.invoiceHeaders;
      
    });

    // we need the itemDetails but we don't need them explicitly as they will automatically be attached to the joHeader
    const p2 = this.dbQueryService.getItemDetailsByJoHeaderId(this.joHeaderId);
    await Promise.all([p1, p2]);

    await this.fixupInvoices(this.invoiceHeaders);
    
    await this.shippingService.getShippingCarriers(); // just to cache everything

    this.isPageReady = true;
  }

  // Hack: needed to insure that vouchers have a valid due date and vouchers have calc'd totals
  private async fixupInvoices(invHeaders: InvoiceHeader[]) {
    const voucherStatusIds =  [InvoiceStatusCode.Voucher, InvoiceStatusCode.VoucherLabeled, InvoiceStatusCode.VoidError];
    const appConfig = this.dbQueryService.appConfig;
    invHeaders.forEach(invh => {
      if (invh.invoiceDate.getTime() >= invh.dueDate.getTime()) {
        const dueDays = invh.joHeader.account.dueDateNumDays || appConfig.account.dueDateNumDays;
        invh.dueDate = DateFns.dateAdd(invh.invoiceDate, dueDays, 'days');
      }
      
      // needed because vouchers did not get migrated with a total ( deliberately - we wanted to calculate them here)
      if ( voucherStatusIds.includes(<InvoiceStatusCode> invh.invoiceStatusId)) {
        invh.totalAmt = invh.calcTotalAmt();
      }

    });
    if (this.uow.hasChanges()) {
      await this.dbSaveService.saveChanges();
    }
  }

  onJohGridReady(evt: any) {
    const colDefs = [
      {
        headerName: 'Job Order Id',
        ...NavFns.createIdCellClickedNavProps('id', this.router, '/job-orders')
      },
      { headerName: 'Account', field: 'account.accountName' },
      { headerName: 'JO Date', field: 'joDate' },
      { headerName: 'Ship By Date', field: 'shipByDate' },
      { headerName: 'Status', field: 'joStatus.name' },
    ];
    AgFns.initGrid(this.johGridOptions, colDefs, null, true);
  }

  onInvhGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Invoice', field: 'id', cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'Invoice Date', field: 'invoiceDate', },
      { headerName: 'Due Date', field: 'dueDate', },
      { headerName: 'Status', field: 'invoiceStatus.name', },
    ];

    const sortModel = [{ colId: 'id', sort: 'asc' as const }];
    AgFns.initGrid(this.invhGridOptions, colDefs, sortModel);

    this.invhGridOptions.onFirstDataRendered = () => {
      AgFns.selectGridRowByKey(this.invhGridOptions.api, (e) => e.id, this.initInvoiceId);
    }
  }

  onInvdGridReady(evt: any) {
    const colDefs: ColDef[] = [
      { headerName: 'Invoice Detail Id', field: 'id', cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'JoDetail Id', field: 'joDetailId', type: 'numericColumn' },
      { headerName: 'Manufacturer', field: 'joDetail.product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'joDetail.product.productType.description' },
      { headerName: 'Style', field: 'joDetail.product.productType.style' },
      { headerName: 'Features', field: 'joDetail.product.featureChoicesExtract' },
      { headerName: 'New Qty', field: 'shipQty' },
      { headerName: 'Price/Unit', field: 'joDetail.unitPriceAmt' },
      { headerName: 'Oversize Amt/Unit', field: 'joDetail.unitOversizeAmt' },
      { headerName: 'Custom Amt/Unit', field: 'joDetail.unitCustomAmt' },
      // Should be the same as unitCustomAmt
      // { headerName: 'Mods Amt/Unit', field: 'joDetail.unitModsAmt' },
      { headerName: 'Total Amt/Unit', field: 'joDetail.unitTotalAmt' },
      {
        headerName: 'Extended Amt',
        valueGetter: params => {
          return params.data.joDetail.unitTotalAmt * params.data.shipQty;
        },
        valueFormatter: params => {
          return this.UtilFns.fmtCurrency(params.value);
        },
      },
      { headerName: 'Reissue Qty', field: 'redistributeQty' },
      { headerName: 'Reissue Amt/Unit', field: 'joDetail.unitReissueAmt' },
      {
        headerName: 'Extended Reissue Amt',
        valueGetter: params => {
          return (params.data.redistributeQty || 0) * params.data.joDetail.unitReissueAmt || 0;
        },
        valueFormatter: params => {
          return this.UtilFns.fmtCurrency(params.value);
        },
      },
    ];
    this.updateInvdMasterDetail(this.invdGridOptions);
    AgFns.initGrid(this.invdGridOptions, colDefs, null, true);
  }

  updateInvdMasterDetail(parentGridOptions: GridOptions) {
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      { headerName: 'Addon', field: 'addon.nameAndLocation' },
      { headerName: 'Addl. Info', field: 'additionalInfo' },
      { headerName: 'Price/Unit', field: 'unitPriceAmt' },
      
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      getDetailRowData: params => {
        const invd = params.data as InvoiceDetail;
        params.successCallback(invd.joDetail.joDetailAddons);
      },
    };
  }

  onItdGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'product.productType.description' },
      { headerName: 'SKU', field: 'product.productType.style' },
      { headerName: 'Features', field: 'product.featureChoicesExtract' },
      { headerName: 'Status', field: 'itemDetailStatus.name' },
      { headerName: 'Item Detail Id', field: 'id' },

    ];
    AgFns.initGrid(this.itdGridOptions, colDefs, null, true);
  }

  onSboxGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Box Id', field: 'id' },
      {
        headerName: 'Weight', field: 'weight',
        type: 'numericColumn',
        valueGetter: params => {
          const sb = <ShippingBox>params.data;
          return `${sb.weight} ${this.uomMap[sb.weightUoMId]}`;
        }
      },
      { headerName: 'Package', field: 'packageApiIdentifier', },
      { headerName: 'Tracking #', field: 'trackingLabelApiIdentifier', cellRenderer: this.renderTrackingLink.bind(this) },
      { ...AgFns.createCellButtonProps('Shipping Label', this.shipLabelCell) },

    ];
    AgFns.initGrid(this.sboxGridOptions, colDefs, null, true);
  }

  async onInvhRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    const invh = e.data as InvoiceHeader;
    if (!invh) {
      return;
    }
    this.invoiceHeader = invh;
    this.invoiceDetails = invh.invoiceDetails;
    this.itemDetails = invh.itemDetails;
    this.updateLocation(invh.id);

    let pShipment = Promise.resolve(<Shipment>null);
    if (this.invoiceHeader?.shipmentId != null) {
      pShipment = this.dbQueryService.getShipment(this.invoiceHeader.shipmentId);

    }
    const pVouchers = this.dbQueryService.getVoucherInvoicesOnAccount(this.joHeader.accountId);

    let [shipment, relatedVouchers] = await Promise.all([pShipment, pVouchers]);
    if (shipment != null) {
      this.updateShippingBoxUI(shipment);
    }
    
    relatedVouchers = relatedVouchers.filter(invh => invh.joHeader.shippingKey == this.invoiceHeader.joHeader.shippingKey);

    this.vouchersWithSameAddress = relatedVouchers.filter(invh =>
      (invh.shipmentId == null || invh.shipmentId != this.invoiceHeader.shipmentId)
      && (invh.id != this.invoiceHeader.id));
    this.shippingIdKey = _.min(relatedVouchers.map(invh => invh.id));
    
  }

  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree([`/jo-invoice/${this.joHeaderId}/${key}`]);
    const url = urlTree.toString();
    this.domainService.location.replaceState(url);
  }

  shouldShowMigratedTotal(invoiceHeader: InvoiceHeader) {
     return invoiceHeader.wasMigrated && Math.abs(invoiceHeader.totalAmt - invoiceHeader.calcTotalAmt()) > .01;
  }

  formatInvoiceList(invoiceHeaders: InvoiceHeader[]) {
    return invoiceHeaders.map(invh => invh.id).join(', ');
  }

  /** link to carrier site to track packages */
  renderTrackingLink(params: any) {
    const box = params.data as ShippingBox;
    const link = this.shippingService.getTrackingLink(box);
    if (!link) {
      return box.trackingLabelApiIdentifier;
    }
    return `<a style="color:blue;" href="${link}" target="_blank" rel="noopener">${box.trackingLabelApiIdentifier}</a>`;
  }

  canUndoInvoice(invoiceHeader: InvoiceHeader) {
    return invoiceHeader?.isNotPosted && !this.invoiceHeader?.hasShippingLabel;
  }

  async undoInvoice(invoiceHeader: InvoiceHeader) {
    // put the related itemDetail back into the Pull or Received state
    // need to do this before we detach the invoiceHeader.
    const itemDetails = invoiceHeader.itemDetails.filter(itd => itd.invoiceHeaderId === invoiceHeader.id);
    itemDetails.forEach(itd => {
      // move item back to ShipPrep
      itd.itemDetailStatusId = ItemDetailStatusCode.InProcessShipPrep;
      itd.invoiceHeaderId = null;
    });

    // insure that UI cannot update during next section
    this.invoiceHeader = null;

    await this.undoShipment(invoiceHeader);

    invoiceHeader.invoiceDetails.slice().forEach(invd => {
      invd.entityAspect.setDeleted();
    });
    invoiceHeader.entityAspect.setDeleted();

    await this.dbSaveService.saveChanges();

    this.dialogService.toast('Invoice undone');

    const qparams = NavFns.buildNavIdParams(this.joHeaderId, 'id');
    this.router.navigate(['/jo-ship-prep'], qparams);
    
  }

  private async undoShipment(invoiceHeader: InvoiceHeader) {
    if (invoiceHeader.shipmentId == null) { return; }

    const shipment = await this.dbQueryService.getShipment(invoiceHeader.shipmentId);
    if (shipment == null) {
      // means that shipment was probably deleted by another session
      return;
    }

    if (invoiceHeader.shipment.primaryInvoiceHeaderId != invoiceHeader.id) {
      const ynResult = await this.dialogService.askYesNo('Delete shipment info?'
        , `This invoice is not the primary invoice for this shipment. 
        Do you want to delete this shipment or just remove this invoice from the shipment? `
        , ['Delete Shipment', 'Remove Invoice from Shipment']);
      if (ynResult.index == 1) {
        invoiceHeader.shipmentId = null;
        return;
      }
    }

    shipment.invoiceHeaders.forEach(invh => invh.shipmentId = null);
    // delete shipping info
    shipment.shippingBoxes.slice().forEach(sb => sb.entityAspect.setDeleted());
    shipment.entityAspect.setDeleted();
  }

  canCalcFreight(invoiceHeader: InvoiceHeader) {
    return invoiceHeader
      && (invoiceHeader?.isPrimaryInvoice || invoiceHeader?.shipment == null)
      && invoiceHeader.isNotPosted
      && !invoiceHeader.hasShippingLabel;
  }

  async calcFreight(invoiceHeader: InvoiceHeader) {
    const ok = await this.invoiceShipmentService.askAndCalcFreight(this.matDialog, invoiceHeader);
    if (ok) {
      this.updateShippingBoxUI(invoiceHeader.shipment);
    }
  }

  updateShippingBoxUI(shipment: Shipment) {
    this.shippingBoxes = shipment.shippingBoxes;
    // Hack - this should NOT be necessary but if the Freight tab is live and 
    // we recalc and modify the shippingBoxes then they do NOT repaint unless we
    // set everything to [] and then back.
    this.sboxGridOptions.api?.setRowData([]);
    this.sboxGridOptions.api?.setRowData(this.shippingBoxes);
  }

  canPrintLabel(invoiceHeader: InvoiceHeader) {
    return (invoiceHeader?.invoiceStatusId == InvoiceStatusCode.Voucher
      || invoiceHeader?.invoiceStatusId == InvoiceStatusCode.VoucherLabeled)      
      && invoiceHeader.hasFreight;
  }

  async printBoxLabel(box: ShippingBox) {
    if (!box.shippingLabel) {
      return;
    }
    return await this.invoiceShipmentService.printBoxShippingLabels([box]);
  }

  async printLabels(invoiceHeader: InvoiceHeader) {
    const ok = await this.invoiceShipmentService.printShippingLabel(invoiceHeader);
    if (ok) {
      this.invhGridOptions.api?.redrawRows();
      this.sboxGridOptions.api?.redrawRows();
    }
  }

  canVoidLabel(invoiceHeader: InvoiceHeader) {
    return invoiceHeader?.isPrimaryInvoice
      && invoiceHeader.hasShippingLabel
      && invoiceHeader.invoiceStatusId == InvoiceStatusCode.VoucherLabeled;
  }

  async voidLabel(invoiceHeader: InvoiceHeader) {
    const ok = await this.invoiceShipmentService.voidShippingLabel(invoiceHeader);
    // we may have a void error - in which case we need to also change ui
    this.invhGridOptions.api?.redrawRows();
    this.sboxGridOptions.api?.redrawRows();
  }

  canRemoveVoidError(invoiceHeader: InvoiceHeader) {
    return invoiceHeader?.isPrimaryInvoice
      && invoiceHeader?.invoiceStatusId === InvoiceStatusCode.VoidError;
  }

  async removeVoidError(invoiceHeader: InvoiceHeader) {
    const ok = await this.invoiceShipmentService.removeVoidError(invoiceHeader);
    if (ok) {
      this.invhGridOptions.api?.redrawRows();
      this.sboxGridOptions.api?.redrawRows();
    }
  }

  canEditAddress(prefix: string) {
    if (this.invoiceHeader?.isPostedOrQueued) {
      return false;
    }
    if (prefix == 'shipping') {
      if (this.invoiceHeader?.hasShippingLabel) {
        // need to void label first
        return false;
      }
    }
    return true;
  }

  async editAddress(prefix: string, addressTypeName: string) {
    const r = await AddressDialogComponent.show(this.matDialog, {
      entity: this.invoiceHeader.joHeader,
      addressPrefix: prefix,
      addressTypeName: addressTypeName,
      editable: true
    });
    // if we change the address we must recalc the freight
    if (r && prefix == 'shipping') {
      const shipment = this.invoiceHeader.shipment;
      if (shipment != null) {
        shipment.freightAmt = null;
        shipment.shipmentApiIdentifier = null;
      }
    }
  }

  canDisplayAltVoucherMessage() {
    return this.vouchersWithSameAddress?.length > 0 && this.invoiceHeader?.isNotPosted;
  }

  getCarrierDisplayName(invoiceHeader: InvoiceHeader) {
    return this.shippingService.getCarrierDisplayName(invoiceHeader.shipment.carrierApiIdentifier);
  }

  getServiceDisplayName(invoiceHeader: InvoiceHeader) {
    return this.shippingService.getServiceDisplayName(
      invoiceHeader.shipment.carrierApiIdentifier,
      invoiceHeader.shipment.serviceApiIdentifier
    );
  }

  getPcardName(invoiceHeader: InvoiceHeader) {
    const name = invoiceHeader.paymentName;
    if (!name || !name.indexOf(' ')) { return name; }
    return name.replace('XXXX', '');
  }

  getQuickbooksLink(invoiceHeader: InvoiceHeader) {
    if (invoiceHeader.invoiceStatusId === 'POSTED') {
      return environment.quickbooksUrl + 'invoice?txnId=' + invoiceHeader.gLInvoiceId;
    }
    return '';
  }

  goShipping() {
    const params = {
      queryParams: {
        key: this.shippingIdKey
      }
    };
    this.router.navigate(['/jo-ship'], params);
  }

  goPrintableInvoice() {
    if (this.invoiceHeader != null) {
      this.router.navigate(['/printable-invoice', this.invoiceHeader.id]);
    }
  }

}
