<app-uni-page  [parent]="this" *ngIf="isPageReady" pageTitle="Create Purchase Orders" [bodyTemplate]="bodyTemplate"
  [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <div *ngIf="poDetails.length == 0" class="h3 ml-12 mb-4" >There are no unassigned Purchase Order Detail records.</div>
  <div *ngIf="poDetails.length > 0" class="h3 ml-12 mb-4">Unassigned Purchase Order Details</div>

  <app-ag-grid style="height: calc(100vh - 145px);" [gridOptions]="podGridOptions" [rowData]="poDetails">
  </app-ag-grid>
  <ng-template #noteCell let-row>
    <button *ngIf="row!=null" [ngClass]="{'btn-has-data': hasPoNotes(row)}" (click)="editPoNotes(row)">Note</button>
  </ng-template>
  <ng-template #editQtyCell let-row>
    <button *ngIf="canEditQty(row)"  (click)="editQty(row)">Edit Qty</button>
  </ng-template>


</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <button *ngIf="poDetails.length > 0" mat-raised-button (click)="createPurchaseOrders()">Ok
    </button>
  </div>
  <button mat-raised-button (click)="goBack()">
    <span>Go Back</span>
  </button>
</ng-template>