import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { EOQType, Feature, ProductType, Product, ProductTypeTag, ProductTypeTagMap, Manufacturer } from 'app/model/entities/entity-model';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { AgFns, GridState, ISortModel } from 'app/shared/ag-fns';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { takeUntil } from 'rxjs/operators';
import { AltProductTypeDialogComponent } from './alt-product-type-dialog.component';
import { EditProductDialogComponent } from './edit-product-dialog.component';
import { ProductTypeTagDialogParams } from './edit-generic-entity-dialog.component';
import { ProductTypeFinderDialogComponent } from './product-type-finder-dialog.component';
import { EntityFns } from 'app/shared/entity-fns';

@Component({
  selector: 'app-product-type-tag',
  templateUrl: './product-type-tag.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ProductTypeTagComponent extends DomainBaseComponent implements OnInit {
  
  isTagGridOptions: GridOptions;
  productTypeTags: ProductTypeTag[] = [];
  selectedProductTypeTag: ProductTypeTag;
  
  isTagMapGridOptions: GridOptions;
  productTypeTagMaps: ProductTypeTagMap[];
  

  constructor(
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    protected matDialog: MatDialog,

  ) {
    super(domainService);
    this.user = domainService.authService.getUser();
    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    this.isTagGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onIsTagGridReady,
      onRowSelected: this.onIsTagRowSelected,
      accentedSort: true
    })

    AgFns.captureGridRouteParams(this.isTagGridOptions, this.route, 'id');
    
    this.isTagMapGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onIsTagMapGridReady,
      getRowId: p => p.data.productTypeId
    });

    this.productTypeTags = await this.dbQueryService.getProductTypeTags();   
    
    this.isPageReady = true;
  }

  canDeactivate() {
    if (this.isBusy) return false;
    this.uow.clearEntities(ProductTypeTag);
    this.uow.clearEntities(ProductType);
    return true;
  }

  async onIsTagGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Name', field: 'name', filter: 'agTextColumnFilter' },
      { ...AgFns.createButtonProps('', this.editProductTypeTag.bind(this), { label: 'Edit'} ) },
      { headerName: 'Id', field: 'id', filter: 'agNumberColumnFilter' },
    ];
    const sortModel =  [
      { colId: 'name', sort: 'asc' },
      
    ] as ISortModel;
    AgFns.initGrid(this.isTagGridOptions, colDefs, sortModel);    

    AgFns.applyGridRouteParams(this.isTagGridOptions);
  }

  async onIsTagMapGridReady(evt: any) {
    const colDefs = [
      { ...AgFns.createButtonProps('', this.detachProductType.bind(this), { label: 'Detach'} ) },
      { headerName: 'Manufacturer', field: 'productType.manufacturer.name', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'productType.description', filter: 'agTextColumnFilter' },
      { headerName: 'Style', field: 'productType.style', filter: 'agTextColumnFilter' },
      { ...AgFns.createButtonProps('', this.editProductType.bind(this), { label: 'Edit Product Type'} ) },
      { headerName: 'Status', field: 'productType.activeStatus.name',  },
      { headerName: 'Id', field: 'productTypeId', filter: 'agNumberColumnFilter' },
    ];

    const sortModel =  [
        { colId: 'productType.manufacturer.name', sort: 'asc' },
        { colId: 'productType.description', sort: 'asc' },
        { colId: 'productType.style', sort: 'asc' },
      ] as ISortModel;

    AgFns.initGrid(this.isTagMapGridOptions, colDefs, sortModel);    

  }

  async onIsTagRowSelected(event: RowSelectedEvent) {    
    if (!event.node.isSelected()) {
      return;
    }
    const isTag = event.data as ProductTypeTag;
    if (isTag == null) {
      return;
    }
    this.updateLocation(isTag.id);
    this.selectedProductTypeTag = isTag;
    this.productTypeTagMaps = await this.dbQueryService.getProductTypeMapsForTag(isTag.id);
  }


  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/product-type-tags']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.isTagGridOptions, key && key.toString());
    this.domainService.location.replaceState(url);
  }

  async editProductTypeTag(isTag: ProductTypeTag) {
    const r = await this.dialogService.showGenericDialog({
      ...ProductTypeTagDialogParams, target: isTag,
     } );
    if (r) {
      AgFns.refreshGrid(this.isTagGridOptions, this.productTypeTags);
    }
  }

  async addProductTypeTag() {
    const r = await this.dialogService.showGenericDialog({
      ...ProductTypeTagDialogParams, target: null,
     } );
    if (r) {
      this.productTypeTags = await this.dbQueryService.getProductTypeTags();
      AgFns.refreshGrid(this.isTagGridOptions, this.productTypeTags);
    }
  }

  async attachProductTypes() {
    const r = await ProductTypeFinderDialogComponent.show(this.matDialog, {  });
    if (r) {
      const istIdSet = new Set(this.productTypeTagMaps.map(x => x.productTypeId));
      var newIsts = r.filter(x => !istIdSet.has(x.id));
      var newMaps = newIsts.map(x => {
        return this.uow.createEntity(ProductTypeTagMap, {
          productTypeId: x.id,
          productTypeTagId: this.selectedProductTypeTag.id
        });
      })
      await this.dbSaveService.saveSelectedChanges(newMaps);
      this.productTypeTagMaps = await this.dbQueryService.getProductTypeMapsForTag(this.selectedProductTypeTag.id);
      AgFns.refreshGrid(this.isTagMapGridOptions, this.productTypeTagMaps);
    }
  }

  async detachProductType(isTagMap: ProductTypeTagMap) {
    this.productTypeTagMaps = this.productTypeTagMaps.filter(x => x.productTypeId != isTagMap.productTypeId);
    isTagMap.entityAspect.setDeleted();
    await this.dbSaveService.saveSelectedChanges([isTagMap]);
    AgFns.refreshGrid(this.isTagMapGridOptions, this.productTypeTagMaps);
  }



  editProductType(isTagMap: ProductTypeTagMap) {
    this.router.navigate(['./product-type', isTagMap.productTypeId]);
    return;
  }



}
