import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { ItemDetailAddon } from 'app/model/entities/entity-model';
import { AgFns } from 'app/shared/ag-fns';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { FileViewDialogComponent, FileViewDialogData } from 'app/shared/file-view-dialog.component';
import { NavFns } from 'app/shared/nav-fns';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-addon-wip',
  templateUrl: './addon-wip.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class AddonWipComponent extends DomainBaseComponent {
  @ViewChild('viewDocCell') viewDocCell: TemplateRef<any>;
  @ViewChild('wipCell') wipCell: TemplateRef<any>;

  itdAddonGridOptions: GridOptions;
  itemDetailAddons: ItemDetailAddon[];
  currentItdAddon: ItemDetailAddon;
  
  constructor(
    public uow: UnitOfWork,
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    private matDialog: MatDialog
  ) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  canDeactivate() {
    if (this.isBusy) return false;
    this.uow.clearEntities(ItemDetailAddon);
    
    return true;
  }

  async updateFromContext() {
    this.itdAddonGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onItdAddonGridReady,
      onRowSelected: this.onItdAddonRowSelected,
      onModelUpdated: this.onModelUpdated,
      onFilterChanged: this.onFilterChanged,
      rowModelType: 'serverSide',
      onPaginationChanged: (params) => {
        if (params.newPage) {
          this.clearCurrentSelection();
        }
      }
    });
    AgFns.captureGridRouteParams(this.itdAddonGridOptions, this.route, 'id');

    this.isPageReady = true;
  }

  onItdAddonGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'itemDetail.product.productType.manufacturer.name', filter: 'agTextColumnFilter' },
      { headerName: 'Style', field: 'itemDetail.product.productType.style', filter: 'agTextColumnFilter' },
      { headerName: 'Features', field: 'itemDetail.product.featureChoicesExtract', filter: 'agTextColumnFilter' },
      { headerName: 'Addon', field: 'addon.nameAndLocation', filter: 'agTextColumnFilter' , width: 200 },
      { headerName: 'Additional Info', field: 'additionaInfo', filter: 'agTextColumnFilter'},
      // { ...AgFns.createSetFilterObjectProps('Addon Status', 'processStatus.name', this.dbQueryService.getAllCached(ProcessStatus)) },
      { ...AgFns.createCellButtonProps('Documents', this.viewDocCell)  },
      { ...AgFns.createCellButtonProps('WIP', this.wipCell, 'wip'), maxWidth: 85 },
      { headerName: 'Job Order', ...NavFns.createIdCellClickedNavProps('itemDetail.joDetail.joHeaderId', this.router, '/job-orders'), filter: 'agNumberColumnFilter' },
      { headerName: 'Purchase Order', ...NavFns.createIdCellClickedNavProps('itemDetail.poDetail.poHeaderId', this.router, '/purchase-orders'), filter: 'agNumberColumnFilter' },
      { headerName: 'Item Detail Id', field: 'itemDetail.id', filter: 'agNumberColumnFilter' },
    ];

    const sortModel =  [{ colId: 'addon.nameAndLocation', sort: 'asc' as const }];
    AgFns.initGrid(this.itdAddonGridOptions, colDefs, sortModel);
    
    this.updateDatasource();
    AgFns.applyGridRouteParams(this.itdAddonGridOptions);
    
  }

  async updateDatasource() {
    const gridApi = this.itdAddonGridOptions?.api;
    if (gridApi == null) {
      return;
    }
    const ds = AgFns.buildDatasource(() => this.dbQueryService.createItemDetailAddonQuery());

    gridApi.setServerSideDatasource(ds);
    
    
  }

  async onItdAddonRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    const itdAddon = e.data as ItemDetailAddon;
    if (!itdAddon) {
      return;
    }
    this.currentItdAddon = itdAddon;

    this.updateLocation();
  }

  onModelUpdated() {
    
  }

  onFilterChanged() {
    this.clearCurrentSelection();
  }

  clearCurrentSelection() {
    this.updateLocation();
  }
  
  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/addons-wip']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.itdAddonGridOptions, this.currentItdAddon?.id.toString());
    this.domainService.location.replaceState(url);
  }

  hasAddonDocs(itdAddon: ItemDetailAddon) {
    return itdAddon?.addon.addonDocMaps.length > 0;
  }

  async viewAddonDocs(itdAddon: ItemDetailAddon) {
    const data: FileViewDialogData = { 
      title: 'View Addon Document',
      docs: itdAddon.addon.addonDocMaps.map(x => x.addonDoc)
    };
    await FileViewDialogComponent.show(this.matDialog, data);

  }

  async goWipToInvoice(itde: ItemDetailAddon) {
    this.currentItdAddon = itde;
     this.updateLocation();
    const johId = itde.itemDetail.joDetail.joHeaderId;
    this.router.navigate(['/jo-wip-to-invoice', johId]);
    
  }
  

}
