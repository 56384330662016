<app-uni-dialog title="Purchase Order Instructions" [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div uni-flex-row  >
    <mat-form-field style="width: 800px;">
      <textarea rows="12" matInput placeholder="Purchase Order Instructions" [(ngModel)]="poHeader.instructions"></textarea>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button [disabled]="dbSaveService.isSaving || poHeader.entityAspect.entityState.isUnchanged()" (click)="save()">Save
  </button>
  <button mat-raised-button [disabled]="dbSaveService.isSaving" (click)="cancel()">Cancel
  </button>
</ng-template>
