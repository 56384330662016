import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GridOptions } from '@ag-grid-community/core';
import { AgFns } from 'app/shared/ag-fns';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { JoHeader, PoHeader, PoNote } from 'app/model/entities/entity-model';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import * as _ from 'lodash';
import { EntityFns } from 'app/shared/entity-fns';
import { PoStatusCode } from 'app/model/enums/po-status-code';
import { UtilFns } from 'app/shared/util-fns';
import { DateFns } from 'app/shared/date-fns';

@Component({
  templateUrl: './split-purchase-orders-dialog.component.html',
})
export class SplitPurchaseOrdersDialogComponent {
  title: string;
  poHeader: PoHeader;

  joGridOptions: GridOptions;
  jobOrders: JoHeader[];
  isPageReady = false;
  shouldSplit = '_shouldSplit';

  static async show(matDialog: MatDialog, data: any) {
    return await matDialog
      .open(SplitPurchaseOrdersDialogComponent, {
        disableClose: true,
        height: '480px',
        width: '700px',
        data: data
      })
      .afterClosed()
      .toPromise();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SplitPurchaseOrdersDialogComponent>,
    private dbQueryService: DbQueryService,
    private dbSaveService: DbSaveService,
    private uow: UnitOfWork,
    private dialogService: DialogService
  ) {
    this.title = 'Select job orders to split off';
    this.poHeader = this.data.poHeader;

    this.prepare();
  }

  async prepare() {
    this.joGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJoGridReady,
    });

    await this.getRelatedJobOrders();
    this.isPageReady = true;
  }

  async getRelatedJobOrders() {
    if (this.poHeader.poStatusId !== PoStatusCode.NewUnposted) {
      await this.dialogService.showOkMessage('Invalid Operation',
       'You cannot split a purchaseOrder that has a status of: ' + this.poHeader.poStatus.name);
       this.dialogRef.close(null);
       return;
    }
    let poDetails = this.poHeader.poDetails;
    if (poDetails.length == 0) {
      poDetails = await this.dbQueryService.getPoDetailsForReceive(this.poHeader.id);
    }

    this.jobOrders = _.chain(poDetails)
      .map(pod => pod.joDetail)
      .filter(jod => jod != null)
      .map(jod => jod.joHeader)
      .uniq()
      .value();

    // just in case;
    this.jobOrders.forEach(jo => jo[this.shouldSplit] = null);
    
  }

  onJoGridReady() {
    const colDefs = [
      { headerName: 'Job Order', field: 'id' },
      { headerName: 'Split', field: this.shouldSplit, cellRenderer: AgCheckboxCellComponent },
      { headerName: 'Account', field: 'account.accountName' },
      { headerName: 'JO Date', field: 'joDate' },
      
    ];
    AgFns.initGrid(this.joGridOptions, colDefs);
    AgFns.sizeColumnsToFit(this.joGridOptions);
  }

  async ok() {
    this.joGridOptions.api.stopEditing();

    const joIdsToSplit = this.jobOrders.filter(po => po[this.shouldSplit])
      .map(jo => jo.id);

    if (joIdsToSplit.length === 0) {
      this.dialogService.showOkMessage('Select at least one', 'You must select at least one Job Order to split off.');
      return false;
    } else if (joIdsToSplit.length === this.jobOrders.length) {
      this.dialogService.showOkMessage('Cannot select all', `You cannot select all of the Job Orders to be split off.
      You must leave at least 1 job order on the original Purchase Order`);
      return false;
    }

    const clone = EntityFns.cloneStruct(this.poHeader, ['id', 'crtnTs']);

    const newPoHeader = this.uow.createEntity(PoHeader, clone);
    const now = new Date();
    newPoHeader.crtnTs = now;

    this.poHeader.poNotes.forEach(pon => {
      // copy all of the notes that are NOT poDetail specific.
      if (pon.poDetailId == null) {
        const ponClone = EntityFns.cloneStruct(pon, ['id']);
        const newPoNote = this.uow.createEntity(PoNote, ponClone);
        newPoNote.poHeader = newPoHeader;
      }
    } );

    const podsToSplit = this.poHeader.poDetails.filter(pod => {
      return pod.joDetail != null && joIdsToSplit.indexOf(pod.joDetail.joHeaderId) !== -1;
    });

    podsToSplit.slice().forEach(pod => {
      pod.poNotes.slice().forEach(pon => pon.poHeader = newPoHeader);
      pod.modTs = new Date();
      pod.poHeader = newPoHeader;
    });

    // poke crtn ts by 1 second for Optimistic concurrency
    this.poHeader.crtnTs = DateFns.pokeDate(this.poHeader.crtnTs);
    
    await this.dbSaveService.saveChanges();
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
