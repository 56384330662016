<app-uni-page [parent]="this" *ngIf="isPageReady" pageTitle="{{ title }}" [bodyTemplate]="bodyTemplate"
  [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <div class="content-card" style="height: 85px">
    <div class="mt-12 ml-12" uni-flex-row>
      <mat-form-field style="width: 350px">
        <mat-select [(ngModel)]="chart" placeholder="Chart" (ngModelChange)="onChartChange($event)" >
          <mat-option *ngFor="let chart of charts" [value]="chart">
            {{chart.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="chart.usesAccount" style="width: 350px">
        <mat-select [ngModel]="accountId" placeholder="Account" (ngModelChange)="onAccountChange($event)" >
          <mat-option>
            <ngx-mat-select-search ngModel (ngModelChange)="searchAccounts($event)" placeholder="Account"
              [placeholderLabel]="'Find Account...'" [noEntriesFoundLabel]="'No matching accounts found'">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let account of matchingAccounts" [value]="account.id">
            {{account.accountName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button *ngIf="chart.usesAccount" mat-raised-button (click)="clearAccount()">
        <span>Clear Account</span>
      </button>
      
    </div>
  </div>
  <div style="height: calc(100% - 85px);" class="content-card">
    <!-- <app-chart-invoice-delay style="height: calc(100% - 75px);" [account]="account"></app-chart-invoice-delay> -->
    <app-chart-invoice-delay  *ngIf="chart.id=='InvoiceDelay'" style="height: calc(100% - 85px);" [account]="account"></app-chart-invoice-delay>
    <app-chart-invoice-stats  *ngIf="chart.id=='InvoiceStats'" style="height: calc(100% - 85px);" [account]="account"></app-chart-invoice-stats>
    <app-chart-manuf-inventory  *ngIf="chart.id=='ManufInventoryCount'" style="height: calc(100% - 85px);" ></app-chart-manuf-inventory>
    <app-chart-jo-stats  *ngIf="chart.id=='JoStats'" style="height: calc(100% - 85px);" ></app-chart-jo-stats>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
  </div>
  <div>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>