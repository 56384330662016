<app-uni-page [parent]="this" pageTitle="Add-Ons" [bodyTemplate]="bodyTemplate"
  [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <div *ngIf="isPageReady" style="height: 100%">
    <app-ag-grid [gridOptions]="addonGridOptions" ></app-ag-grid>
  </div>


</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <button [disabled]="!this.user.isProductAdmin" mat-raised-button (click)="addonAdd()">
      <span>Add Addon</span>
    </button>
  </div>
  <div>
    <button mat-raised-button (click)="goAddonStations()">
      <span>Addon Stations</span>
    </button>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>