// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Addon } from './addon';
import { PoProductTypeConfig } from './po-product-type-config';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class PoProductTypeConfigAddon extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  additionalInfo: string;
  addonId: number;
  poProductTypeConfigId: number;
  addon: Addon;
  poProductTypeConfig: PoProductTypeConfig;
}

