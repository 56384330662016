<div id="home" class="page-layout simple fullwidth">

  <div class="header accent p-24 h-160" style="display: flex;  flex-flow: row wrap;  justify-content: space-between;">

    <div>
      <div class="h2 mt-16">References</div>
    </div>

  </div>

  <div class="content">
    <div class="cards">
      <mat-card routerLink="/manufacturers">
        <div class="h1">
          <mat-icon>assignment</mat-icon>
          Manufacturers
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>

      <mat-card routerLink="/product-types">
        <div class="h1">
          <mat-icon>shopping_cart</mat-icon>
          Product Types
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>

      <mat-card routerLink="/feature">
        <div class="h1">
          <mat-icon>assessment</mat-icon>
          Features
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>
    </div>
    <div class="cards">
      <mat-card routerLink="/addons">
        <div class="h1">
          <mat-icon>work</mat-icon>
          Add-Ons
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>
      <mat-card routerLink="/product-type-tags">
        <div class="h1">
          <mat-icon>work</mat-icon>
          Product Type Tags
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>
      <mat-card (click)="goBack()">
        <div class="h1">
          <mat-icon>work</mat-icon>
          Go Back
        </div>
        
      </mat-card>
    </div>
    <div class="cards">
      
    </div>
  </div>