<app-uni-page [parent]="this" *ngIf="isPageReady" pageTitle="Proximity API - Pending Submission Items"
  [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <mat-tab-group (selectedTabChange)="onTabChanged($event)" style="background-color: white;">
    <mat-tab label="Bulk Reference Submissions" class="h-80">
      <app-ag-grid [gridOptions]="subItemGridOptions" ></app-ag-grid>
    </mat-tab>
    <mat-tab label="Product/Feature Combination Submissions">
      <app-ag-grid [gridOptions]="cmbItemGridOptions" [rowData]="combineItems" ></app-ag-grid>
    </mat-tab>
    <mat-tab label="Shipping Submissions">
      <app-ag-grid [gridOptions]="soloSubGridOptions" [rowData]="soloItems" ></app-ag-grid>
    </mat-tab>
  </mat-tab-group>

  
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <button mat-raised-button [disabled]="totalRemainingCount==0"(click)="submitSupplierData()">
      <span>Submit Supplier Data to Proximity</span>
      <span>&nbsp;&nbsp;&nbsp;&nbsp; Items remaining: {{ totalRemainingCount }}</span>
    </button>
  </div>
  <div>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>