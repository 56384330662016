import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { GridOptions } from '@ag-grid-community/core';
import { ItemDetail, JoDetail, JoHeader, JoPullBatch } from 'app/model/entities/entity-model';
import { AgFns } from 'app/shared/ag-fns';
import { DbQueryService } from 'app/shared/db-query.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { DialogService } from 'app/shared/dialog.service';
import { NavFns } from 'app/shared/nav-fns';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { ItemDetailStatusCode } from 'app/model/enums/item-detail-status-code';
import { DbSaveService } from 'app/shared/db-save.service';
import { DomainService } from 'app/shared/domain.service';
import { AuthUser } from 'app/shared/auth.service';
import { firstValueFrom } from 'rxjs';

export interface JoShipScanDialogData {
  joHeader: JoHeader;
  itemDetails: ItemDetail[];
  mustScanAll: boolean;
}

@Component({
  templateUrl: './jo-ship-scan-dialog.component.html',
  //  encapsulation: ViewEncapsulation.None
})
export class JoShipScanDialog {
  authUser: AuthUser;
  
  johGridOptions: GridOptions;
  joHeaders: JoHeader[];  // will only contain a single joHeader

  itdGridOptions: GridOptions;
  itemDetails: ItemDetail[]; 
  scannedItemDetails: ItemDetail[] = [];

  scannedItemDetailId: string;
  scanErrorMessage: string;
  
  isPageReady = false;

  static async show(matDialog: MatDialog, data: JoShipScanDialogData ) {
    var obs = matDialog
      .open(JoShipScanDialog, {
        disableClose: true,
        height: '800px',
        width: '1200px',
        data: data,
      })
      .afterClosed();
    return await firstValueFrom<ItemDetail[]>(obs);
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: JoShipScanDialogData, public dialogRef: MatDialogRef<JoShipScanDialog>,
     private router: Router, private dbQueryService: DbQueryService, private dbSaveService: DbSaveService, 
     private domainService: DomainService, private dialogService: DialogService, private uow: UnitOfWork) {

    
    this.prepare();
  }

  async prepare() {
    this.authUser = this.domainService.authService.getUser();
    this.joHeaders = [this.data.joHeader];
    this.itemDetails = this.data.itemDetails;

    this.johGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJohGridReady,
    },);

    this.itdGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onItdGridReady,
    },);

    this.isPageReady = true;
  }

  onJohGridReady() {
    const colDefs = [
      { headerName: 'Job Order Id', field: 'id', maxWidth: 110, minWidth: 110,  },
      { headerName: 'Account', field: 'account.accountName' },
      { headerName: 'JO Date', field: 'joDate'},
      { headerName: 'Ship By Date', field: 'shipByDate' },
      { headerName: 'Ship Frequency', field: 'shipFrequency.name', filter: 'agTextColumnFilter' },
      { headerName: 'Allow Partial Shipments', field: 'allowPartialShipments', editable: false, cellRenderer: AgCheckboxCellComponent },
    ];
    
    AgFns.initGrid(this.johGridOptions, colDefs);
    AgFns.sizeColumnsToFit(this.johGridOptions);
  }

  onItdGridReady() {
    const colDefs = [
      { ...AgFns.createButtonProps('Mark as scanned', this.markAsScanned.bind(this), 
        { label: 'Mark As scanned', canDisplay: this.canMarkAsScanned.bind(this) }),
        colId: 'markAsScanned', minWidth: 150
      },
      { headerName: 'Item Detail Id', field: 'id', cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'product.productType.description' },
      { headerName: 'Style', field: 'product.productType.style' },
      { headerName: 'Features', field: 'product.featureChoicesExtract' },
      { headerName: 'Scanned', cellRenderer: AgCheckboxCellComponent, editable: false,
        valueGetter: params => {
          const itd = <ItemDetail>params.data;
          const found = this.scannedItemDetails.includes(itd);
          return found;
        }
      },
      { headerName: 'Jo Detail Id', field: 'joDetail.id' }
    ]
    
    AgFns.initGrid(this.itdGridOptions, colDefs);
    AgFns.sizeColumnsToFit(this.itdGridOptions);
  }

  onInputFilled(event: { target: any }, fn: (value: any) => void) {
    const input = event.target;
    const length = input.value.length;
    const maxLength = input.attributes.maxlength.value;

    if (length >= maxLength) {
      // bind is needed to associate the fn returned with 'this' component.
      fn.bind(this)(input.value);
    } else {
      this.scanErrorMessage = '';
    }
  }

  async scanItemDetailId(itemDetailId: string) {
    const itd = this.itemDetails.find((x => x.id== itemDetailId));
    if (itd != null) {
      this.scannedItemDetails.push(itd);
      this.dialogService.toast('Found and marked.');
      AgFns.refreshGrid(this.itdGridOptions, this.itemDetails); 
    } else  {
      this.scanErrorMessage = `Invalid - Item detail with id: '${itemDetailId}' is not one of the itemDetail available for this shipment. `;
    }
    this.scannedItemDetailId = '';
  }

  canMarkAsScanned(itd: ItemDetail) {
    return this.authUser.isShippingAdmin && !this.scannedItemDetails.includes(itd) ;
  }

  markAsScanned(itd: ItemDetail) {
    this.scanItemDetailId(itd.id);
  } 

  canContinue() {
    return this.itemDetails.every(x => this.scannedItemDetails.includes(x));
  }

  ok() {
    this.dialogRef.close(this.scannedItemDetails);
  }
  
  async cancel() {
    if (this.scannedItemDetails.length > 0) {
      const ynResult = await this.dialogService.askYesNo("Cancel", "Any scanned items will be forgotten if you do this. Ok?");
      if (ynResult.index == 1) {
        return;
      }
    }
    this.dialogRef.close(null);
  }

}
