import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { OnDestroy, Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { InvoiceHeader, Email } from 'app/model/entities/entity-model';
import { UtilFns } from 'app/shared/util-fns';
import { ShippingService } from 'app/shipping/shipping.service';
import { InvoiceStatusCode } from 'app/model/enums/invoice-status-code';
import { DbQueryService } from 'app/shared/db-query.service';

interface FlatDetail {
  mfr: string;
  desc: string;
  style: string;
  featureChoicesExtract: string;
  qty: number;
  unitPrice: string;
  unitOs: string;
  unitCus: string;
  reQty: number;
  reAmt: string;
  total: string;
  embs: FlatEmb[];
}
interface FlatEmb {
  description: string;
  additionalInfo: string;
  unitPrice: string;
  total: string;
}

@Component({
  selector: 'app-printable-invoice-content',
  templateUrl: './printable-invoice-content.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class PrintableInvoiceContentComponent implements OnInit {
  @Input() invoiceHeader: InvoiceHeader;

  // dueDate: Date;
  employee: string;
  modTotal: number;
  flatDetails: FlatDetail[];
  getAddress = UtilFns.getAddress;
  serviceDisplayName: string;
  labelApi: string;

  constructor(private shippingService: ShippingService, private dbQueryService: DbQueryService) {
  }

  ngOnInit() {
    const invoiceDetails = this.invoiceHeader.invoiceDetails;

    const fmtCurrency = UtilFns.fmtCurrency;
    let modTotal = 0;
    this.flatDetails = invoiceDetails.map(d => {
      const jo = d.joDetail;
      const style = jo.product.productType;
      const embs = jo.joDetailAddons && jo.joDetailAddons.map(jodAddon => {
        modTotal += d.shipQty * (jodAddon.unitPriceAmt || 0);
        return {
          description: jodAddon.addon.nameAndLocation,
          additionalInfo: jodAddon.additionalInfo,
          
          unitPrice: (jodAddon.unitPriceAmt > 0) ? fmtCurrency(jodAddon.unitPriceAmt) : '',
          total: (jodAddon.unitPriceAmt > 0) ? fmtCurrency(d.shipQty * (jodAddon.unitPriceAmt || 0)) : ''
        };
      });

      const unitTotalPreModsAmt = jo.unitTotalAmt - jo.unitModsAmt;
      return {
        mfr: style.manufacturer.name,
        desc: style.description,
        style: style.style,
        featureChoicesExtract: jo.product.getFeatureChoicesExtract(),
        qty: d.shipQty,
        unitPrice: fmtCurrency(jo.unitPriceAmt),
        unitOs: fmtCurrency(jo.unitOversizeAmt),
        unitCus: fmtCurrency(jo.unitCustomAmt),
        reQty: d.redistributeQty,
        reAmt: fmtCurrency(jo.unitReissueAmt),
        total: fmtCurrency((unitTotalPreModsAmt * d.shipQty) + ((d.redistributeQty || 0) * jo.unitReissueAmt || 0)),
        embs: embs
      };
    });
    const joHeader = this.invoiceHeader.joHeader;
    
    this.employee = (joHeader.empFirstName || '') + ' ' + (joHeader.empLastName || '') + ' ' + (joHeader.empEmail || '');
    this.modTotal = modTotal;
    
    this.serviceDisplayName = this.getServiceDisplayName(this.invoiceHeader) ?? 'N/A';
    const boxes = this.invoiceHeader.shipment?.shippingBoxes;
    if (boxes?.length > 0) {
      this.labelApi = boxes[0].trackingLabelApiIdentifier ?? 'N/A';
    } else {
      this.labelApi = 'N/A';
    }
  }

  getCarrierDisplayName(invoiceHeader: InvoiceHeader) {
    if (invoiceHeader?.shipment) {
      return this.shippingService.getCarrierDisplayName(invoiceHeader.shipment.carrierApiIdentifier);
    }
    return null;
  }

  getServiceDisplayName(invoiceHeader: InvoiceHeader) {
    if (invoiceHeader?.shipment) {
      return this.shippingService.getServiceDisplayName(
        invoiceHeader.shipment.carrierApiIdentifier,
        invoiceHeader.shipment.serviceApiIdentifier
      );
    }
    return null;
  }

  getBillingAddress() {
    if (this.isManifested) {
      return this.invoiceHeader.joHeader.billingName + '\n\n\n';
    }
    return UtilFns.getAddress(this.invoiceHeader.joHeader, 'billing', false, false);
  }

  /** Visa XXXX1111 -> Visa 1111 */
  get paymentName() {
    const name = this.invoiceHeader.joHeader.paymentName;
    if (!name || !name.indexOf(' ')) { return name; }
    return name.replace('XXXX', '');
  }

  get isPosted() {
    return this.invoiceHeader && this.invoiceHeader.invoiceStatusId === InvoiceStatusCode.InvoicePosted;
  }

  get isManifested() {
    return this.invoiceHeader && this.invoiceHeader.joHeader?.manifestId;
  }

}
