<app-uni-dialog title="Purchase Order Detail" [parent]="this" [contentTemplate]="contentTemplate"
  [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div uni-flex-row *ngIf="!hasJoDetail()" >
    <button mat-raised-button (click)="lookupProduct()">Select Product
    </button>
    <mat-hint *ngIf="poDetail.productId===0" class="error-hint">A Product must be specified</mat-hint>
  </div>

  <div uni-flex-row class="mt-16 ml-12" >
    <mat-form-field style="width: 300px;">
      <input matInput placeholder="Manufacturer" [ngModel]="poHeader.manufacturer.name" readonly="readonly" />
    </mat-form-field>
    <mat-form-field style="width: 310px;">
      <mat-select [(ngModel)]="poDetail.accountId" placeholder="Account - for account owned inventory"
        [disabled]="hasJoDetail()">
        <mat-option>
          <ngx-mat-select-search ngModel (ngModelChange)="searchAccounts($event)" [placeholderLabel]="'Find Account...'"
            [noEntriesFoundLabel]="'No matching accounts found'">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let account of matchingAccounts" [value]="account.id">
          {{account.accountName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div uni-flex-row class="mt-12 ml-12" >
    <mat-form-field style="width: 300px;">
      <input matInput placeholder="Description" [ngModel]="poDetail.product?.productType.description"
        readonly="readonly" />
    </mat-form-field>
    <mat-form-field style="width: 250px;">
      <input matInput placeholder="SKU" [ngModel]="poDetail.product?.productType.style" readonly="readonly" />
    </mat-form-field>
  </div>
  <div uni-flex-row class="mt-12 ml-12" >
    
    <mat-form-field style="width: 200px;">
      <input matInput placeholder="Features" [ngModel]="poDetail.product?.featureChoicesExtract" readonly="readonly" />
    </mat-form-field>
  
  </div>
  <div uni-flex-row class="mt-12 ml-12" >
    <mat-form-field style="width: 220px;">
      <input matInput placeholder="Qty" [(ngModel)]="poDetail.orderQty" [readonly]="hasJoDetail()" />
      <mat-hint class="error-hint" *ngIf="getErrorMessage('orderQty')">{{ getErrorMessage('orderQty') }}
      </mat-hint>
    </mat-form-field>
    <mat-form-field style="width: 200px;">
      <input matInput placeholder="Expected Date" [(ngModel)]="poDetail.expectedShipDate"
        [matDatepicker]="expectedDatePicker" />
      <mat-datepicker-toggle matSuffix [for]="expectedDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #expectedDatePicker></mat-datepicker>
      <mat-hint class="error-hint" *ngIf="getErrorMessage('expectedDate')">{{ getErrorMessage('expectedDate') }}
      </mat-hint>
    </mat-form-field>
    <mat-form-field style="width: 200px;">
      <mat-label>Order Type</mat-label>
      <mat-select [(ngModel)]="poDetail.poDetailTypeId" [disabled]="hasJoDetail()">
        <mat-option [value]="poDetailType.id" *ngFor="let poDetailType of poDetailTypes">{{poDetailType.name}}
        </mat-option>
      </mat-select>
      <mat-hint class="error-hint" *ngIf="getErrorMessage('poDetailTypeId')">{{ getErrorMessage('poDetailTypeId') }}
      </mat-hint>
    </mat-form-field>

  </div>

</ng-template>

<ng-template #footerTemplate>
  <button [disabled]="dbSaveService.isSaving" mat-raised-button (click)="save()">Save
  </button>
  <button [disabled]="dbSaveService.isSaving" mat-raised-button (click)="cancel()">Cancel
  </button>

</ng-template>
