import { RowSelectedEvent, GetRowIdParams, GridOptions } from '@ag-grid-community/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { PoBackorder } from 'app/model/entities/-po-backorder';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { AgFns, GridState, ISortModel } from 'app/shared/ag-fns';
import { AgMatDatepickerComponent } from 'app/shared/ag-mat-datepicker.component';
import { UtilFns } from 'app/shared/util-fns';
import { takeUntil } from 'rxjs/operators';
import { ProductType, Manufacturer } from '../model/entities/entity-model';
import { DomainBaseComponent } from '../shared/domain-base.component';
import { DomainService } from '../shared/domain.service';
import { DateFns } from 'app/shared/date-fns';

@Component({
  selector: 'app-backorders',
  templateUrl: './backorders.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class BackordersComponent extends DomainBaseComponent implements OnInit {
  pobGridOptions: GridOptions;
  shouldShowIdColumns = true;
  
  currentPob: PoBackorder;
  pobItems: PoBackorder[];
  

  constructor(protected domainService: DomainService, protected route: ActivatedRoute, private matDialog: MatDialog) {
    super(domainService);

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    // this.gridState = AgFns.createGridState(this.route.snapshot.queryParams, 'currentPodItem');
     
    this.pobGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPobGridReady,
      onRowSelected: this.onRowSelected,
      onFilterChanged: this.onFilterChanged,
      onColumnRowGroupChanged: this.onColumnRowGroupChanged,
      onCellValueChanged: this.onCellValueChanged,
      getRowId: (p) => p.data?.poDetailId,
      rowGroupPanelShow: 'always',
      suppressDragLeaveHidesColumns: true,
      suppressMakeColumnVisibleAfterUnGroup: false,
      stopEditingWhenCellsLoseFocus: true,
      components: { datePicker: AgMatDatepickerComponent },
      onPaginationChanged: (params) => {
        if (params.newPage) {
          this.currentPob = null;
          this.updateLocation();
        }
      }
    });
    AgFns.captureGridRouteParams(this.pobGridOptions, this.route, 'podId');
    
    await this.requery();
    this.isPageReady = true;
  }

  // getRowId(params: GetRowIdParams) {
  //   return params.data?.podId;
  // }

  async canDeactivate() {
    if (this.isBusy) return false;
    this.stopEditing();
    if (this.uow.hasChanges()) {
      const ynResult = await this.dialogService.askYesNo('Changes have not been saved', 'Do you want to save before exiting?');
      if (ynResult.index == 0) {
        await this.saveChanges();
      } else {

      }
    }

    
    this.uow.clearEntities(ProductType);
    return true;
  }

  async requery() {
    await this.busyService.busy( async () => {
      this.pobItems = <PoBackorder[]><any>await this.dbQueryService.getPoDetailBackorders();
      this.pobItems.forEach(pob => pob._isChecked = false);
      this.currentPob = null;
      this.pobGridOptions?.api?.refreshCells();
    });
  }

  // This method is called AFTER ngAfterViewInit - this is important
  // because the ColDef's here reference TemplateRef's above that will not be resolved until here.
  onPobGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Checked', field: '_isChecked', cellRenderer: AgCheckboxCellComponent },
      { headerName: 'Account', field: 'account.accountName', filter: 'agTextColumnFilter', enableRowGroup: true },
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name', filter: 'agTextColumnFilter', enableRowGroup: true, },
      { headerName: 'SKU', field: 'product.productType.style', filter: 'agTextColumnFilter', enableRowGroup: true },
      { headerName: 'Features', field: 'product.featureChoicesExtract', filter: 'agTextColumnFilter', enableRowGroup: true },
      { headerName: 'Description', field: 'product.productType.description', filter: 'agTextColumnFilter', enableRowGroup: true },
      { headerName: 'Order Qty', field: 'orderQty', filter: 'agNumberColumnFilter' },
      { headerName: 'Received Qty', field: 'receivedQty' },
      { headerName: 'Canceled Qty', field: 'canceledQty' },
      { headerName: 'JO Orig Ship By Date', field: 'joDetail.joHeader.origShipByDate', filter: 'agDateColumnFilter', },
      { headerName: 'JO Expected Date', field: 'joDetail.expectedShipDate', editable: true, cellStyle: AgFns.setEditableStyle, filter: 'agDateColumnFilter', cellEditor: "datePicker" },
      { headerName: 'Manufacturer Expected Date', field: 'poDetail.expectedShipDate', editable: true, cellStyle: AgFns.setEditableStyle, filter: 'agDateColumnFilter', cellEditor: "datePicker" },
      { headerName: 'Manuf Last Updated Ts', field: 'poDetail.expectedShipDateModTs', filter: 'agDateColumnFilter' },
      { headerName: 'Account Id', field: 'accountId', filter: 'agNumberColumnFilter' },
      { headerName: 'Manufacturer Id', field: 'product.productType.manufacturerId', filter: 'agNumberColumnFilter' },
      { headerName: 'PO Header Id', field: 'poDetail.poHeaderId', filter: 'agNumberColumnFilter', enableRowGroup: true },
      { headerName: 'PO Detail Id', field: 'poDetailId', filter: 'agNumberColumnFilter', enableRowGroup: true },
      { headerName: 'Job Order', field: 'joDetail.joHeaderId', filter: 'agNumberColumnFilter', enableRowGroup: true },
      { headerName: 'Job Order Detail', field: 'joDetailId', filter: 'agNumberColumnFilter' },
      // { headerName: 'Account Guid', field: 'accountGuid',  },
      // { headerName: 'Manufacturer Guid', field: 'manufacturerGuid',  },
    ];

    const sortModel = [
        { colId: 'account.accountName', sort: 'asc' },
        { colId: 'product.productType.manufacturer.name', sort: 'asc' },
        { colId: 'product.productType.style', sort: 'asc' },
        
      ] as ISortModel;
    
    // next line is needed by the buildDatasource method
    AgFns.initGrid(this.pobGridOptions, colDefs, sortModel);
    this.pobGridOptions.autoGroupColumnDef = {
      sort: 'asc',
      minWidth: 300,
    };

    AgFns.applyGridRouteParams(this.pobGridOptions);

    // This needs to be done last in order to avoid excess query calls
    // this.updateDatasource();

    this.showHideIdColumns(false);
    AgFns.autoSizeAllColumns(this.pobGridOptions);

  }

  async onRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    let pob = e.data;
    if (!pob) {
      // This usually means that we are on a group node so ...
      pob = e.node?.allLeafChildren?.[0]?.data;
      if (!pob) { return; }
    }
    this.currentPob = pob;
    this.updateLocation(this.currentPob?.poDetailId);
  }

  async onCellValueChanged(event) {
    
    const colId = event?.column?.colId
    if (colId == 'poDetail.expectedShipDate') {
      this.currentPob.poDetail.expectedShipDateModTs = new Date(Date.now());
    }

  }

  onFilterChanged() {
    this.updateLocation();
  }

  onColumnRowGroupChanged(event) {
    // hides columns when grouped 
    // it turns out we don't need to unhide them when ungrouped 
    // because this happens automatically
    const cdefs = this.pobGridOptions.columnDefs;
    const colApi = this.pobGridOptions.columnApi
    const groupColumns = colApi.getRowGroupColumns();
    const groupColumnNames = groupColumns.map(gc => gc.getColId());
    colApi.setColumnsVisible(groupColumnNames, false);
    this.updateLocation();
  }

  
  async saveChanges() {
    const sr = await this.dbSaveService.saveChanges();
    this.pobGridOptions?.api?.refreshCells();
    this.dialogService.toast(`${sr.entities.length} records(s) saved`);
  }

  async cancelChanges() {
    this.dbSaveService.rejectChanges();
    await this.requery();
  }

  showHideIdColumns(forceShowHide: boolean = null) {
    if (forceShowHide == null) {
      this.shouldShowIdColumns = !this.shouldShowIdColumns;
    } else {
      this.shouldShowIdColumns = forceShowHide;
    }
    const poColApi = this.pobGridOptions.columnApi;
    if (poColApi) {
      poColApi.setColumnsVisible(
        ['accountId', 'product.productType.manufacturerId', 'poDetail.poHeaderId', 'poDetailId', 'joDetail.joHeaderId', 'joDetailId'], this.shouldShowIdColumns);
    }
  }

  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/backorders']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.pobGridOptions, key && key.toString());
    this.domainService.location.replaceState(url);
  }

  stopEditing() {
    this.pobGridOptions.api?.stopEditing();
  }

  getTitle() {

  }

  

  gotoAccountPage() {
    this.updateLocation(this.currentPob?.poDetailId);
    const accountGuid = this.currentPob?.joDetail.joHeader.account.accountCryptonym.guid;
    if (accountGuid != null) {
      this.router.navigate(['/account-backorders', accountGuid]);
    }
  }

  gotoManufPage() {
    this.updateLocation(this.currentPob?.poDetailId);
    const manufGuid = this.currentPob?.product.productType.manufacturer.manufacturerCryptonym.guid;
    if (manufGuid != null) {
      this.router.navigate(['/manufacturer-backorders', manufGuid]);
    }
  }

  async sendAccountEmail() {
    const account = this.currentPob?.joDetail.joHeader.account;
    const accountGuid = account?.accountCryptonym.guid;
    if (accountGuid == null) {
      await this.dialogService.showOkMessage("Unable to locate an Account CryptoGuid",
       "We cannot create this email because we have no Account CryptoGuid to insert into the message to the account holder");
      return
    }
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    
    const emailAddress = account?.invoiceEmail || '{This account does not have an Email address in the database}';
    (<any> window).location = `mailto:${emailAddress}?subject=Uniformax Backorder Info&body=Please click on the following link to access your Uniformax account backorders information: `
    + "%0D%0A %0D%0A "
    + `${baseUrl}/account-backorders/${accountGuid}`
    + "%0D%0A "
    + "------------------------ ";
    
  }

  async sendManufEmail() {
    const manuf = this.currentPob.product.productType.manufacturer;
    const manufId = manuf.id
    const manufGuid = manuf.manufacturerCryptonym.guid
    if (manufGuid == null) {
      await this.dialogService.showOkMessage("Unable to locate an Manufacturer CryptoGuid",
       "We cannot create this email because we have no Manufacturer CryptoGuid to insert into the message to the manufacturer");
      return
    }
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    
    const emailAddress = manuf?.whEmail || '{This manufacturer does not have a warehouse email address in the database}';
    (<any> window).location = `mailto:${emailAddress}?subject=Uniformax Backorder Info&body=Please click on the following link to access your Uniformax backorder information: `
    + "%0D%0A %0D%0A "
    + `${baseUrl}/manufacturer-backorders/${manufGuid}`
    + "%0D%0A "
    + "------------------------ ";
    
  }

  async resetAccountGuid() {
    return await this.dialogService.showOkMessage('Not yet implemented', 'This feature has not yet been implemented');
  }

  async resetManufGuid() {
    return await this.dialogService.showOkMessage('Not yet implemented', 'This feature has not yet been implemented');
    // const guid = this.UtilFns.generateGUID();
    // const manufId = this.currentPob?.manufacturerId;
    // const manuf = await this.dbQueryService.getManufacturer(manufId);
  }

  goBack() {
    this.domainService.location.back();
  }
}
