import { GetRowIdParams, GridOptions, RowSelectedEvent, ValueGetterParams, GridReadyEvent } from '@ag-grid-community/core';
import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { ProductTypeAccountCrosstabSummary, ProductTypeAccountSummary, ProductCrosstabSummary, ProductSummary, ProductTypeXXXCrosstabSummary, ProductTypeXXXSummary } from 'app/model/entities/-product-type-summary';
import { EOQType, ProductType, Product, ProductEOQ } from 'app/model/entities/entity-model';
import { EOQTypeCode } from 'app/model/enums/eoq-type-code';
import { AgFns, GridState, ISortModel } from 'app/shared/ag-fns';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { UtilFns } from 'app/shared/util-fns';
import { EntityState } from 'breeze-client';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'inventory-eoq',
  templateUrl: './inventory-eoq.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class InventoryEoqComponent extends DomainBaseComponent implements OnInit {
  @ViewChild('ptButtonsCell') ptButtonsCell: TemplateRef<any>;

  ptGridOptions: GridOptions;
  ptaGridOptions: GridOptions;

  prodGridOptions: GridOptions;
  showPercentages = false;

  currentProductType: ProductType;
  currentProductsForEOQ: Product[]; // products restricted to just reorderable ones
  eOQTypes: EOQType[];

  ptaItems: ProductTypeAccountCrosstabSummary[];
  ptaTotals: ProductTypeAccountCrosstabSummary[];  // just one row

  prodItems: ProductCrosstabSummary[];
  prodTotals: ProductCrosstabSummary[];  // just one row


  constructor(protected domainService: DomainService, protected route: ActivatedRoute, protected matDialog: MatDialog, protected location: Location) {
    super(domainService);

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    // this.gridState = AgFns.createGridState(this.route.snapshot.queryParams, 'id');
    this.eOQTypes = this.dbQueryService.getAllCached(EOQType);

    this.ptGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPtGridReady,
      onRowSelected: this.onPtRowSelected,
      onFilterChanged: this.onFilterChanged,
      onCellValueChanged: this.onProdCellValueChanged, // note NOT ptCellValueChanged
      rowModelType: 'serverSide',
      onPaginationChanged: (params) => {
        if (params.newPage) {
          this.updateLocation();
        }
      },
    });
    AgFns.captureGridRouteParams(this.ptGridOptions, this.route, 'id');
    
    this.ptaGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPtaGridReady,
      getRowId: this.getPtaRowId,
      getRowStyle: this.getPtaRowStyle,
    });

    this.prodGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onProdGridReady,
      onCellValueChanged: this.onProdCellValueChanged,
      getRowId: this.getProdRowId,
      getRowStyle: this.getPtaRowStyle, // note same style as above
    });

    this.isPageReady = true;
  }

  async canDeactivate() {
    if (this.isBusy) return false;
    this.stopEditing();
    if (this.uow.hasChanges()) {
      const ynResult = await this.dialogService.askYesNo('Changes have not been saved', 'Do you want to save before exiting?');
      if (ynResult.index == 0) {
        await this.dbSaveService.saveChanges();
      } else {
        this.dbSaveService.rejectChanges();
      }
    }

    this.uow.clearEntities(ProductType);
    return true;
  }

  async onPtGridReady(evt: GridReadyEvent) {
    const cellStyle = this.user.isInventoryAdmin ? AgFns.setEditableStyle : ((p) => null);
    const colDefs = [
      { ...AgFns.createCellButtonProps('', this.ptButtonsCell) as any },
      { headerName: 'Manufacturer', field: 'manufacturer.name', filter: 'agTextColumnFilter' },
      { headerName: 'SKU', field: 'style', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'description', filter: 'agTextColumnFilter' },
      { headerName: 'EOQ Type', field: 'eOQTypeId', filter: 'agTextColumnFilter', editable: true, cellStyle: cellStyle,
      ...AgFns.createDropdownEditorPropsFromArray(this.eOQTypes, 'id', 'name') },
      { headerName: 'Lead Time (days)', field: 'leadTimeDays', editable: true, cellStyle: AgFns.setEditableStyle, filter: 'agNumberColumnFilter' },
      { headerName: 'Ordering Cost', field: 'orderingCostAmt', editable: true, cellStyle: AgFns.setEditableStyle, filter: 'agNumberColumnFilter' },
      { headerName: 'Unit Carrying Cost/Year', field: 'unitCarryingCostPerYearAmt', editable: true, cellStyle: AgFns.setEditableStyle, filter: 'agNumberColumnFilter' },
      { headerName: 'Safety Stock/Year Pct', field: 'safetyStockPerYearPct', editable: true, cellStyle: AgFns.setEditableStyle, filter: 'agNumberColumnFilter' },
      { headerName: 'Product Type Id', field: 'id', filter: 'agNumberColumnFilter' },
      { headerName: 'Last modified', field: 'eOQModTs' }

    ];
/*     colDefs.push({ ...AgFns.createCellButtonProps('', this.ptButtonsCell) as any }); */
    const sortModel = [
        { colId: 'manufacturer.name', sort: 'asc' },
        { colId: 'style', sort: 'asc' },
        { colId: 'description', sort: 'asc' },
      ] as ISortModel;
    
    AgFns.initGrid(this.ptGridOptions, colDefs, sortModel);
    
    this.updateDatasource();
    AgFns.applyGridRouteParams(this.ptGridOptions);
  }

  async onPtaGridReady(evt: any) {
    const currYear = (new Date()).getFullYear();
    const colDefs = [
      // { headerName: 'Manufacturer', field: 'manufacturer.name', filter: 'agTextColumnFilter' },
      // { headerName: 'Style', field: 'productType.style', filter: 'agTextColumnFilter' },
      // { headerName: 'Description', field: 'productType.description', filter: 'agTextColumnFilter' },
      { headerName: 'Account', filter: 'agTextColumnFilter',
        valueGetter: (params: ValueGetterParams) => {
          if (params.node?.isRowPinned()) {
            return 'Total';
          }
          const x = <ProductTypeAccountCrosstabSummary> params.data;
          return (x && x.account?.accountName) ?? '[None]' ;
        } },
      { headerName: `Qty-${currYear}-YTD`, field: 'year0Qty', valueFormatter: (p) => p.value || '' },
      { headerName: `Qty-${currYear - 1}`, field: 'year1Qty', valueFormatter: (p) => p.value || '' },
      { headerName: `Qty-${currYear - 2}`, field: 'year2Qty', valueFormatter: (p) => p.value || '' },
      { headerName: `Qty-${currYear - 3}`, field: 'year3Qty', valueFormatter: (p) => p.value || '' },
      { headerName: `Qty-Inventory`, field: 'inventoryQty', valueFormatter: (p) => p.value || '' }
    ];
    const sortModel = [
      { colId: 'account.accountName', sort: 'asc' as const},
    ];

    AgFns.initGrid(this.ptaGridOptions, colDefs, sortModel, true);
  }

  async onProdGridReady(evt: any) {
    const currYear = (new Date()).getFullYear();
    const colDefs = [
      // { headerName: 'Manufacturer', field: 'manufacturer.name', filter: 'agTextColumnFilter' },
      // { headerName: 'Style', field: 'productType.style', filter: 'agTextColumnFilter' },
      // { headerName: 'Description', field: 'productType.description', filter: 'agTextColumnFilter' },
      // { headerName: 'ProductId', field: 'productId',  },
      { headerName: 'Features', field: 'product.featureChoicesExtract', filter: 'agTextColumnFilter' },
      { headerName: `Qty-${currYear}-YTD`, field: 'year0Qty', valueFormatter: (p) => p.value || '' },
      { headerName: `Qty-${currYear - 1}`, field: 'year1Qty', valueFormatter: (p) => p.value || '' },
      { headerName: `Qty-${currYear - 2}`, field: 'year2Qty', valueFormatter: (p) => p.value || '' },
      { headerName: `Qty-${currYear - 3}`, field: 'year3Qty', valueFormatter: (p) => p.value || '' },
      { headerName: `Qty-Inventory`, field: 'inventoryQty', valueFormatter: (p) => p.value || '' },
      { headerName: `Pct-${currYear}-YTD`, field: 'year0Pct', valueFormatter: (p) => UtilFns.fmtPct(p.value, 2) },
      { headerName: `Pct-${currYear - 1}`, field: 'year1Pct', valueFormatter: (p) => UtilFns.fmtPct(p.value, 2) },
      { headerName: `Pct-${currYear - 2}`, field: 'year2Pct', valueFormatter: (p) => UtilFns.fmtPct(p.value, 2) },
      { headerName: `Pct-${currYear - 3}`, field: 'year3Pct', valueFormatter: (p) => UtilFns.fmtPct(p.value, 2) },
      { headerName: 'Qty Projected', field: 'projQty', type: 'numericColumn', editable: true, cellStyle: AgFns.setEditableStyle, valueParser: (p) => this.parseIntOr0(p.newValue) },
      { headerName: 'Calc EOQ', field: 'calcEOQQty', type: 'numericColumn', valueFormatter: (p) => this.fmtNumberOrMproding(p) },
      { headerName: 'Safety Stock', field: 'calcSafetyStockQty', type: 'numericColumn', valueFormatter: (p) => this.fmtNumberOrMproding(p) },
      { headerName: 'Reorder Point', field: 'calcReorderPointQty', type: 'numericColumn', valueFormatter: (p) => this.fmtNumberOrMproding(p) },
      { headerName: 'Reorder Period (days)', field: 'calcReorderPeriod', type: 'numericColumn', valueFormatter: (p) => this.fmtNumberOrMproding(p) },
    ];
    const sortModel = [
      { colId: 'eOQSize', sort: 'asc' },
    ] as ISortModel;

    AgFns.initGrid(this.prodGridOptions, colDefs, sortModel, true);
    this.showHideProdColumns();
  }

  parseIntOr0(value: any) {
    const v = parseInt(value);
    return isNaN(v) ? 0 : v;
  }

  fmtNumberOrMproding(p: any) {
    const value = p.value;
    if (value == null) { return '' };
    return value;
  }

  updateDatasource() {
    const gridApi = this.ptGridOptions.api;
    if (gridApi == null) {
      return;
    }

    const opts = {
      initFn: () => {
        // clear bottom grid after change in top grid.
        this.ptaItems = [];
        this.setSummaryValues(this.ptaGridOptions, []);

        this.prodItems = [];
        this.setSummaryValues(this.prodGridOptions, []);
      } 
    }
    const ds = AgFns.buildDatasource(
      () => this.dbQueryService.createProductTypesForEOQQuery(), null, opts);
      
    gridApi.setServerSideDatasource(ds);

    AgFns.applyGridRouteParams(this.ptGridOptions);
  }

  onFilterChanged() {
    this.updateLocation();
  }

  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/inventory-eoq']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.ptGridOptions, key && key.toString());
    this.domainService.location.replaceState(url);
  }

  async onPtRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    const pt = e.data as ProductType;
    if (!pt) {
      return;
    }
    this.currentProductType = pt;
    this.updateLocation(pt.id);

    // narrow products to just those that are reorder products or do not have a reorder product
    if (pt.allowReorderFeatureChoices) {
      const reorderProducts = pt.products.map((p) => p.getReorderProduct());
      this.currentProductsForEOQ = _.uniq(reorderProducts)
    } else {
      this.currentProductsForEOQ = pt.products;  
    }     
    await this.AgFns.busyGrid([this.ptaGridOptions, this.prodGridOptions], this.busyService, async () => {
      await this.getProductTypeAccountSummaries(pt.id);
      await this.getProductSummaries(pt.id);
    });

  }

  async editStyle(pt: ProductType) {
    if (!this.user.isInventoryAdmin) {
      this.dialogService.showOkMessage('Unable to Continue', 'Inventory Admin. rights are required for this action');
      return;
    }
    this.updateLocation(pt.id);
    this.router.navigate(['./product-type', pt.id]);
  }

  setSummaryValues(gridOptions: GridOptions, summaryValues: any[]) {
    if (gridOptions == this.prodGridOptions) {
      this.prodTotals = summaryValues;
    } else if (gridOptions == this.ptaGridOptions) {
      this.ptaTotals = summaryValues;
    }
    gridOptions.api.setPinnedBottomRowData(summaryValues);
    AgFns.autoSizeAllColumns(gridOptions);
  }

  async getProductTypeAccountSummaries(productTypeId: number) {
    const q = this.dbQueryService.createProductTypeAccountSummaryQuery(productTypeId);
    const r = await q.execute()
    const summaries = r as unknown as ProductTypeAccountSummary[];
    const { crossTabSummaries, summaryObj } = this.createCrosstabSummaries(summaries, (s) => { return { productTypeId: s.productTypeId, accountId: s.accountId } });
    this.ptaItems = crossTabSummaries as ProductTypeAccountCrosstabSummary[];
    this.setSummaryValues(this.ptaGridOptions, [summaryObj]);
  }

  oldSummaries: any[] = [];

  async getProductSummaries(productTypeId: number) {
    const q = this.dbQueryService.createProductSummaryQuery(productTypeId);
    const r = await q.execute()
    const summaries = r as unknown as ProductSummary[];
    this.oldSummaries.push(summaries as any);
    // const { crossTabSummaries, summaryObj } = this.createCrosstabSummaries(summaries, (s) => { return { reorderProductId: s.product.getReorderProduct().id } });
    const { crossTabSummaries, summaryObj } = this.createCrosstabSummaries(summaries, (s) => { return { reorderProductId: s.product.getReorderProduct().id } });
    if (this.currentProductType.eOQTypeId == EOQTypeCode.None) {
      this.prodItems = (crossTabSummaries as ProductCrosstabSummary[]);
    } else {
      this.prodItems = await this.updateProductEOQRefs(productTypeId, crossTabSummaries);
    }
    AgFns.refreshGrid(this.prodGridOptions, this.prodItems);

    this.updatePctSummaryValues(summaryObj);
    this.setSummaryValues(this.prodGridOptions, [summaryObj]);

    this.showHideProdColumns();

    // needed to insure that projQty total gets populated
    await UtilFns.wait(0);
    this.onProdCellValueChanged(null);
  }

  private createCrosstabSummaries<T extends ProductTypeXXXSummary>(ptaSummaries: T[], keyFn: (ptaSummary: T) => Object) {
    const currYear = (new Date()).getFullYear();
    const summaryObj = { year0Qty: 0, year1Qty: 0, year2Qty: 0, year3Qty: 0, inventoryQty: 0 } as any;
    const temp = ptaSummaries.reduce<any>((acc, ptaSummary) => {
      const key = JSON.stringify(keyFn(ptaSummary));
      const { qty, invoiceYear, ...rest } = ptaSummary;
      acc[key] = acc[key] || { ...rest, year0Qty: 0, year1Qty: 0, year2Qty: 0, year3Qty: 0, inventoryQty: 0 };
      const item = acc[key];
      const yearKey = (ptaSummary.invoiceYear == -1 || ptaSummary.invoiceYear == null) ? 'inventoryQty' : `year${currYear - ptaSummary.invoiceYear}Qty`;
      //const yearKey = (ptaSummary.invoiceYear == -1 ) ? 'inventoryQty' : `year${currYear - ptaSummary.invoiceYear}Qty`;
      item[yearKey] = (item[yearKey] || 0) + ptaSummary.qty;
      summaryObj[yearKey] = (summaryObj[yearKey] || 0) + ptaSummary.qty;
      return acc;
    }, {} as any);
    const crossTabSummaries = Object.values(temp) as ProductTypeXXXCrosstabSummary[];
    return { crossTabSummaries, summaryObj };
  }

  private async updateProductEOQRefs(productTypeId: number, crossTabSummaries: ProductTypeXXXCrosstabSummary[]) {
    const prodEOQs = await this.dbQueryService.getProductEOQs(productTypeId);
    const prodEOQMap = new Map<number, ProductEOQ>();
    prodEOQs.forEach(prodEoq => prodEOQMap.set(prodEoq.productId, prodEoq));
    // match each crossTabItem ( corresponding to an product) to an ProductTypeEOQ or create one if it doesn't exist. 
    let entitiesToSave = [];
    const prodItems = (crossTabSummaries as ProductCrosstabSummary[]).map(prodSummary => {
      // we need an actual instance of the ProductCrosstabSummary class in order for instance methods to work. 
      const prodCts = new ProductCrosstabSummary();
      Object.assign(prodCts, prodSummary);
      let prodEoq = prodEOQMap.get(prodCts.productId);
      if (prodEoq == null) {
        const prodForEOQ = this.currentProductsForEOQ.find(x => x.id == prodSummary.productId);
        if (prodForEOQ == null) {
          return null; // This shouldn't happen but we want to ignore this value if it does.
        }
        const eoqTemplate = <ProductEOQ>{ productId: prodForEOQ.id, projectedUsageQty: 0 };
        prodEoq = this.uow.createEntity(ProductEOQ, eoqTemplate, EntityState.Added);
        entitiesToSave.push(prodEoq);
      }
      prodCts.productEOQ = prodEoq;
      return prodCts;
    }).filter(x => x != null);

    if (entitiesToSave.length > 0) {
      await this.dbSaveService.saveSelectedChanges(entitiesToSave);
    }
    return prodItems;
  }

  private updatePctSummaryValues(summaryObj: ProductCrosstabSummary) {
    summaryObj.projQty = 0;
    let calcEOQQty = 0
    this.prodItems.forEach(item => {
      item["year0Pct"] = (item.year0Qty / summaryObj.year0Qty);
      item["year1Pct"] = (item.year1Qty / summaryObj.year1Qty);
      item["year2Pct"] = (item.year2Qty / summaryObj.year2Qty);
      item["year3Pct"] = (item.year3Qty / summaryObj.year3Qty);
      summaryObj.projQty += item.projQty ?? 0;
      calcEOQQty += this.parseIntOr0(item.calcEOQQty);
    });
    summaryObj.calcEOQQty = calcEOQQty;
    summaryObj["year0Pct"] = summaryObj.year0Qty ? 1 : '';
    summaryObj["year1Pct"] = summaryObj.year1Qty ? 1 : '';
    summaryObj["year2Pct"] = summaryObj.year2Qty ? 1 : '';
    summaryObj["year3Pct"] = summaryObj.year3Qty ? 1 : '';
  }

  getPtaRowStyle(params) {
    if (params.node.rowPinned) {
      return { 'font-weight': 'bold' };
    }
  };

  getPtaRowId(params: GetRowIdParams) {
    const s = params.data as ProductTypeAccountCrosstabSummary;
    return JSON.stringify({ productTypeId: s?.productTypeId, accountId: s?.accountId });
  }

  getProdRowId(params: GetRowIdParams) {
    const s = params.data as ProductCrosstabSummary;
    return s.productId.toString();
  }

  async onProdCellValueChanged(event) {
    if (event != null) {
      if (event.oldValue != null || event.newValue != null) {
        this.currentProductType.eOQModTs = new Date(Date.now());
        this.ptGridOptions.api.redrawRows();
      }
    }

    if (event?.column?.colId == 'eOQTypeId') {
      if (event.newValue != EOQTypeCode.None) {
        await this.getProductSummaries(this.currentProductType.id);
        this.prodGridOptions.api.redrawRows();
        AgFns.autoSizeAllColumns(this.prodGridOptions);
      }
      this.showHideProdColumns();
    }
    
    // Note: this is subtle - but this stopEditing is needed because otherwise if you
    // are editting the last cell in the grid before the footer and hit tab you will be
    // 'editing' the totals cell - when in editmode - changes to the cell via binding 
    // are ignored - so total calcs will be ignored in this case. 
    const cell = this.prodGridOptions.api.getFocusedCell();
    if (cell?.rowPinned) {
      this.prodGridOptions.api.stopEditing();
    }

    this.calcProjQtyColumn(event && event.rowPinned && event.newValue);
    this.calcEOQColumns();

  }

  private calcProjQtyColumn(valueToProrate: any) {
    if (this.prodItems.length == 0) {
      return;
    }
    let total = 0;

    const gridApi = this.prodGridOptions.api;

    gridApi.forEachNode(n => {
      total += this.parseIntOr0(n.data?.projQty);
    });
    if (valueToProrate != null) {
      if (total == 0) {
        this.dialogService.showOkMessage('Unabled to prorate', 'Unable to prorate based on existing values because they are all 0.')
        return;
      }
      const newTotal = this.parseIntOr0(valueToProrate);
      let cumTotal = 0;
      // prorate value across all the cells
      let maxProd: ProductCrosstabSummary = null;
      this.prodItems.forEach(prod => {
        const v = prod.projQty ?? 0;
        if (maxProd == null || maxProd.projQty < v) {
          maxProd = prod;
        }
        prod.projQty = Math.round(newTotal * (v / total));
        cumTotal += prod.projQty ?? 0;
      });

      maxProd.projQty += newTotal - cumTotal;
      this.refreshUIForProdItems();
      
    } else {
      // add up all the values 
      const summary = this.prodTotals[0];
      summary.projQty = total;
      this.setSummaryValues(this.prodGridOptions, [summary]);
    }
  }

  calcEOQColumns() {
    if (this.prodTotals.length == 0) {
      return;
    }
    if (this.currentProductType == null) {
      return;
    }
    if (this.currentProductType.eOQTypeId == EOQTypeCode.None) {
      return;
    }
    this.updateDefaultProductTypeValues();
    let sumEOQQty = 0;
    const errorStrings = this.checkForMprodingData();
    this.prodItems.forEach(prod => {
      prod.recalcAll();
      sumEOQQty += prod.calcEOQQty;
    });
    
    this.refreshUIForProdItems();
    
    const summary = this.prodTotals[0];
    summary.calcEOQQty = sumEOQQty;
    this.setSummaryValues(this.prodGridOptions, [summary])
  }

  private refreshUIForProdItems() {
    this.prodItems = this.prodItems.slice();
    // Hack - this is needed because ag-grid does not do change detection on getters automatically - this forces it. 
    this.prodGridOptions.api.refreshCells({ force: true });
  }

  checkForMprodingData() {
    const pt = this.currentProductType;
    const errorStrings: string[] = [];
    if (pt.orderingCostAmt == null) {
      errorStrings.push('missing ordering cost');
    }
    if (pt.unitCarryingCostPerYearAmt == null) {
      errorStrings.push('missing carrying cost');
    }
    if (pt.safetyStockPerYearPct == null) {
      errorStrings.push('missing safety stock pct');
    }
  }

  private updateDefaultProductTypeValues() {
    const pt = this.currentProductType;
    if (pt.eOQTypeId == EOQTypeCode.None) { return; }
    let shouldRefreshPtGrid = false;

    if (pt.leadTimeDays == null) {
      pt.leadTimeDays = pt.manufacturer.leadTimeDays;
      shouldRefreshPtGrid = true;
    }
    const appConfig = this.dbQueryService.appConfig;
    if (pt.orderingCostAmt == null) {
      pt.orderingCostAmt = appConfig.productTypeDefault.orderingCostAmt;
      shouldRefreshPtGrid = true;
    }

    if (pt.safetyStockPerYearPct == null) {
      pt.safetyStockPerYearPct = appConfig.productTypeDefault.safetyStockPerYearPct;
      shouldRefreshPtGrid = true;
    }

    if (pt.unitCarryingCostPerYearAmt == null) {
      pt.unitCarryingCostPerYearAmt = appConfig.productTypeDefault.unitCarryingCostPerYearAmt;
      shouldRefreshPtGrid = true;
    }

    if (shouldRefreshPtGrid) {
      this.ptGridOptions.api.redrawRows();
    }
  }

  toggleShowPercentages() {
    this.showPercentages = !this.showPercentages;
    this.showHideProdColumns();
  }

  showHideProdColumns() {
    const columnApi = this.prodGridOptions?.columnApi;
    if (columnApi == null) { return; }
    const qtyFields = ["year0Qty", "year1Qty", "year2Qty", "year3Qty", 'inventoryQty'];
    const pctFields = ["year0Pct", "year1Pct", "year2Pct", "year3Pct",];
    const eoqFields = ["projQty", "calcEOQQty", "calcSafetyStockQty", "calcReorderPointQty"]
    columnApi.setColumnsVisible(qtyFields, !this.showPercentages);
    columnApi.setColumnsVisible(pctFields, this.showPercentages);
    columnApi.setColumnsVisible(eoqFields, this.currentProductType?.eOQTypeId != EOQTypeCode.None);
    columnApi.setColumnsVisible(['calcSafetyStockQty', 'calcReorderPeriod'], this.currentProductType?.eOQTypeId == EOQTypeCode.FixedTime);
    columnApi.setColumnsVisible(['calcReorderPointQty'], this.currentProductType?.eOQTypeId == EOQTypeCode.FixedQuantity);
  }

  canShowFormula() {
    const eOQTypeId = this.currentProductType?.eOQTypeId;
    return eOQTypeId == EOQTypeCode.FixedTime || eOQTypeId == EOQTypeCode.FixedQuantity;
  }

  showFormula() {
    const eOQTypeId = this.currentProductType?.eOQTypeId;
    if (eOQTypeId == EOQTypeCode.FixedQuantity) {
      this.dialogService.showOkMessage("Fixed Quantity", `EOQ = SQRT((2 * ProjectedAnnualQuantity * OrderingCostPerOrder)/UnitCarryingCostPerYear))<br>
        ReorderPoint = Round( ((ProjectedAnnualQuantity/250) * LeadTimeInDays) + (SafetyStockPctPerYear * ProjectedAnnualQuantity) )<br> )
           (Note: 250 is the approximate number of workdays in a normal year)`);
    } else if (eOQTypeId == EOQTypeCode.FixedTime) {
      this.dialogService.showOkMessage("Fixed Time", `EOQ = SQRT((2 * (ProjectedAnnualQuantity * (1 + SafetyStockPctPerYear)) * OrderingCostPerOrder)/UnitCarryingCostPerYear))<br>
        SafetyStock = Round(ProjectedAnnualQuantity * SafetyStockPctPerYear)`);
    }
  }

  async cancelChanges() {
    this.stopEditing();
    this.uow.rollback();
    this.ptGridOptions.api.redrawRows();
    this.showHideProdColumns();
    this.onProdCellValueChanged(null);
  }

  async saveChanges() {
    this.stopEditing();
    const sr = await this.dbSaveService.saveChanges();
    this.dialogService.toast('EOQ Changes saved');
  }

  stopEditing() {
    this.ptGridOptions.api.stopEditing();
    this.prodGridOptions.api.stopEditing();
  }

  goProductTypes() {
    this.router.navigate(['/product-types']);
  }

  // calcEOQ(prod: ProductCrosstabSummary) {
  //   const pt = this.currentProductType;

  //   const qty = prod.projQty;
  //   if (qty ==  0) {
  //     prod.calcEOQQty = 0;
  //   } else if (pt.eOQTypeId == EOQTypeCode.FixedQuantity) {
  //     const base = (2 * qty * pt.orderingCostAmt)/pt.unitCarryingCostPerYearAmt;
  //     prod.calcEOQQty = Math.round(Math.sqrt(base));
  //   } else if (pt.eOQTypeId == EOQTypeCode.FixedTime) {
  //     const safetyStock =  qty * (pt.safetyStockPerYearPct);
  //     const base = (2 * ( qty + safetyStock) * pt.orderingCostAmt)/pt.unitCarryingCostPerYearAmt;
  //     prod.calcEOQQty = Math.round(Math.sqrt(base));
  //   } else {

  //   }

  // }

  // calcSafetyStock(prod: ProductCrosstabSummary) {
  //   const pt = this.currentProductType;
  //   const qty = prod.projQty;
  //   if (qty == 0) {
  //     prod.calcSafetyStockQty = 0;
  //   } else {
  //     prod.calcSafetyStockQty = Math.round(qty * pt.safetyStockPerYearPct);
  //   }
  // }

  // calcReorderPoint(prod: ProductCrosstabSummary) {
  //   const pt = this.currentProductType;
  //   const eoqQty = prod.calcEOQQty;
  //   if (eoqQty == 0) {
  //     prod.calcReorderPointQty = 0;
  //   } else {
  //     prod.calcReorderPointQty = Math.round(((eoqQty/250)*(pt.leadTimeDays ?? 0)) + (pt.safetyStockPerYearPct * eoqQty));
  //   }
  // }

  // calcReorderPeriod(prod: ProductCrosstabSummary) {
  //   const pt = this.currentProductType;
  //   const eoqQty = prod.calcEOQQty;
  //   if (eoqQty == 0) {
  //     prod.calcReorderPeriod = 0;
  //   } else {
  //     prod.calcReorderPeriod =  Math.round( 365 * eoqQty / prod.projQty);
  //   }
  // }


}
