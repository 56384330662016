<app-uni-page  [parent]="this" pageTitle="Purchase Orders needed per EOQ calculations" 
  [bodyTemplate]="bodyTemplate"  [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <app-ag-grid [gridOptions]="issEOQGridOptions" [rowData]="issEOQs"></app-ag-grid>
  <ng-template #createPoCell let-row>
    <button *ngIf="canCreatePo(row)" (click)="createPo(row)">Create PO</button>
  </ng-template>

</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
  </div>
  <div>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>
