<div id="home" class="page-layout simple fullwidth">

  <div class="header accent p-24 h-160" style="display: flex;  flex-flow: row wrap;  justify-content: space-between;">

    <div>
      <div class="h2 mt-16">In Process</div>
    </div>

  </div>

  <div class="content">
    <div class="cards">
      <mat-card routerLink="/jo-in-process">
        <div class="h1">
          <mat-icon>assignment</mat-icon>
          Job Orders
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>

      <mat-card routerLink="/addon-bins">
        <div class="h1">
          <mat-icon>assignment</mat-icon>
          Add-On Bins
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>
      <mat-card routerLink="/jo-ship-prep">
        <div class="h1">
          <mat-icon>shopping_cart</mat-icon>
          Ship Prep
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>
    </div>
    <div class="cards">
      <mat-card routerLink="/invoices">
        <div class="h1">
          <mat-icon>shopping_cart</mat-icon>
          Vouchers
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>
      <mat-card routerLink="/jo-ship">
        <div class="h1">
          <mat-icon>assessment</mat-icon>
          Shipping and Invoicing
        </div>
        <div class="mt-16">Click for more</div>
      </mat-card>      
      <mat-card (click)="goBack()">
        <div class="h1">
          <mat-icon>work</mat-icon>
          Go Back
        </div>
        
      </mat-card>


    </div>
    

  </div>