import { CellValueChangedEvent, ColDef, GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { InvoiceHeader, InvoiceStatus, ShipBin, ShipFrequency, Shipment } from 'app/model/entities/entity-model';
import { InvoiceStatusCode } from 'app/model/enums/invoice-status-code';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { AgFns } from 'app/shared/ag-fns';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { NavFns } from 'app/shared/nav-fns';
import { UtilFns } from 'app/shared/util-fns';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { InvoicePrintDialogComponent } from './invoice-print-dialog.component';
import { InvoiceShipmentService } from './invoice-shipment.service';
import { DateFns } from 'app/shared/date-fns';
import { ShipFrequencyDialogComponent } from './ship-frequency-dialog.component';
import { BarcodeService } from 'app/shared/barcode.service';


@Component({
  selector: 'app-jo-ship',
  templateUrl: './jo-ship.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class JoShipComponent extends DomainBaseComponent {

  navigateToShipBinId?: number;
  
  shipBinGridOptions: GridOptions; 
  shipBins: ShipBin[];
  allOpenShipBins: ShipBin[];
  selectedShipBin: ShipBin;
  showCurrentBinsOnly = true;

  invoiceHeaders: InvoiceHeader[] = [];
  invhGridOptions: GridOptions;

  currentShipment: Shipment = null;

  constructor(protected domainService: DomainService, protected route: ActivatedRoute,
    protected matDialog: MatDialog, protected invoiceShipmentService: InvoiceShipmentService,
    protected barcodeService: BarcodeService) {
    super(domainService);

    this.dbQueryService.getAllCached(InvoiceStatus);
    this.dbQueryService.getAllCached(ShipFrequency);
    

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {

    this.navigateToShipBinId = +this.route.snapshot.params['shipBinId'];

    this.shipBinGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onShipBinGridReady,
      onRowSelected: this.onShipBinRowSelected,
      onFirstDataRendered: (params) => {
        if (this.navigateToShipBinId) {
          AgFns.selectGridRowByKey(this.shipBinGridOptions.api, (e) => e.id, this.navigateToShipBinId.toString());
        } else {
          AgFns.selectFirstRow(this.shipBinGridOptions);
        }
      }
    });
    AgFns.captureGridRouteParams(this.shipBinGridOptions, this.route, 'id');

    this.invhGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onInvGridReady,
      onRowSelected: this.onInvRowSelected,
      onCellValueChanged: this.onCellValueChanged,
      onFirstDataRendered: (params) => this.AgFns.autoSizeAllColumns(this.invhGridOptions)
    });
    
    
    const vInvoiceHeaders = await this.dbQueryService.getVoucherInvoices();

    this.allOpenShipBins = _.uniq(vInvoiceHeaders.map(invh => invh.shipBin));
    this.allOpenShipBins = this.allOpenShipBins.filter(x => x != null);
    this.refreshAndUpdate();

    this.isPageReady = true;
  }

  refreshAndUpdate() {
    const todayMs = DateFns.startOfDay(new Date()).getTime();
    if (this.showCurrentBinsOnly) {
      this.shipBins = this.allOpenShipBins.filter(x => x.expectedShipDate.getTime() <= todayMs);
    } else {
      this.shipBins = this.allOpenShipBins;
    }
    if (!this.shipBins.includes(this.selectedShipBin)) {
      this.selectedShipBin = null;
      this.invoiceHeaders = [];
      this.currentShipment = null;
    }
  }

  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/jo-ship']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.shipBinGridOptions, key && key.toString());
    this.domainService.location.replaceState(url);
  }

  onShipBinGridReady(evt: any) {
    this.shipBinGridOptions.rowSelection = 'single';
    const colDefs = [
      { ...AgFns.createButtonProps('Print Ship Bin Label', this.printShipBinLabel.bind(this)) 
        ,minWidth: 150
      },
      { headerName: 'Ship Bin Id', field: 'id', },
      { headerName: 'Frequency', field: 'firstJoh.shipFrequency.name', },
      { headerName: 'Expected Ship Dt', field: 'expectedShipDate',  },
      { headerName: 'Account', field: 'firstJoh.account.accountName', },
      { headerName: 'Name', field: 'firstJoh.shippingName', },
      { headerName: 'Address1', field: 'firstJoh.shippingAddress1' },
      { headerName: 'Address2', field: 'firstJoh.shippingAddress2' },
      { headerName: 'City', field: 'firstJoh.shippingCity' },
      { headerName: 'State', field: 'firstJoh.shippingState' },
      { headerName: 'Zip', field: 'firstJoh.shippingZipCode' },
      { headerName: 'Invoice Count', field: 'invoiceHeaders.length', type: 'numericColumn' }
    ];
    const sortFields = [
      'firstJoh.account.accountName',
      'firstJoh.shippingName',
      'firstJoh.shippingAddress1',
      'firstJoh.shippingAddress2',
    ];
    const sortModel =  sortFields.map(sf => {
        return { colId: sf, sort: 'asc'  as const};
      });


    AgFns.initGrid(this.shipBinGridOptions, colDefs, sortModel, true);

    AgFns.applyGridRouteParams(this.shipBinGridOptions);

    
  }

  onInvGridReady(evt: any) {
    const colDefs: ColDef[] = [
      { ...AgFns.createButtonProps('Print Invoice Package Label', this.printInvoicePackageLabel.bind(this)) 
        ,minWidth: 200
      },
      { colId: 'addRemove', ...AgFns.createButtonProps('Add/Remove', this.addToRemoveFromShipment.bind(this), 
        { calcLabel: this.calcAddToRemoveFromShipmentLabel.bind(this),  canDisplay: this.canDisplayAddToRemoveFromShipment.bind(this) }), 
       minWidth: 170
      },
      { ...AgFns.createButtonProps('Calc Freight', this.calcFreight.bind(this), 
        { label: 'Calc Freight',  canDisplay: this.canCalcFreight.bind(this) }), 
       minWidth: 170
      },
      { ...AgFns.createButtonProps('Print Shipping Label', this.printShippingLabel.bind(this), 
        { label: 'Print Label',  canDisplay: this.canPrintShippingLabel.bind(this) }), 
       minWidth: 150
      },
      { ...AgFns.createButtonProps('Print Invoice', this.printInvoice.bind(this), 
        { label: 'Print/Post Invoice',  canDisplay: this.canPrintInvoice.bind(this) }), 
       minWidth: 170
      },
      { ...AgFns.createButtonProps('Void Shipping Label', this.voidAction.bind(this), 
        { calcLabel: this.calcVoidActionLabel.bind(this),  canDisplay: this.canVoidAction.bind(this) }), 
       minWidth: 150
      },
      { headerName: 'Shipment', field: 'shipmentId'  },
      { headerName: 'Primary Invoice', field: 'isPrimaryInvoice', cellRenderer: AgCheckboxCellComponent, minWidth: 120 },
      { headerName: 'Invoice', field: 'id', minWidth: 100, ...NavFns.createCellClickedCalcNavProps(this.router,
        (event) => { 
          const invh = <InvoiceHeader> event.data;
          return { navPath: `/jo-invoice/${ invh.joHeaderId }/${ invh.id }` }; 
        })
      },
      { headerName: 'Date', field: 'invoiceDate', minWidth: 80 },
      { headerName: 'Status', field: 'invoiceStatus.name', minWidth: 120 },
      {
        headerName: 'Freight',
        type: 'numericColumn',
        valueFormatter: (params) => UtilFns.fmtCurrency(params.value),
        valueGetter: params => {
          const invh = <InvoiceHeader>params.data;
          return invh?.isPrimaryInvoice ? invh.shipment?.freightAmt : null
        }
      },
      // { ...AgFns.createCellButtonProps('Shipping Label', this.shipLabelCell) },
      { headerName: 'Label', field: 'shipment.labelApiIdentifier'}
    ];
    
/*     if (this.user.isAccountingAdmin) {
      var colDef = { ...AgFns.createButtonProps('Print Invoice', this.printInvoice.bind(this), 
        { label: 'Print Invoice',  canDisplay: this.canPrintInvoice.bind(this) }), 
       minWidth: 170
      };
      colDefs.push(colDef);
    } */
    
    AgFns.initGrid(this.invhGridOptions, colDefs, null, true);

    AgFns.autoSizeAllColumns(this.invhGridOptions);
  }

  get title() {
    return `Current Shipment: ${this.currentShipment?.id ?? 'New'} `;
  }

  onShipBinRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    this.selectedShipBin = e.data as ShipBin;
    this.updateLocation(this.selectedShipBin.id);


    this.invoiceHeaders = this.selectedShipBin.invoiceHeaders;
    this.showHideVoucherCols();
        
    this.currentShipment = null;
  }

  showHideVoucherCols() {
    const todayMs = DateFns.startOfDay(new Date()).getTime();
    const canShow = (this.selectedShipBin.expectedShipDate.getTime() <= todayMs); 
    this.invhGridOptions?.columnApi?.setColumnsVisible(['addRemove', 'isPrimaryInvoice', 'shipmentId'], this.canShip() )
  }

  onInvRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    const invoiceHeader = e.data as InvoiceHeader;
    if (!invoiceHeader) {
      return;
    }

    // Cannot expand or remove from a shipment with a label
    if (invoiceHeader.shipment && invoiceHeader.shipment?.labelApiIdentifier == null) {
      this.currentShipment = invoiceHeader.shipment;
    }
  }

  onCellValueChanged(e: CellValueChangedEvent) {
    if (e.colDef.field === 'isPrimaryInvoice') {
      this.invhGridOptions.api?.redrawRows();
    }
  }

  canShip() {
    const todayMs = DateFns.startOfDay(new Date()).getTime();
    if (this.selectedShipBin == null) { return false; }
    const canShip = (this.selectedShipBin.expectedShipDate.getTime() <= todayMs); 
    return canShip;
  }

  canAddAllToCurrentShipment() {
    return this.invoiceHeaders.some(invh => this.canAddToShipment(invh));
  }

  async addAllToCurrentShipment() {
    await this.busyInvGrid( async () => {
      const invhs = this.invoiceHeaders.filter(invh => invh.shipmentId == null && this.currentShipment?.labelApiIdentifier == null);
      if (invhs.length == 0) {
        return;
      }
      if (this.currentShipment == null) {
        this.currentShipment = this.uow.createEntity(Shipment, {
          modTs: new Date(),
        });
      }
      invhs.forEach(invh => this.addToShipmentCore(invh));
      await this.dbSaveService.saveChanges();
      this.hackInvGridRefreshRows();
    });
  }

  async addToRemoveFromShipment(invh: InvoiceHeader) {
    if (this.canAddToShipment(invh)) {
      this.addToShipment(invh);
    } else if (this.canRemoveFromShipment(invh)) {
      this.removeFromShipment(invh);
    }
  }

  calcAddToRemoveFromShipmentLabel(invh: InvoiceHeader) {
    if (this.canAddToShipment(invh)) {
      return 'Add to shipment';
    } else if (this.canRemoveFromShipment(invh)) {
      return 'Remove from shipment';
    }
  }

  canDisplayAddToRemoveFromShipment(invh: InvoiceHeader) {
    if (this.canAddToShipment(invh)) {
      return true;
    } else if (this.canRemoveFromShipment(invh)) {
      return true;
    } else {
      return false;
    }
  }

  canAddToShipment(invoiceHeader: InvoiceHeader) {
    return invoiceHeader && invoiceHeader?.shipmentId == null && this.currentShipment?.labelApiIdentifier == null;
  }

  async addToShipment(invoiceHeader: InvoiceHeader) {
    await this.busyInvGrid(async () => {
      if (this.currentShipment == null) {
        this.currentShipment = this.uow.createEntity(Shipment, {
          modTs: new Date(),
        });
      }
      this.addToShipmentCore(invoiceHeader);
      await this.dbSaveService.saveChanges();
      this.hackInvGridRefreshRows();
    });
  }


  private addToShipmentCore(invoiceHeader: InvoiceHeader) {
    const shipment = this.currentShipment;
    // insure that any previous shipment info from shipstation is removed.
    shipment.shipmentApiIdentifier = null;
    shipment.freightAmt = null;
    shipment.modTs = new Date(); // for OC purposes.
    invoiceHeader.shipment = shipment;

    if (shipment.invoiceHeaders.length === 1) {
      invoiceHeader.isPrimaryInvoice = true;
    }
  }

  canRemoveFromShipment(invoiceHeader: InvoiceHeader) {
    return invoiceHeader?.shipment && invoiceHeader.shipment?.labelApiIdentifier == null;
  }

  async removeFromShipment(invoiceHeader: InvoiceHeader) {
    await this.busyInvGrid( async () => {
      const shipment = invoiceHeader.shipment
      invoiceHeader.isPrimaryInvoice = false;
      invoiceHeader.shipment = null;
      // insure that any previous shipment info from shipEngine is removed.
      shipment.shipmentApiIdentifier = null;
      shipment.freightAmt = null;
      
      // cleanup empty shipping boxes and shipments
      if (shipment.invoiceHeaders.length === 0) {
        shipment.shippingBoxes.slice().forEach(sb => {
          sb.entityAspect.setDeleted();
        });
        shipment.entityAspect.setDeleted();
        this.currentShipment = null;
      } else {
        shipment.modTs = new Date(); // for OC purposes
      }

      await this.dbSaveService.saveChanges();
      this.hackInvGridRefreshRows();
    });
  }

  setNewShipment() {
    this.currentShipment = null;
  }

  canCalcFreight(invoiceHeader: InvoiceHeader) {
    return invoiceHeader?.isPrimaryInvoice && invoiceHeader.invoiceStatusId == InvoiceStatusCode.Voucher;
  }

  async calcFreight(invoiceHeader: InvoiceHeader) {
    // deliberately not using busyInvGrid here because we don't want to set busy flag when a dialog box
    // is going to be opened inside. We just want to disable the grid.
    await AgFns.busyGrid( this.invhGridOptions, null, async () => {
      const ok = await this.invoiceShipmentService.askAndCalcFreight(this.matDialog, invoiceHeader);
      if (ok) {
        this.hackInvGridRefreshRows();
      }
    });
  }

  async printShipBinLabel(shipBin: ShipBin) {
    return await this.barcodeService.printShipBinBarcode(shipBin);
  }

  async printInvoicePackageLabel(invh: InvoiceHeader) {
    return await this.barcodeService.printInvoicePackageBarcode(invh);
  }
  
  invoiceHasLabel(invh: InvoiceHeader) {
    return invh?.shipment?.labelApiIdentifier != null;
  }

  canPrintShippingLabel(invh: InvoiceHeader) {
    return (invh.invoiceStatusId === InvoiceStatusCode.Voucher || 
      invh.invoiceStatusId === InvoiceStatusCode.VoucherLabeled)
      && invh.isPrimaryInvoice && invh.freightAmt != null
  }

  async printShippingLabel(invh: InvoiceHeader) {
    await this.busyInvGrid( async () => {
      const ok = await this.invoiceShipmentService.printShippingLabel(invh);
      if (ok) {
        this.setNewShipment(); // current shipment is no longer available for assignment.
        this.hackInvGridRefreshRows();
      }
    });
  }

  voidAction(invh: InvoiceHeader) {
    if (this.canVoidShippingLabel(invh)) {
      this.voidShippingLabel(invh)
    } else if (this.canRemoveVoidError(invh)) {
      this.removeVoidError(invh);
    }
  }

  canVoidAction(invh: InvoiceHeader) {
    return this.canVoidShippingLabel(invh) || this.canRemoveVoidError(invh);
  }

  calcVoidActionLabel(invh: InvoiceHeader) {
    if (this.canVoidShippingLabel(invh)) {
      return 'Void Label';
    } else if (this.canRemoveVoidError(invh)) {
      return 'Remove Void Error';
    }
  }

  canVoidShippingLabel(invoiceHeader: InvoiceHeader) {
    return invoiceHeader?.isPrimaryInvoice && invoiceHeader?.invoiceStatusId === InvoiceStatusCode.VoucherLabeled;
  }

  async voidShippingLabel(invoiceHeader: InvoiceHeader) {
    await this.busyInvGrid( async () => {
      const ok = await this.invoiceShipmentService.voidShippingLabel(invoiceHeader);
      // need to redraw even if it fails because a void error will be set
      this.hackInvGridRefreshRows();
    });
  }

  canRemoveVoidError(invoiceHeader: InvoiceHeader) {
    return invoiceHeader?.isPrimaryInvoice && invoiceHeader?.invoiceStatusId === InvoiceStatusCode.VoidError;
  }

  async removeVoidError(invoiceHeader: InvoiceHeader) {
    await this.busyInvGrid( async () => {
      const ok = await this.invoiceShipmentService.removeVoidError(invoiceHeader);
      if (ok) {
        this.hackInvGridRefreshRows();
      }
    });
  }

  canPrintInvoice(invoiceHeader: InvoiceHeader) {
    return (
      invoiceHeader?.invoiceStatusId === InvoiceStatusCode.VoucherLabeled || 
      invoiceHeader?.invoiceStatusId === InvoiceStatusCode.Printed || 
      invoiceHeader?.invoiceStatusId === InvoiceStatusCode.AwaitPost) && invoiceHeader.isPrimaryInvoice;
  }

  async printInvoice(invoiceHeader: InvoiceHeader) {
    if (!this.user.isAccountingAdmin) { 
      this.dialogService.showOkMessage('Unable to Continue', 'Account Admin access required to print invoices');
      return; 
    }
    // ensure all parts are loaded for calculation
    invoiceHeader = await this.dbQueryService.getInvoice(invoiceHeader.id);

    const oldStatusId = invoiceHeader.invoiceStatusId;

    await InvoicePrintDialogComponent.show(this.matDialog, {
      invoiceHeader: invoiceHeader,
      canPreview: true,
    });

    if (invoiceHeader.invoiceStatusId != oldStatusId) {
      this.hackInvGridRefreshRows();
      await this.updateFromContext();
    }
  }

  hackInvGridRefreshRows() {
    this.invoiceHeaders = this.invoiceHeaders.slice();
    this.invhGridOptions.api?.refreshCells();
  }

  async busyInvGrid<T>(op:  (() => Promise<T>)) {
    return await AgFns.busyGrid( this.invhGridOptions, this.busyService, op);
  }

  async editShipFrequencies() {
    await ShipFrequencyDialogComponent.show(this.matDialog, {  });
  }
}
