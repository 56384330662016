// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class GLSubmission extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  confirmationKey: string;
  crtnTs: Date;
  entityId: string;
  errorMessage: string;
  submissionTs: Date;
  tableName: string;
  wasPreviouslySubmitted: boolean;
}

