<app-uni-page  [parent]="this" *ngIf="isPageReady" 
  pageTitle="{{ isShowingEmptyProductTypes ? 'Empty ' : '' }}Product Types" 
  [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate"></app-uni-page>
<ng-template #bodyTemplate>
  <as-split unit="percent" direction="vertical">

    <as-split-area [size]="50">
      <app-ag-grid *ngIf="!isShowingEmptyProductTypes" [gridOptions]="itsGridOptions"></app-ag-grid>
      <app-ag-grid *ngIf="isShowingEmptyProductTypes" [gridOptions]="itsEmptyGridOptions" [rowData]="productTypes">
      </app-ag-grid>
    </as-split-area>

    <as-split-area [size]="50">
      <mat-tab-group (selectedTabChange)="onTabChanged($event)">
        <mat-tab label="Available Products">
          <app-ag-grid [gridOptions]="issGridOptions" [rowData]="products"></app-ag-grid>
          
        </mat-tab>
        <mat-tab label="Alternate Product Types">
          <app-ag-grid [gridOptions]="altItsGridOptions" [rowData]="altProductTypes"></app-ag-grid>
        </mat-tab>
      </mat-tab-group>
    </as-split-area>

  </as-split>
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <mat-button-toggle-group [(value)]="isShowingEmptyProductTypes" class="ml-12" aria-label="ProductType filters">
      <mat-button-toggle id="all-product-types" [value]="false">All Product Types</mat-button-toggle>
      <mat-button-toggle id="empty-product-types" [value]="true">Empty Product Types</mat-button-toggle>
    </mat-button-toggle-group>
    <button [disabled]="!this.user.isProductAdmin" mat-raised-button (click)="addNewProductType()">
      <span>Add New Product Type</span>
    </button>
  </div>
  <div>
    <button mat-raised-button (click)="goProductTypeTags()">
      <span>Product Type Tags</span>
    </button>
    <button mat-raised-button (click)="goFeatures()">
      <span>Features</span>
    </button>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>