import { GetRowIdParams, GridOptions, RowNode, RowSelectedEvent } from '@ag-grid-community/core';
import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { AccountFinderDialogComponent } from 'app/admin/account-finder-dialog.component';
import { ProductFinderDialogComponent } from 'app/inventory/product-finder-dialog.component';
import { ItemDetailSummary } from 'app/model/entities/-item-detail-summary';
import { Account, ItemBin, ItemDetail, ItemDetailAddon, ItemDetailStatus, Feature, ProductType, Product, Addon, ReturnRequestDetail } from 'app/model/entities/entity-model';
import { BarcodePrintStatusCode } from 'app/model/enums/barcode-print-status-code';
import { ItemDetailStatusCode } from 'app/model/enums/item-detail-status-code';
import { AddonFinderDialogComponent } from 'app/shared/addon-finder-dialog.component';
import { AgFns, ISortModel } from 'app/shared/ag-fns';
import { BarcodeService } from 'app/shared/barcode.service';

import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { NavFns } from 'app/shared/nav-fns';
import { LinkHelpTooltipComponent } from 'app/shared/tooltip.component';
import { EntityState, SaveResult } from 'breeze-client';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';


interface ItemDetailProxy {
  tempId: number;

  qty: number;
  account: Account;
  product: Product;
  itemBin: ItemBin;
  itemDetailAddons: ItemDetailAddonProxy[];
}

interface ItemDetailAddonProxy {
  tempId: number;
  itdProxy: ItemDetailProxy;

  additionalInfo: string;
  addon: Addon;
}

@Component({
  selector: 'add-inventory',
  templateUrl: './add-inventory.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class AddInventoryComponent extends DomainBaseComponent implements OnInit {
  @ViewChild('delButtonCell') delButtonCell: TemplateRef<any>; 
  @ViewChild('issButtonCell') issButtonCell: TemplateRef<any>;
  @ViewChild('accountButtonCell') accountButtonCell: TemplateRef<any>;
  @ViewChild('addModButtonCell') addModButtonCell: TemplateRef<any>;
  @ViewChild('delModButtonCell') delModButtonCell: TemplateRef<any>;
  @ViewChild('embButtonCell') embButtonCell: TemplateRef<any>;

  idsGridOptions: GridOptions;
  currentSummaryItem: ItemDetailSummary;
  currentSummaryRowNode: RowNode;

  newItdGridOptions: GridOptions;
  itdProxies: ItemDetailProxy[] = [];
  currentNewItd: ItemDetailProxy;
  nextTempId = 0;

  allItdStatuses: ItemDetailStatus[];
  selectedItdStatuses: ItemDetailStatus[];
  
  itemBins: ItemBin[];

  itemDetailStatusDefault: ItemDetailStatus;
  itemBinDefault: ItemBin;

  features: Feature[];

  constructor(protected domainService: DomainService, protected barcodeService: BarcodeService,
    protected route: ActivatedRoute, protected matDialog: MatDialog, 
    protected location: Location) {
    super(domainService);
    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
    
  }

  async updateFromContext() {
    this.idsGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onIdsGridReady,
      onRowSelected: this.onIdsRowSelected,
      onFilterChanged: this.onFilterChanged,
      getRowId: this.getIdsRowId,
      rowModelType: 'serverSide',
      onPaginationChanged: (params) => {
        if (params.newPage) {
          this.updateLocation();
        }
      }
    })
    // TODO: 'id' is not right on next line - set getRowId
  AgFns.captureGridRouteParams(this.idsGridOptions, this.route, 'id');
    
    this.newItdGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onNewItdGridReady,
      onRowSelected: this.onNewItdRowSelected,
      getRowId: this.getNewItdRowId,
      rowMultiSelectWithClick: true
    }, { detailProperty: 'itemDetailAddons' });

    
    this.features = await this.dbQueryService.getFeatures();
    this.allItdStatuses = this.dbQueryService.getAllCached(ItemDetailStatus);
    
    let statusIds: number[];
    if (this.idsGridOptions.context?.gridState?.fm == null) {
      statusIds = [ItemDetailStatusCode.InInventory];
      this.selectedItdStatuses = this.allItdStatuses.filter(its => statusIds.indexOf(its.id) !== -1);
    }
    
    this.itemBins = await this.dbQueryService.getItemBins();
    this.itemBins = _.sortBy(this.itemBins, ib => ib.name);

    this.itemBinDefault = this.itemBins.find(x => x.id == ItemBin.UnitecDefault);

    this.isPageReady = true;
  }

  async canDeactivate() {
    this.stopEditing();
    if (this.isBusy) return false;
    
    if (this.itdProxies.length > 0) {
      const ynResult = await this.dialogService.askYesNo('Changes have not been saved', 'Do you want to save before exiting?');
      if (ynResult.index == 0) {
        const wasSaved = await this.saveChanges(true);
        if (!wasSaved) return false;
      } else {
        // Not actually needed but for clarity.
        this.cancelChanges();
      }
    }

    this.uow.clearEntities(ItemDetail);
    this.uow.clearEntities(Product);
    this.uow.clearEntities(ProductType);
    
    return true;
  }

  async onIdsGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'product.productType.description', filter: 'agTextColumnFilter' },
      { headerName: 'SKU', field: 'product.productType.style', filter: 'agTextColumnFilter' },
      { headerName: 'Features', field: 'product.featureChoicesExtract', filter: 'agTextColumnFilter' },
      { headerName: 'Account', field: 'account.accountName', filter: 'agTextColumnFilter' },
      { ...AgFns.createSetFilterObjectProps('Status', 'itemDetailStatus.name', this.allItdStatuses) },
      { headerName: 'Qty', field: 'qty', },
      { headerName: 'Inventory', ...this.createInventoryCellClickedNavProps('productId', '/inventory') },
      // { headerName: 'Product Id', field: 'product.id', filter: 'agNumberColumnFilter'  },
    ];
    
    const sortModel = [
        { colId: 'product.productType.manufacturer.name', sort: 'asc' },
        { colId: 'product.productType.style', sort: 'asc' },
     ] as ISortModel; 
    
    // next line is needed by the buildDatasource method
    AgFns.initGrid(this.idsGridOptions, colDefs, sortModel);
    this.updateDatasource();
    AgFns.applyGridRouteParams(this.idsGridOptions)
  }

  createInventoryCellClickedNavProps(field: string, navPath: string)  {
    return {
      field: field,
      cellStyle: { color: 'blue', cursor: 'pointer' },
      onCellClicked: (event) => {
        const itds = event.data as ItemDetailSummary;
        // const id = event.value;
        const fm = AgFns.addFilterClause({}, 'productId', 'number', itds.productId );
        const urlSuffix = encodeURIComponent(JSON.stringify(fm));
        const params =  {
          queryParams: {
            fm: urlSuffix,
          }
        };
        NavFns.navigateWithClickEvent(event, this.router, navPath, params)
      },
      tooltipComponent: LinkHelpTooltipComponent,
      tooltipField: field
    };
  }

  getIdsRowId(params: GetRowIdParams) {
    const data = <ItemDetailSummary> params.data;
    const r = data?.productId.toString() + '-' + data?.accountId?.toString() + '-' + data?.itemDetailStatusId.toString();
    return r;
  }

  getNewItdRowId(params: GetRowIdParams) {
    return params.data?.tempId?.toString();
  }

  onNewItdGridReady(evt: any) {
    const colDefs = [
      { ...AgFns.createCellButtonProps('', this.delButtonCell), minWidth: 100,  },
      { ...AgFns.createCellButtonProps('', this.issButtonCell), minWidth: 100 },
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name',  },
      { headerName: 'Description', field: 'product.productType.description',  },
      { headerName: 'SKU', field: 'product.productType.style',  },
      { headerName: 'Features', field: 'product.featureChoicesExtract',  },
      { ...AgFns.createCellButtonProps('', this.accountButtonCell), minWidth: 100 },
      { headerName: 'Account', field: 'account.accountName',  },
      { headerName: 'Item Bin', field: 'itemBin', editable: true, ...AgFns.createDropdownEditorObjectProps(this.itemBins, 'name' ) }, 
      { headerName: 'Qty', field: 'qty', editable: true }, 
      { ...AgFns.createCellButtonProps('', this.addModButtonCell), minWidth: 100 },
      { headerName: 'Mods', field: 'itemDetailAddons.length', cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'TempId', field: 'tempId',  },
    ];
    const sortFields = [
      'account.accountName',
      'product.productType.manufacturer.name',
      'product.productType.description',
      'product.productType.style',
    ];
    const sortModel = sortFields.map(sf => {
      return { colId: sf, sort: 'asc' };
    }) as ISortModel;
    this.updateForMasterDetail(this.newItdGridOptions);
    AgFns.initGrid(this.newItdGridOptions, colDefs, sortModel);
  }

  updateForMasterDetail(parentGridOptions: GridOptions) {
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.getRowId = (params: GetRowIdParams) => { return params.data?.tempId.toString() };
    detailGridOptions.columnDefs = [
      { ...AgFns.createCellButtonProps('', this.delModButtonCell), width: 100 },
      { ...AgFns.createCellButtonProps('', this.embButtonCell)  },
      { headerName: 'Addon', field: 'addon.nameAndLocation' },
      { headerName: 'Addl. Info', field: 'additionalInfo', editable: true },
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      refreshStrategy: 'everything',
      detailGridOptions: detailGridOptions,
      getDetailRowData: params => {
        const itd = params.data as ItemDetailProxy;
        params.successCallback(itd.itemDetailAddons);
      },
    };
  }

  updateDatasource() {
    const gridApi = this.idsGridOptions.api;
    if (gridApi == null) {
      return;
    }
    const sortModel = [ { colId: 'productId', sort: 'asc' as const } ];

    const ds = AgFns.buildDatasource( () => this.dbQueryService.createItemDetailSummaryQuery(false), sortModel );
        
    gridApi.setServerSideDatasource(ds);
    AgFns.applyGridRouteParams(this.idsGridOptions);
    
  }
  
  onFilterChanged() {
    this.updateLocation();
  }

  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/add-inventory']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.idsGridOptions, key && key.toString());
    this.domainService.location.replaceState(url);
  }

  async onIdsRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    this.currentSummaryItem = e.node.data as ItemDetailSummary;
    this.currentSummaryRowNode = e.node as any;

    this.newItdGridOptions.api.deselectAll();
    this.currentNewItd = null;

    this.updateLocation();
  }

  async onNewItdRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    this.currentNewItd = e.node.data;
    this.idsGridOptions.api.deselectAll();
    this.currentSummaryItem = null;
  }
  
  addBlankItemDetail() {
    const itdStruct = <ItemDetailProxy> {};
    this.addItemDetailCore(itdStruct);
  }

  addCopyItemDetail() {
    this.stopEditing();
    const copyFrom = this.currentNewItd ?? this.currentSummaryItem;
    if (copyFrom == null) {
      return;
    } 
    const itdProxy = <ItemDetailProxy> {};
    itdProxy.product = copyFrom.product;
    itdProxy.account = copyFrom.account;
    if (this.isItdProxy(copyFrom)) {
      itdProxy.itemBin = copyFrom.itemBin;
      itdProxy.qty = copyFrom.qty;
      
      itdProxy.itemDetailAddons = copyFrom.itemDetailAddons.map(mod => {
        const newMod = { ...mod}
        newMod.itdProxy = itdProxy;
        newMod.tempId = this.nextTempId++;
        return newMod;
      });
    }
    this.addItemDetailCore(itdProxy);
  }

  isItdProxy(obj: any): obj is ItemDetailProxy {
    return obj.itemBin !== undefined 
  }

  private addItemDetailCore(itdProxy: ItemDetailProxy) {
    itdProxy.tempId = this.nextTempId++;
    itdProxy.itemBin = itdProxy.itemBin ?? this.itemBinDefault;
    itdProxy.qty = itdProxy.qty ?? 1;
    if (itdProxy.itemDetailAddons == null) {
      itdProxy.itemDetailAddons = [];
    }
    this.itdProxies.push(itdProxy);

    if (this.newItdGridOptions.api) {
      this.newItdGridOptions.api.setRowData(this.itdProxies);
      AgFns.autoSizeAllColumns(this.newItdGridOptions);
    }
    this.selectItemDetailRow(itdProxy.tempId.toString(), true);
  }

  selectItemDetailRow(itemDetailId: string, shouldSelect = true) {
    const rowNode = this.newItdGridOptions.api.getRowNode(itemDetailId);
    rowNode.setSelected(shouldSelect);
  }

  async selectProduct(itdProxy: ItemDetailProxy) {
    const params = itdProxy.product != null ? { productType: itdProxy.product.productType } : {};
    const iss = await ProductFinderDialogComponent.show(this.matDialog, params);
    if (iss == null) {
      return;
    }
    itdProxy.product = iss;
    this.newItdGridOptions.api.refreshCells();
  }

  async selectAccount(itdProxy: ItemDetailProxy) {
    const account = await AccountFinderDialogComponent.show(this.matDialog, { shouldShowReissueAccountsOnly: true });
    if (account == null) {
      return;
    }
    itdProxy.account = account;
    this.newItdGridOptions.api.refreshCells();
  }

  
  async selectAddon(itdAddonProxy: ItemDetailAddonProxy) {
    const accountId = itdAddonProxy.itdProxy.account?.id;
    if (accountId == null) {
      await this.dialogService.showOkMessage('No account specified','An account is needed in order to select an addon.');
      return;
    }
    const addons = await AddonFinderDialogComponent.show(this.matDialog, { accountId: accountId } );
    if (addons.length == 0) {
      return;
    }
    itdAddonProxy.addon = addons[0];
    this.forceRefreshSubgridHack();

    const rowNode = this.newItdGridOptions.api.getRowNode(itdAddonProxy.itdProxy.tempId.toString());
    rowNode.setExpanded(true);
  }

  async deleteItemDetail(itdProxy: ItemDetailProxy) {
    _.remove(this.itdProxies, itd => itd == itdProxy);
    this.newItdGridOptions.api.setRowData(this.itdProxies);
  }

  addItemDetailAddon(itdProxy: ItemDetailProxy) {
    if (itdProxy == null) { return; }
    const itmProxy = <ItemDetailAddonProxy> {};
    itmProxy.tempId = this.nextTempId++;
    itmProxy.itdProxy = itdProxy;
    itdProxy.itemDetailAddons.push(itmProxy);
        
    this.forceRefreshSubgridHack();

    const rowNode = this.newItdGridOptions.api.getRowNode(itdProxy.tempId.toString());
    rowNode.setExpanded(true);
  }

  async deleteItemDetailAddon(itmProxy: ItemDetailAddonProxy) {
    const parent = itmProxy.itdProxy;
    _.remove(parent.itemDetailAddons, x => x == itmProxy);

    this.forceRefreshSubgridHack();

    const rowNode = this.newItdGridOptions.api.getRowNode(parent.tempId.toString());
    rowNode.setExpanded(true);
  }

  // canSkipRequery is true if save is called from deactivate
  async saveChanges(canSkipRequery = false) {
    const gridApi = this.stopEditing();
    
    let totalQty = 0;
    for (let itdProxy of this.itdProxies) {
      
      if (itdProxy.product == null) {
        const rowNode = gridApi.getRowNode(itdProxy.tempId.toString());
        rowNode.setSelected(true);
        this.dialogService.showOkMessage(
          'Cannot save',
          `Product may not be null`
        );
        return false;
      }
      for ( let itmProxy of itdProxy.itemDetailAddons) {
        if (itmProxy.addon == null) {
          const rowNode = gridApi.getRowNode(itdProxy.tempId.toString());
          rowNode.setSelected(true);
          rowNode.setExpanded(true);
          this.dialogService.showOkMessage(
            'Cannot save',
            `Modification type may not be null`
          );
          return false;
        }
      }
      totalQty += itdProxy.qty;
    };
    const nextIds = await this.dbQueryService.getNextItemDetailIds(totalQty);
    let ix = 0;
    const now = new Date(Date.now());
    this.itdProxies.forEach((itdProxy) => {
      for (var i = 0; i < itdProxy.qty; i++) {
        const nextId = nextIds[ix++];
        const itdStruct = <ItemDetail> {};
        itdStruct.accountId = itdProxy.account?.id ;
        itdStruct.productId = itdProxy.product.id;
        itdStruct.itemBinId = itdProxy.itemBin.id;
        itdStruct.itemDetailStatusId = ItemDetailStatusCode.InInventory;
        itdStruct.crtnTs = now;
        itdStruct.id = nextId;
        const itemDetail = this.uow.createEntity(ItemDetail, itdStruct , EntityState.Added);
        itdProxy.itemDetailAddons.forEach(itdAddon => {
          const itmStruct = <ItemDetailAddon> {};
          itmStruct.itemDetailId = itemDetail.id;
          itmStruct.addonId = itdAddon.addon.id;
          itmStruct.additionalInfo = itdAddon.additionalInfo;
          itmStruct.modUserInit = this.user.initials;
          const itdMod = this.uow.createEntity(ItemDetailAddon, itmStruct, EntityState.Added);
          
        })
      }
    });
    const r = await this.dbSaveService.saveChanges();
    await this.askPrintBarcodes(r);

    if (!canSkipRequery) {
      this.dialogService.toast('Save complete - new inventory has been added');
      this.itdProxies = [];
      gridApi.redrawRows();
      this.updateDatasource();
    }
    return true;
  }

  async askPrintBarcodes(saveResult: SaveResult) {
    const itds = <ItemDetail[]> saveResult.entities.filter(e => e instanceof ItemDetail);
    if (itds.length == 0) { return; }
    const yn = await this.dialogService.askYesNo('Print Barcodes?', 'Do you want to print barcodes for the inventory just created?');
    if (yn.index == 0) {
      await this.barcodeService.printItemDetailBarcodes(itds);
      itds.forEach(itd => itd.barcodePrintStatusId = BarcodePrintStatusCode.Stock);
      await this.dbSaveService.saveChanges();
    }
  }

  stopEditing() {
    const gridApi = this.newItdGridOptions.api;
    gridApi.forEachDetailGridInfo(detailGridInfo => {
      detailGridInfo.api?.stopEditing();
    });
    gridApi.stopEditing();
  
    this.currentSummaryRowNode?.setSelected(true);
    
    return gridApi;
  }

  forceRefreshSubgridHack() {
    // This should NOT be necessary - but the only combination of poking the grid that will allow the subgrid data to display
    // is to call setRowData with an empty collection followed by the real data
    // Note that NEITHER of these calls should be required and certainly not both.
    // Note also that this is not needed to update the main grids - just subgrids when they change after initial draw.
    this.newItdGridOptions.api.setRowData([]);
    this.newItdGridOptions.api.setRowData(this.itdProxies);
    // commented out because they only do part of what the 2 lines above do
    // this.newItdGridOptions.api.refreshCells( { force: true });
    // this.newItdGridOptions.api.redrawRows();
  }

  cancelChanges() {
    const gridApi = this.stopEditing();
    this.itdProxies = [];
    gridApi.redrawRows();
  }


}
