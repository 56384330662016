import { Component, Input, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { DomainBaseComponent } from './domain-base.component';

@Component({
  selector: 'app-uni-page',
  templateUrl: './uni-page.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,

})
export class UniPageComponent implements OnInit {
  @Input() parent: DomainBaseComponent;
  @Input() pageIcon: string;
  @Input() pageTitle: string;
  @Input() bodyTemplate: TemplateRef<any>;
  @Input() footerTemplate: TemplateRef<any>;

  constructor() {
    
  }

  ngOnInit() {
    this.pageIcon = this.pageIcon ?? this.parent?.getPageIcon();
  
  }

}
