<app-uni-dialog [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div *ngIf="isPageReady">

    <div uni-flex-row>
      <mat-form-field uni-flex-item [matTooltip]="feature.name">
        <input matInput placeholder="Name" [(ngModel)]="feature.name"  />
      </mat-form-field>
    </div>

    <div uni-flex-row>
      <mat-form-field uni-flex-item [matTooltip]="feature.docName">
        <input matInput placeholder="Display Name" [(ngModel)]="feature.docName"  />
      </mat-form-field>
    </div>

    <div uni-flex-row>
      <mat-form-field uni-flex-item [matTooltip]="feature.mismatchAddonNameAndLocation">
        <input matInput placeholder="Mismatch Addon Name And Location" [(ngModel)]="feature.mismatchAddonNameAndLocation" />
      </mat-form-field>
      <mat-checkbox [(ngModel)]="feature.mismatchAddonIsCrossAccount" name="isCrossAccountAddon">Is Mismatch Addon Cross Account?
      </mat-checkbox>
      
    </div>

    <div uni-flex-row>
      <mat-form-field style="width: 200px;" [matTooltip]="feature.activeStatus?.name">
        <mat-select [(ngModel)]="feature.activeStatusId" placeholder="Status">
          <mat-option [value]="activeStatus.id" *ngFor="let activeStatus of activeStatuses">
            {{activeStatus.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>

</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
</ng-template>