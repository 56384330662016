import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { AgFns } from 'app/shared/ag-fns';
import { takeUntil } from 'rxjs/operators';
import { Addon, AddonDoc, AddonStation, } from '../model/entities/entity-model';
import { DomainBaseComponent } from '../shared/domain-base.component';
import { DomainService } from '../shared/domain.service';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { EditAddonDialogComponent } from './edit-addon-dialog.component';
import { AddonStationDialogParams } from './edit-generic-entity-dialog.component';


@Component({
  selector: 'app-addon-station',
  templateUrl: './addon-station.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class AddonStationComponent extends DomainBaseComponent implements OnInit {

  aosGridOptions: GridOptions;
  selectedAddonStation: AddonStation;
  addonStations: AddonStation[];


  constructor(protected domainService: DomainService, protected route: ActivatedRoute, private matDialog: MatDialog) {
    super(domainService);

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {

    this.aosGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onAosGridReady,
      onRowSelected: AgFns.handleRowSelected( (e: AddonStation) => this.selectedAddonStation = e )
    });
    AgFns.captureGridRouteParams(this.aosGridOptions, this.route, 'id');

    this.addonStations = await this.dbQueryService.getAll(AddonStation);

    this.isPageReady = true;
  }

  async canDeactivate() {
    if (this.isBusy) return false;
    this.uow.clearEntities(AddonStation);
    return true;
  }
  
  onAosGridReady(evt: any) {
    const colDefs = [
      this.productAdminAllow({ ...AgFns.createButtonProps('', this.addonStationEdit.bind(this), { label: 'Edit' }), maxWidth: 60, minWidth: 60 }),
      { headerName: 'Name', field: 'name', filter: 'agTextColumnFilter' },
      { headerName: 'Status', field: 'activeStatus.name',  },
      { headerName: 'Id', field: 'id', filter: 'agNumberColumnFilter' },
    ];
    // sorted on server.
    AgFns.initGrid(this.aosGridOptions, colDefs, null);

  }

  productAdminAllow(x: any) {
    return this.user.isProductAdmin ? x : {};
  }

  
  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/addon-stations']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.aosGridOptions, key && key.toString());
    this.domainService.location.replaceState(url);
  }

  // -----------------------------------------------------


  
  async addonStationEdit(addonStation?: AddonStation) {
    const r = await this.dialogService.showGenericDialog({
      ...AddonStationDialogParams, target: addonStation
     } );
    if (r) {
      if (addonStation == null) {
        this.addonStations = await this.dbQueryService.getAll(AddonStation);
      }
      AgFns.refreshGrid(this.aosGridOptions, this.addonStations);
    }
  }

  

  


}
