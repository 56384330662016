<app-uni-page [parent]="this" pageTitle="Add-On Bin Processor" [bodyTemplate]="bodyTemplate"
  [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>

  <div *ngIf="isPageReady && addonBin" class="content-card" style="height: 50px">
    <div class="ml-12 mt-12 mr-12">
      <div uni-flex-row>
        <mat-form-field uni-flex-item>
          <input matInput placeholder="Add-On Bin Id" [ngModel]="addonBin.id" readonly />
        </mat-form-field>

        <mat-form-field uni-flex-item [matTooltip]="addonBin.account?.accountName">
          <input matInput placeholder="Account" [ngModel]="addonBin.account?.accountName" readonly />
        </mat-form-field>

        <mat-form-field uni-flex-item>
          <input matInput placeholder="Bin Status" [ngModel]="addonBin.addonBinStatus?.name" readonly />
        </mat-form-field>
      </div>

      <div uni-flex-row>
        <mat-form-field uni-flex-item [matTooltip]="addonBin.currentAddonStation?.name">
          <input matInput placeholder="Current Station" [ngModel]="addonBin.currentAddonStation?.name" readonly />
        </mat-form-field>
        <mat-form-field uni-flex-item>
          <input matInput placeholder="Current Station Status" [ngModel]="addonBin.currentAddonBinStationStatus?.name"
            readonly />
        </mat-form-field>
        <mat-form-field uni-flex-item [matTooltip]="addonBin.pendingAddonStation?.name">
          <input matInput placeholder="Pending Station" [ngModel]="addonBin.pendingAddonStation?.name" readonly />
        </mat-form-field>
      </div>

      <div uni-flex-row>
        <mat-form-field uni-flex-item>
          <input matInput placeholder="Priority Order" [ngModel]="addonBin.priorityOrder" readonly />
        </mat-form-field>
        <mat-form-field uni-flex-item [matTooltip]="addonBin.aggregateGroupingKey">
          <input matInput placeholder="Grouping Key" [ngModel]="addonBin.aggregateGroupingKey" readonly />
        </mat-form-field>
        <mat-form-field uni-flex-item>
          <input matInput placeholder="Job Order Id (if Job Order specific bin)" [ngModel]="addonBin.joHeaderId"
            readonly />
        </mat-form-field>
      </div>
    </div>
  </div>


  <div uni-flex-row class="my-4">
    <div>Item Details requiring Addons</div>
    <mat-checkbox [disabled]="isBusy" class="ml-8" [(ngModel)]="shouldShowAllItemDetailAddons" [ngModelOptions]="{standalone: true}"
      (ngModelChange)="refreshGrid()">
      Show all Add-On Stations
    </mat-checkbox>
  </div>


  <div *ngIf="isPageReady" class="content-card" style="flex-grow: 4; min-height: 60px">
    <app-ag-grid [gridOptions]="detailGridOptions" [rowData]="itemDetailAddons"></app-ag-grid>
  </div>


  <div *ngIf="isPageReady && canScanIntoStation()" class="content-card theme-default" style="max-height: 100px">
    <div class="h3 ml-12">Scan Item Detail into '{{ this.addonBin?.pendingAddonStation?.name }}' station </div>
    <div class="ml-12" style="display: flex; flex-direction: row;">
      <mat-form-field class="mt-8" style="width: 150px;">
        <input [disabled]="isBusy" matInput placeholder="Item Detail Id" [(ngModel)]="scannedItemDetailId" name="scannedItemDetailId"
          autocomplete="off" maxlength="12" size="12" (input)="onInputFilled($event, scanItemDetailId)">
      </mat-form-field>
      <div *ngIf="scanErrorMessage.length" class="content-card" style="flex-grow: 1">
        <h3 class="ml-12">{{ scanErrorMessage }}</h3>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <mat-spinner class="mt-12 ml-12" diameter="30" *ngIf="isBusy; else notBusy"></mat-spinner>
  <ng-template #notBusy>
    <div *ngIf="isPageReady" class="left-buttons">
      <button [disabled]="isBusy" *ngIf="canMarkStationInProcess()" mat-raised-button
        (click)="markStation(AddonBinStationStatusCode.InProcess)">
        <span>Mark {{ addonBin.currentAddonStation.name }} station in process</span>
      </button>
      <button [disabled]="isBusy" *ngIf="canMarkStationComplete()" mat-raised-button
        (click)="markStation(AddonBinStationStatusCode.Closed)">
        <span>Mark {{ addonBin.currentAddonStation.name }} station complete</span>
      </button>
      <button [disabled]="isBusy" *ngIf="canMarkStationOnHold()" mat-raised-button (click)="markStation(AddonBinStationStatusCode.OnHold)">
        <span>Mark {{ addonBin.currentAddonStation.name }} station on hold</span>
      </button>
    </div>
    <div>
      <button [disabled]="isBusy" mat-raised-button (click)="scanAll()">
        <span>Scan All</span>
      </button>
      <button [disabled]="isBusy" mat-raised-button (click)="goBack()">
        <span>Go Back</span>
      </button>
    </div>
  </ng-template>
</ng-template>