<app-uni-page [parent]="this" pageTitle="Job Order Detail - Pull Apply Item Detail" [bodyTemplate]="bodyTemplate"
  [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>

  <div *ngIf="isPageReady" class="content-card" style="flex-grow: 1; min-height: 60px">
    <app-ag-grid [gridOptions]="jodGridOptions" [rowData]="joDetails" [hasFooter]="false"></app-ag-grid>
  </div>
  <div class="h3 ml-12 mb-4 mt-0">Matching Inventory</div>
  <div *ngIf="isPageReady" class="content-card" style="flex-grow: 3; min-height: 60px">
    <app-ag-grid [gridOptions]="itdMatchGridOptions" [rowData]="matchingItemDetails"></app-ag-grid>
    <ng-template #selectItemDetailCell let-row>
      <button *ngIf="canCheckItemDetail(row)" (click)="selectItemDetail(row)">Mark as scanned</button>
    </ng-template>
  </div>
  <div class="h3 ml-12 mb-4 mt-0">Find/Scan Matching Inventory</div>
  <div class="content-card theme-default" un-flex-col style="flex-grow: 1; max-height: 250px">

    <div uni-flex-row  class="ml-12 mt-4">
      <mat-form-field matTooltip="Scan/Edit Item Detail Id" class="mt-8" style="width: 150px;">
        <input matInput placeholder="Item Detail Id" [(ngModel)]="itemDetailId" name="itemDetailId" autocomplete="off"
          maxlength="12" size="12" (input)="onInputFilled($event, processItemDetailId)">
      </mat-form-field>
      <div uni-flex-1  class="mr-8">
        <app-ag-grid *ngIf="itemDetails != null" [gridOptions]="itdGridOptions" [rowData]="itemDetails" 
          [hasFooter]=false></app-ag-grid>
      </div>
    </div>
    <!-- // 001104122586 -->

    <div class="ml-12" [hidden]="itemDetails==null || errorMessage.length">
      <span *ngIf="isStyleSizeMismatch">
        <h3 *ngIf="alternativeAlreadyExists" class="bold">This alternative is already registered</h3>
        <h3 *ngIf="!alternativeAlreadyExists" class="bold">The selected item does not exactly match the style and
          size of the specified job order detail item.</h3>
        <div uni-flex-row >
          <!-- No longer needed -->
          <!-- <mat-checkbox [(ngModel)]="isSpecialHandlingRequired">This item requires alterations/special handling
            </mat-checkbox> -->
          <mat-checkbox *ngIf="!alternativeAlreadyExists" [(ngModel)]="isValidAlternative">Add this style as an
            alternative</mat-checkbox>
        </div>
      </span>
    </div>

    <div  *ngIf="errorMessage.length" class="content-card" style="flex-grow: 1">
      <h3 class="ml-12">{{ errorMessage }}</h3>
    </div>
  </div>

</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <button #confirmButton mat-raised-button [disabled]="itemDetails==null || errorMessage.length > 0"
      (click)="confirmSelection()">Confirm selection
    </button>
    <mat-checkbox  class="ml-8" [(ngModel)]="shouldPrintBarcodes" name="shouldPrintBarcodes">Automatically Print Barcodes</mat-checkbox>

  </div>
  <div>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>