import { GridOptions } from '@ag-grid-community/core';
import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Config } from 'app/model/entities/config';
import { AgFns } from 'app/shared/ag-fns';
import { AuthService, AuthUser } from 'app/shared/auth.service';
import { BusyService } from 'app/shared/busy.service';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { UnitOfWork } from 'app/shared/unit-of-work';


@Component({
  templateUrl: './app-config-dialog.component.html',
  //  encapsulation: ViewEncapsulation.None
})
export class AppConfigDialogComponent {
  configGridOptions: GridOptions;
  configs: Config[];
  isPageReady = false;
  user: AuthUser;

  static async show(matDialog: MatDialog, data: any) {
    
    return await matDialog
      .open(AppConfigDialogComponent, {
        disableClose: true,
        height: '600px',
        width: '800px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) private data: AppConfigDialogComponent,
    private dialogRef: MatDialogRef<AppConfigDialogComponent>, private dialogService: DialogService,
    public dbSaveService: DbSaveService, private dbQueryService: DbQueryService,
    public uow: UnitOfWork, private authService: AuthService, 
    public busyService: BusyService) {
    this.prepare();  
  }

  async prepare() {
    if (this.uow.hasChanges()) {
      this.dialogService.showOkMessage('Unable to open', 'Please save or cancel before trying this.');
      this.dialogRef.close(null);
      return;
    };
    this.configGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onConfigGridReady,
      getRowId: (p) => (p.data.groupName ?? '') + '|' + (p.data.fieldName ?? ''),
      onCellValueChanged: this.cellValueChanged,
    });
    
    this.user = this.authService.getUser();
    this.configs = await this.dbQueryService.getConfigs();
    this.configs = this.configs.filter(c => c.shouldDisplay);
    this.isPageReady = true;
  }

  onConfigGridReady() {
    const colDefs = [
      // { headerName: 'Group', field: 'groupName', maxWidth: 120 },
      // { headerName: 'Name', field: 'fieldName', width: 120 },
      { headerName: 'Description', field: 'fieldDescription', width: 200 },
      { headerName: 'Value', field: 'value', width: 200, editable: !!this.user?.isUserAdmin, valueParser: (p) => this.parseAndFmt(p) },
    ];
    const sortModel = [{ colId: 'fieldDescription', sort: 'asc' as const }];
    AgFns.initGrid(this.configGridOptions, colDefs, sortModel, true);
  }

  cellValueChanged(event) {
    // Nothing needed here yet.
    // if (event != null) {
    //   if (event.oldValue != null || event.newValue != null) {
        
    //   }
    // }
    // const config = event?.node?.data as Config;
  }

  parseAndFmt(event) {
    const config = event?.node?.data as Config;
    if (config == null) { return; }
    if (config.fieldType == 'number') {
      const result =  this.parseFloatDefault(event.newValue, 0).toString();
      return result;
    } else {
      return event.newValue;
    }
  }

  parseFloatDefault(value: any, def = 0.0) {
    const v = parseFloat(value);
    return isNaN(v) ? def : v;
  }

  async save() {
    await this.dbSaveService.saveChanges();
    this.dbQueryService.updateAppConfig(this.configs);
    this.dialogRef.close(null);
  }
  
  async undo() {
    this.configGridOptions.api.stopEditing();
    this.configs.slice().forEach(x => x.entityAspect.rejectChanges());
    this.configs = await this.dbQueryService.getConfigs();
    // needed because of valueParser above
    this.configGridOptions.api.refreshCells( { force: true});
  }

  async close() {
    this.configGridOptions.api.stopEditing();
    if (this.uow.hasChanges()) {
      const ynResult = await this.dialogService.askYesNo('Changes have not been saved', 'Do you want to save before exiting?');
      if (ynResult.index == 0) {
        await this.dbSaveService.saveChanges();
      } else {
        this.dbSaveService.rejectChanges();
      }
    }

    this.dialogRef.close(null);
  }

  
}
