<app-uni-page [parent]="this" pageTitle="Addon Work in Progress" [bodyTemplate]="bodyTemplate"
  [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <as-split unit="percent" direction="vertical">
    <as-split-area [size]="50">
      <app-ag-grid [gridOptions]="itdAddonGridOptions"></app-ag-grid>

      <ng-template #wipCell let-row>
        <button  (click)="goWipToInvoice(row)">WIP</button>
      </ng-template>

      <ng-template #viewDocCell let-row>
        <button *ngIf="hasAddonDocs(row)" (click)="viewAddonDocs(row)">View Documents</button>
      </ng-template>

    </as-split-area>
  </as-split>
</ng-template>

<ng-template #footerTemplate>
  <mat-spinner class="mt-12 ml-12" diameter="30" *ngIf="busyService.isBusy | async; else notBusy"></mat-spinner>
  <ng-template #notBusy>
    <div class="left-buttons">
    </div>
    <div>
      <button mat-raised-button (click)="goBack()">
        <span>Go Back</span>
      </button>
    </div>
  </ng-template>
</ng-template>