<app-uni-page  [parent]="this" *ngIf="isPageReady" pageTitle="Issues" [bodyTemplate]="bodyTemplate"
  [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <div class="content-card" >
    <app-ag-grid [gridOptions]="eiGridOptions" [rowData]="entityIssues"></app-ag-grid>
    <ng-template #eiButtonsCell let-row>
      <button (click)="removeIssue(row)">Remove Issue</button>
    </ng-template>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
  </div>
  <div>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>