import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Email } from 'app/model/entities/email';
import { BusyService } from 'app/shared/busy.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EmailService {
  constructor(
    private uow: UnitOfWork,
    private busyService: BusyService,
    private http: HttpClient
  ) { }

  /** Send a test email to the given address */
  async sendTestEmail(emailAddress: string) {
    const url = environment.apiRoot + 'api/mail/sendEmail?toAddress=' + emailAddress;
    const eventResponse = await firstValueFrom(this.http.get<any>(url));
    return eventResponse.Event as string;
  }

  /** Send email with PO attached, and return the email status */
  sendPoEmail(poHeaderId: number, emailAddress: string): Promise<string> {
    const url = environment.apiRoot + 'api/mail/sendpo?toAddress=' + emailAddress + '&poHeaderId=' + poHeaderId;
    return this.sendEmail(url);
  }

  /** Get the status of the email and related PO; update the entities in the cache */
  getPoEmailStatus(poHeaderId: number): Promise<Email> {
    const url = environment.apiRoot + 'api/mail/getPoEmailStatus?poHeaderId=' + poHeaderId;
    return this.getEmailStatus(url);
  }

  /** Send email with Invoice attached, and return the email status */
  sendInvoiceEmail(invoiceHeaderId: string, emailAddress: string): Promise<string> {
    const url = environment.apiRoot + 'api/mail/sendInvoice?toAddress=' + emailAddress + '&invoiceId=' + invoiceHeaderId;
    return this.sendEmail(url);
  }

  /** Send email with Manifest attached, and return the email status */
  sendManifestEmail(manifestGroupId: number, emailAddress: string): Promise<string> {
    const url = environment.apiRoot + 'api/mail/sendManifest?toAddress=' + emailAddress + '&manifestGroupId=' + manifestGroupId;
    return this.sendEmail(url);
  }

  /** Get the status of the email and related Invoice; update the entities in the cache */
  getManifestEmailStatus(manifestGroupId: number): Promise<Email> {
    const url = environment.apiRoot + 'api/mail/getManifestEmailStatus?manifestGroupId=' + manifestGroupId;
    return this.getEmailStatus(url);
  }


  /** Get the status of the email and related Invoice; update the entities in the cache */
  getInvoiceEmailStatus(invoiceHeaderId: string): Promise<Email> {
    const url = environment.apiRoot + 'api/mail/getInvoiceEmailStatus?invoiceId=' + invoiceHeaderId;
    return this.getEmailStatus(url);
  }

  /** Send email using the given URL to the mail controller */
  private async sendEmail(url: string): Promise<string> {
    return await this.busyService.busy(async () => {
      try {
        // send the email and get back the Email object with "accepted" status
        const email = await this.http.get<Email>(url).toPromise();
        return email.status || (email as any).Status;
      } catch (err) {
        return (err.error && err.error.Message) || err.message;
      }
    });
  }

  // Old code
  // private sendEmail(url: string): Promise<string> {
  //   const promise = this.busyService.busy(this.http.get<Email>(url).toPromise().then(email => {
  //     // send the email and get back the Email object with "accepted" status
  //     return email.status || (email as any).Status;
  //   }).catch(err => {
  //     return (err.error && err.error.Message) || err.message;
  //   }));
  //   return promise;
  // }

  /** Get the status of the email and related entities; update the entities in the cache */
  private async getEmailStatus(url: string): Promise<Email> {
    const query = this.uow.createQuery(Email, url);
    return await this.busyService.busy( async () => {
      try {
        const emails = await query.execute();
        return emails.length && emails[0];
      } catch(err) {
        console.log('Error getting email status from ' + url, err);
        return null;
      }
    });
  }

  // Old code - BUSY not exited.
  // private getEmailStatus(url: string): Promise<Email> {
  //   const query = this.uow.createQuery(Email, url);
  //   return this.busyService.busy(
  //     query.execute().then(emails => {
  //       return emails.length && emails[0];
  //     }).catch(err => {
  //       console.log('Error getting email status from ' + url, err);
  //       return null;
  //     }));
  // }

  // *Delay/Repeat is now handled on the server, so that it will keep checking/updating without client involvement
  // getPoEmailStatusDelayed(poHeaderId: number, timeout: number): Promise<Email> {
  //   return new Promise(resolve => setTimeout(() => this.getPoEmailStatus(poHeaderId).then(m => resolve(m)), timeout));
  // }
  // getPoEmailStatusRepeated(poHeaderId: number, timeout: number, limit: number): Promise<Email> {
  //   return new Promise(resolve => {
  //     let count = 0;
  //     const tid = setInterval(() => this.getPoEmailStatus(poHeaderId).then(m => {
  //       if (m || (++count >= limit)) {
  //         clearInterval(tid);
  //         resolve(m);
  //       }
  //     }), timeout);
  //   });
  // }

}
