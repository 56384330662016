<app-uni-page  [parent]="this" pageTitle="Manufacturers"
  [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
    <app-ag-grid [gridOptions]="manufGridOptions"  ></app-ag-grid>
</ng-template>

<ng-template #footerTemplate >
  <div class="left-buttons">
    <button mat-raised-button [disabled]="!this.user.isProductAdmin" (click)="addManufacturer()">
      <span>Add New Manufacturer</span>
    </button>
  </div>
  <div>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>
