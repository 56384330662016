<app-uni-page  [parent]="this" pageTitle="Back Orders for Account: {{ getTitle() }}"
  [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <div *ngIf="!isPageReady" class="content-card" style="flex-grow: 1">
    <mat-spinner class="mt-12 ml-12" diameter="50"></mat-spinner>
  </div>
  <app-ag-grid *ngIf="isPageReady" [gridOptions]="podGridOptions" [rowData]="podItems" ></app-ag-grid>
  
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <button mat-raised-button (click)="showHideIdColumns()">
      <span>Show/Hide Id Columns</span>
    </button>
  </div>
  <div>

    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>