<app-uni-page  [parent]="this" pageTitle="Receive Purchase Order" [bodyTemplate]="bodyTemplate"
  [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <div *ngIf="!isPageReady" class="content-card" style="flex-grow: 1">
    <mat-spinner class="mt-12 ml-12" diameter="50"></mat-spinner>
  </div>
  <div *ngIf="isPageReady" class="content-card" style="flex-grow: 3">
    <app-ag-grid style="height: 64px;" [gridOptions]="pohGridOptions" [rowData]="poHeaders" [hasFooter]="false">
    </app-ag-grid>

    <div class="h3 ml-12 mt-8 mb-4">Details - Qty: {{ pohRemainingQty }} remaining</div>
    <app-ag-grid style="height: calc(100% - 63px);" [gridOptions]="podGridOptions" [rowData]="poDetails" >
    </app-ag-grid>

  </div>
  <div *ngIf="isPageReady && shouldShowPodSummaries" class="content-card" style="flex-grow: 1">
    <app-ag-grid  [gridOptions]="podsGridOptions"  [rowData]="podSummaries" [hasFooter]="false">
    </app-ag-grid>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <button [disabled]="!canApplyAny()" mat-raised-button (click)="applyAll()">
      <span>Apply All</span>
    </button>
    <button [disabled]="!canPrintBarcodes()" mat-raised-button (click)="printBarcodes()">
      <span>Print Selected Barcodes</span>
    </button>
    <mat-checkbox style="width: 250px;" placeholder="Group By Transaction" [(ngModel)]="shouldGroupByTransaction"
      (ngModelChange)="considerGroupByTrx()">
      Group by Transaction
    </mat-checkbox>
    <mat-checkbox style="width: 250px;" placeholder="Show Pod Received Summary" [(ngModel)]="shouldShowPodSummaries">
      Show Po Detail Receipt Summaries
    </mat-checkbox>  
  </div>
  <div>
    <button mat-raised-button (click)="goAddonBins()">
      <span>Go to Add-On Bins</span>
    </button>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>