import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { GridOptions } from '@ag-grid-community/core';
import { ItemDetail, JoDetail, JoHeader, JoPullBatch } from 'app/model/entities/entity-model';
import { AgFns } from 'app/shared/ag-fns';
import { DbQueryService } from 'app/shared/db-query.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { DialogService } from 'app/shared/dialog.service';
import { NavFns } from 'app/shared/nav-fns';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { ItemDetailStatusCode } from 'app/model/enums/item-detail-status-code';
import { DbSaveService } from 'app/shared/db-save.service';
import { DomainService } from 'app/shared/domain.service';
import { AuthUser } from 'app/shared/auth.service';



@Component({
  templateUrl: './jo-ship-prep-scan-dialog.component.html',
  //  encapsulation: ViewEncapsulation.None
})
export class JoShipPrepScanDialog {
  authUser: AuthUser;
  
  johGridOptions: GridOptions;
  joHeaders: JoHeader[];  // will only contain a single joHeader

  itdGridOptions: GridOptions;
  scannedItemDetails: ItemDetail[] = [];

  scannedItemDetailId: string;
  scanErrorMessage: string;
  
  isPageReady = false;

  static async show(matDialog: MatDialog  ) {
    return await matDialog
      .open(JoShipPrepScanDialog, {
        disableClose: true,
        height: '800px',
        width: '1200px',
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<JoShipPrepScanDialog>,
     private router: Router, private dbQueryService: DbQueryService, private dbSaveService: DbSaveService, 
     private domainService: DomainService, private dialogService: DialogService, private uow: UnitOfWork) {

    
    this.prepare();
  }

  async prepare() {
    this.authUser = this.domainService.authService.getUser();

    this.itdGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onItdGridReady,
    },);

    this.isPageReady = true;
  }

  

  onItdGridReady() {
    const colDefs = [
      { headerName: 'Item Detail Id', field: 'id', cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'product.productType.description' },
      { headerName: 'SKU', field: 'product.productType.style' },
      { headerName: 'Features', field: 'product.featureChoicesExtract' },
      { headerName: 'Jo Detail Id', field: 'joDetail.id' },
    ]
    
    AgFns.initGrid(this.itdGridOptions, colDefs);
    AgFns.sizeColumnsToFit(this.itdGridOptions);
  }

  onInputFilled(event: { target: any }, fn: (value: any) => void) {
    const input = event.target;
    const length = input.value.length;
    const maxLength = input.attributes.maxlength.value;

    if (length >= maxLength) {
      // bind is needed to associate the fn returned with 'this' component.
      fn.bind(this)(input.value);
    } else {
      this.scanErrorMessage = '';
    }
  }

  async scanItemDetailId(itemDetailId: string) {
    const itd = await this.dbQueryService.getItemDetailByIdForScan(itemDetailId);
    if (itd?.itemDetailStatusId == ItemDetailStatusCode.InProcessPendingShipPrep) {
      itd.itemDetailStatusId = ItemDetailStatusCode.InProcessShipPrep;
      this.scannedItemDetails.push(itd);
      this.dialogService.toast('Found and marked.');
      this.refreshGrid(); 
      await this.dbSaveService.saveChanges();
    } else if (itd) {
      this.scanErrorMessage = `Invalid - Item detail with id: ${itemDetailId} is currently in the '${itd.itemDetailStatus.name}' state.`;
    } else {
      this.scanErrorMessage = `Invalid - Item detail with id: ${itemDetailId} cannot be found. `;
    }
    this.scannedItemDetailId = '';
    // AgFns.refreshGrid(this.detailGridOptions, this.itemDetailAddons);
  }

  
  async refreshGrid() {
    AgFns.refreshGrid(this.itdGridOptions, this.scannedItemDetails);
  }
  

  ok() {
    this.dialogRef.close(this.scannedItemDetails);
  }
  
  // async cancel() {
  //   if (this.scannedItemDetails.length > 0) {
  //     const ynResult = await this.dialogService.askYesNo("Cancel", "Any scanned items will be forgotten if you do this. Ok?");
  //     if (ynResult.index == 1) {
  //       return;
  //     }
  //   }
  //   this.dialogRef.close(null);
  // }

}
