import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { RowSelectedEvent, GridOptions, IServerSideDatasource } from '@ag-grid-community/core';
import { AgFns, GridState, ISortModel } from 'app/shared/ag-fns';
import { takeUntil } from 'rxjs/operators';
import { DomainBaseComponent } from '../shared/domain-base.component';
import { DomainService } from '../shared/domain.service';
import { InvoiceHeader, JoHeader, Manifest, JoStatus, ManifestGroupInvoice, ManifestGroup } from './../model/entities/entity-model';
import { EventScheduleDialogComponent } from './event-schedule-dialog.component';
import { UtilFns } from 'app/shared/util-fns';
import * as _ from 'lodash';
import { NavFns } from 'app/shared/nav-fns';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { IDatasource  } from '@ag-grid-community/core';
import { DateFns } from 'app/shared/date-fns';

@Component({
  selector: 'app-manifests',
  templateUrl: './manifest.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ManifestComponent extends DomainBaseComponent implements OnInit {
  @ViewChild('invoiceScheduleCell') invoiceScheduleCell: TemplateRef<any>;
  @ViewChild('sendManifestCell') sendManifestCell: TemplateRef<any>;
  @ViewChild('viewManifestCell') viewManifestCell: TemplateRef<any>;

  manifestGridOptions: GridOptions;
  manifestGridDatasource: IServerSideDatasource;
  currentManifest: Manifest;

  joHeaders: JoHeader[];
  johGridOptions: GridOptions;

  manifestGroups: ManifestGroup[];
  manifestGroupGridOptions: GridOptions;

  shouldReselect: boolean;

  constructor(protected domainService: DomainService, protected route: ActivatedRoute, private matDialog: MatDialog) {
    super(domainService);

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    

    // this.gridState = AgFns.createGridState(this.route.snapshot.queryParams, 'id');

    this.manifestGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onManifestGridReady,
      onRowSelected: this.onRowSelected,
      onModelUpdated: this.onModelUpdated,
      rowModelType: 'serverSide',
      onPaginationChanged: (params) => {
        if (params.newPage) {
          this.currentManifest = null;
          this.updateLocation();
        }
      },
    });
    AgFns.captureGridRouteParams(this.manifestGridOptions, this.route, 'id');
    
    this.manifestGroupGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onManifestGroupGridReady,
    }, { detailProperty: 'manifestGroupInvoices' });

    this.johGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJohGridReady,
    });

    this.isPageReady = true;
  }

  canDeactivate() {
    if (this.isBusy) return false;
    this.uow.clearEntities(Manifest);
    this.uow.clearEntities(ManifestGroup);
    this.uow.clearEntities(ManifestGroupInvoice);
    this.uow.clearEntities(InvoiceHeader);
    this.uow.clearEntities(JoHeader);
    return true;
  }


  // This method is called AFTER ngAfterViewInit - this is important
  // because the ColDef's here reference TemplateRef's above that will not be resolved until here.
  onManifestGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Manifest', field: 'id', filter: 'agNumberColumnFilter' },
      { headerName: 'Name', field: 'manifestName', filter: 'agTextColumnFilter' },
      { headerName: 'Account', valueGetter: this.getAccount, sortable: false },
      { headerName: 'Includes Invoices', field: 'shouldIncludeInvoices', cellRenderer: AgCheckboxCellComponent },
      { headerName: 'Proximity Manifest id', field: 'proximityManifestId', filter: 'agTextColumnFilter' },
      { ...AgFns.createCellButtonProps('Invoice Schedule', this.invoiceScheduleCell, 'eventScheduleId') },
      { headerName: 'Next Process Date', field: 'nextProcessDate', filter: 'agDateColumnFilter' },
      { ...AgFns.createCellButtonProps('Send Manifest', this.sendManifestCell, 'sendManifest') },
    ];

    // save whenever "includes invoices" is changed.
    this.manifestGridOptions.onCellValueChanged = (event => this.uow.commit());

    AgFns.initGrid(this.manifestGridOptions, colDefs );
    // This needs to be done last in order to avoid excess query calls
    this.updateDatasource();
    AgFns.applyGridRouteParams(this.manifestGridOptions);
  }

  getAccount(params: any) {
    const manifest = params.data as Manifest;
    const jo = manifest && manifest.joHeaders && manifest.joHeaders[0];
    return jo && jo.account && jo.account.accountName;
  }

  onJohGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Job Order', filter: 'agNumberColumnFilter',
        ...NavFns.createIdCellClickedNavProps('id', this.router, '/job-orders') },
      { headerName: 'Account', field: 'account.accountName', filter: 'agTextColumnFilter' },
      { ...AgFns.createSetFilterObjectProps('Status', 'joStatus.name', this.dbQueryService.getAllCached(JoStatus)) },
      { headerName: 'JO Date', field: 'joDate', filter: 'agDateColumnFilter' },
      
    ];
    const sortModel = [{ colId: 'id', sort: 'asc' as const}];
    AgFns.initGrid(this.johGridOptions, colDefs, sortModel);
  }

  onManifestGroupGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Sent Date', field: 'sentDate',  cellRenderer: 'agGroupCellRenderer' },
      { ...AgFns.createCellButtonProps('View Manifest', this.viewManifestCell) },
    ];
    const sortModel = [
      { colId: 'manifestGroup.sentDate', sort: 'desc' },
      { colId: 'invoiceHeaderId', sort: 'asc' },
    ] as ISortModel;
    this.updateManifestGroupMasterDetail(this.manifestGroupGridOptions);
    AgFns.initGrid(this.manifestGroupGridOptions, colDefs, sortModel);
  }

  updateManifestGroupMasterDetail(parentGridOptions: GridOptions) {
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      { headerName: 'Invoice', field: 'invoiceHeaderId', 
      ...NavFns.createCellClickedNavProps(this.router, '/printable-invoice') },
      { headerName: 'Invoice Date', field: 'invoiceHeader.invoiceDate' }, 
      { headerName: 'Job Order', 
        ...NavFns.createIdCellClickedNavProps('invoiceHeader.joHeaderId', this.router, '/job-orders') },
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      getDetailRowData: params => {
        const mg = params.data as ManifestGroup;
        const q = this.uow.createQuery(ManifestGroupInvoice, 'ManifestGroupInvoices').where({ manifestGroupId: mg.id })
          .expand('invoiceHeader');
        q.execute().then(mgis => params.successCallback(mgis));
      },
    };
  }

  async updateDatasource() {
    const gridApi = this.manifestGridOptions?.api;
    if (gridApi == null) {
      return;
    }
    const ds = AgFns.buildDatasource(() => this.dbQueryService.createManifestQuery());
    this.manifestGridDatasource = ds;

    gridApi.setServerSideDatasource(ds);


  }

  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/manifests'], {    });
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.manifestGridOptions,  key && key.toString());
    this.domainService.location.replaceState(url);
  }

  async onRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    const manifest = e.data as Manifest;
    if (!manifest) {
      return;
    }
    this.currentManifest = manifest;
    this.updateLocation(manifest.id);
    this.joHeaders = manifest.joHeaders;
    this.manifestGroups = manifest.manifestGroups;
  }

  onModelUpdated(params) {
    const gridApi = this.manifestGridOptions.api;
    // insure that manifests is cleared after any sorts or filters.
    this.manifestGroups = [];
    this.joHeaders = [];
    const nodes = gridApi?.getSelectedNodes();
    if (nodes == null || nodes.length == 0) { return null; }
    const rse = <RowSelectedEvent> { node: nodes[0], data: nodes[0].data };
    this.onRowSelected(rse);
  }

  async onTabChanged(evt: any) {
    const currentTabLabel = evt.tab.textLabel;
    // hack: because of ag-grid issues with button redraw on ngIf'd tab.
    if (currentTabLabel === 'Manifest Invoices') {
      if (this.currentManifest != null) {
        this.manifestGroups = this.currentManifest.manifestGroups.slice();
      } else {
        this.manifestGroups = [];
      }
    } else if (currentTabLabel === 'Job Orders') {
      this.joHeaders = this.joHeaders.slice();
    }
  }

  async viewEventSchedule(manifest: Manifest) {
    const rrule = manifest.rRule;

    await EventScheduleDialogComponent.show(this.matDialog, rrule);
  }

  canSendManifest(manifest: Manifest) {
    if (!manifest || manifest.nextProcessDate == null) {
      return false;
    }
    const today = DateFns.startOfDay(new Date());
    return manifest.nextProcessDate.getTime() <= today.getTime();
  }

  sendManifest(manifest: Manifest) {
    if (manifest != null) {
      const today = DateFns.startOfDay(new Date());
      const mg = manifest.manifestGroups.find(mg => mg.sentDate.getTime() == today.getTime());
      if (mg == null) {
        this.router.navigate(['/printable-manifest', manifest.id]);
      } else {
        // this is a safety net in case the nextProcessDate did not get updated. 
        this.dbSaveService.updateManifestAndRelated(manifest);
        this.router.navigate(['/printable-manifest-group', mg.id]);
      }
    }
  }

  viewManifest(mg: ManifestGroup) {
    if (mg != null) {
      this.router.navigate(['/printable-manifest-group', mg.id]);
    }
  }

  
}
