// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Account } from './account';
import { ActiveStatus } from './active-status';
import { AddonDocMap } from './addon-doc-map';
import { AddonImage } from './addon-image';
import { AddonStation } from './addon-station';
import { Feature } from './feature';
import { ItemDetailAddon } from './item-detail-addon';
import { JoDetailAddon } from './jo-detail-addon';
import { PoProductTypeConfigAddon } from './po-product-type-config-addon';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Addon extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  accountId: number;
  activeStatusId: number;
  additionalInfoDescription: string;
  addonStationId: number;
  groupingKey: string;
  instructions: string;
  isHiddenOnDocs: boolean;
  mOD_DESCRIPTION: string;
  mismatchFeatureId: number;
  nameAndLocation: string;
  needsAdditionalInfo: boolean;
  account: Account;
  activeStatus: ActiveStatus;
  addonDocMaps: AddonDocMap[];
  addonImages: AddonImage[];
  addonStation: AddonStation;
  itemDetailAddons: ItemDetailAddon[];
  joDetailAddons: JoDetailAddon[];
  mismatchFeature: Feature;
  poProductTypeConfigAddons: PoProductTypeConfigAddon[];
}

