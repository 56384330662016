<app-uni-page  [parent]="this" *ngIf="isPageReady" pageTitle="Add Inventory"
  [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate"></app-uni-page>

<ng-template #bodyTemplate>
  <as-split unit="percent" direction="vertical">
    <as-split-area [size]="50">
      <app-ag-grid [gridOptions]="idsGridOptions"></app-ag-grid>
    </as-split-area>
    <as-split-area [size]="50">
      <app-ag-grid [gridOptions]="newItdGridOptions" [rowData]="itdProxies"></app-ag-grid>
      <ng-template #delButtonCell let-row>
        <button class="mr-8" (click)="deleteItemDetail(row)">Del</button>
      </ng-template>
      <ng-template #accountButtonCell let-row>
        <button class="mr-8" (click)="selectAccount(row)">Select Account</button>
      </ng-template>
      <ng-template #issButtonCell let-row>
        <button class="mr-8" (click)="selectProduct(row)">Select Product</button>
      </ng-template>
      <ng-template #addModButtonCell let-row>
        <button class="mr-8" (click)="addItemDetailAddon(row)">Add Addon</button>
      </ng-template>
      <ng-template #delModButtonCell let-row>
        <button class="mr-8" (click)="deleteItemDetailAddon(row)">Del</button>
      </ng-template>
      <ng-template #embButtonCell let-row>
        <button class="mr-8" (click)="selectAddon(row)">Select Addon</button>
      </ng-template>
    </as-split-area>
  </as-split>
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <button [disabled]="isBusy" mat-raised-button (click)="addBlankItemDetail()">
      Add Blank Item Detail
    </button>
    <button [disabled]="isBusy" mat-raised-button (click)="addCopyItemDetail()">
      Copy Selected Item Detail
    </button>
    <button [disabled]="isBusy" *ngIf="this.itdProxies.length > 0" mat-raised-button (click)="saveChanges()">
      <span>Save Changes</span>
    </button>
    <button [disabled]="isBusy" *ngIf="this.itdProxies.length > 0" mat-raised-button (click)="cancelChanges()">
      <span>Cancel Changes</span>
    </button>
  </div>
  <button [disabled]="isBusy" mat-raised-button (click)="goBack()">
    <span>Go Back</span>
  </button>
</ng-template>