<app-uni-page [parent]="this" pageTitle="Job Orders - In Process" [bodyTemplate]="bodyTemplate"
  [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>

  <as-split  unit="percent" direction="vertical">

    <as-split-area [size]="50">
    <app-ag-grid [gridOptions]="johGridOptions" >
    </app-ag-grid>
    </as-split-area>
    <as-split-area [size]="50">
    <mat-tab-group (selectedTabChange)="onTabChanged($event)">
      <mat-tab label="WIP - Pulled or Received">
        <app-ag-grid [gridOptions]="itdGridOptions" [rowData]="itemDetails"></app-ag-grid>
      </mat-tab>
      <mat-tab label="Information">
        
      </mat-tab>
      <mat-tab label="Job Order Details">
        <app-ag-grid [gridOptions]="jodGridOptions" [rowData]="joDetails"></app-ag-grid>
      </mat-tab>
      <mat-tab label="Purchase Orders">
        <app-ag-grid [gridOptions]="podGridOptions" [rowData]="poDetails"></app-ag-grid>
      </mat-tab>
      <mat-tab label="Already Invoiced/Shipped">
        <app-ag-grid [gridOptions]="invoicedItdGridOptions" [rowData]="invoicedItemDetails"></app-ag-grid>
      </mat-tab>
    </mat-tab-group>
  </as-split-area>
  </as-split>


  
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons"></div>
  <div>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>
