import { agCharts } from '@ag-grid-enterprise/charts';
import { Location } from '@angular/common';
import { ThisReceiver } from '@angular/compiler';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { AgCartesianSeriesTooltipRendererParams } from 'ag-charts-community';
import { Account } from 'app/model/entities/entity-model';
import { DateFns } from 'app/shared/date-fns';

import { DbQueryService } from 'app/shared/db-query.service';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { UtilFns } from 'app/shared/util-fns';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-chart-invoice-delay',
  templateUrl: './chart-invoice-delay.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ChartInvoiceDelayComponent extends DomainBaseComponent implements OnInit {
  @Input() account: Account;
  title: string;
  options: any;
  
  constructor(
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    protected matDialog: MatDialog,
    protected location: Location,
    protected dbQueryService: DbQueryService,
  ) {
    super(domainService);

    // this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
    //   this.updateFromContext();
    // });
  }

  async ngOnInit() {
    // ngOnChanges happens right away, so nothing needed here
  }

     //this will called when data is passed from parent to child.
  async ngOnChanges(val){
    this.isPageReady = false;
    await this.processChartData();
    this.isPageReady = true;  
  }

  private async processChartData() {
    // this.title = this.getTitle();
    const data = await this.dbQueryService.getInvoiceDelayDataForChart(this.account?.id);
    DateFns.fixupDatesInProjection(data, ['invoiceMonthStart']);

    this.options = {
      title: {
        text: this.getTitle(),
      },
      data: data,
      series: [{
        type: 'line',
        xKey: 'invoiceMonthStart',
        yKey: 'invoiceCount',
        yName: 'Number of Invoices',
        tooltip: { renderer: this.renderer.bind(this) },
      },
      {
        type: "line",
        xKey: "invoiceMonthStart",
        yKey: "invoiceTotalAmt",
        yName: 'Invoice Total $',
        tooltip: { renderer: this.renderer.bind(this) },
      },
      {
        type: "line",
        xKey: "invoiceMonthStart",
        yKey: "invoiceDelayDays",
        yName: 'Days Delayed',
        tooltip: { renderer: this.renderer.bind(this) },
      },
      ],
      axes: [
        {
          type: 'time',
          nice: false,
          position: 'bottom',
          tick: {
            count: agCharts.time.month,
          },
          label: {
            format: '%b %Y',
          },
          title: {
            text: 'Month Invoiced',
          },
        },
        {
          type: 'number',
          keys: ['invoiceCount'],
          position: 'left',
          title: {
            text: 'Number of invoices',
          },
        },
        {
          type: 'number',
          keys: ['invoiceTotalAmt'],
          position: 'right',
          title: {
            text: 'Invoice Total $',
          },
          label: {
            formatter: (params) => {
              return this.UtilFns.fmtCurrencyShort(params.value);
            }
          }
        },
        {
          type: 'number',
          keys: ['invoiceDelayDays'],
          position: 'right',
          title: {
            text: 'Days Delayed',
          },
        },
      ],
    };
  }


  getTitle() {
    const accountName = (this.account == null) ?  'All accounts' : this.account.accountName;
    return `Invoice Delay Chart - ${accountName} `;
  }

  renderer(params: AgCartesianSeriesTooltipRendererParams) {
    const value = params.yValue;
    let content: string;
    if (params.yKey == 'invoiceTotalAmt') {
      content = 'Invoice Total: ' + UtilFns.fmtCurrencyShort(value);
    } else if ( params.yKey == 'invoiceCount') {
      content = 'Invoice Count: ' + value.toFixed(0);
    } else {
      content = 'Avg Days Delayed: ' + value.toFixed(0);
    }
    
    return {
      title: this.formatDateAsMonth(params.xValue),
      content: content
    };
  }

  formatDateAsMonth(date: Date) {
    const options = { year: 'numeric', month: 'long',  } as any;
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }


  
}
