
<app-uni-dialog title="Application Configuration - Default values" [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <app-ag-grid [gridOptions]="configGridOptions" [rowData]="configs">
  </app-ag-grid>
</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button [disabled]="dbSaveService.isSaving ||  !uow.hasChanges()" (click)="save()">Save & Exit
  </button>
  <button mat-raised-button [disabled]="dbSaveService.isSaving || !uow.hasChanges()" (click)="undo()">Undo Changes
  </button> 
  <button mat-raised-button [disabled]="dbSaveService.isSaving" (click)="close()">Close
  </button>
</ng-template>
