import { Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { Email, InvoiceHeader } from 'app/model/entities/entity-model';
import { InvoiceStatusCode } from 'app/model/enums/invoice-status-code';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { EmailService } from 'app/shared/email.service';
import { PrintFns } from 'app/shared/print-fns';
import { ShippingService } from 'app/shipping/shipping.service';
import { takeUntil } from 'rxjs/operators';
import { InvoicePrintDialogComponent } from './invoice-print-dialog.component';

@Component({
  selector: 'app-printable-invoice',
  templateUrl: './printable-invoice.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class PrintableInvoiceComponent extends DomainBaseComponent implements OnDestroy {
  @ViewChild('printableArea') printElementRef: ElementRef;
  invoiceId: string;
  invoiceHeader: InvoiceHeader;
  /** whether to print all invoices for a shipment */
  isAllInvoices: boolean;
  invoiceHeaders: InvoiceHeader[];

  email: Email;
  private isAllLoaded: boolean;

  constructor(
    protected domainService: DomainService,
    protected route: ActivatedRoute,
    protected matDialog: MatDialog,
    private emailService: EmailService,
    private shippingService: ShippingService,
    private elementRef: ElementRef
  ) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe((paramMap) => {
      this.isAllInvoices = !!paramMap.get('all')
      this.updateFromContext();
    });
  }

  ngOnDestroy(): void {
    // PrintFns.setPrintFn(null);
  }

  async updateFromContext() {

    await this.dbQueryService.cacheAll();
    await this.shippingService.getShippingCarriers(); // just to cache everything
    this.invoiceId = this.route.snapshot.params['invoiceId'];

    this.invoiceHeader = await this.dbQueryService.getInvoice(this.invoiceId);
    if (this.invoiceHeader == null) {
      this.isPageReady = true;
      return;
    }
    this.invoiceHeaders = await this.dbQueryService.getInvoicesOnSameShipment(this.invoiceHeader.id);
    // sort so invoice with freight appears first
    this.invoiceHeaders.sort((a, b) => {
      return a.hasFreight ? -1 : b.hasFreight ? 1 : a.id.localeCompare(b.id);
    })

    // For now we always print ALL of the invoices on a shipment
    this.isAllInvoices = true;

    if (this.isAllInvoices) {
      await this.loadAllInvoices();
    }
    
    this.isPageReady = true;

    setTimeout(() => {
      // if printing or headless browser, use print view
      const mediaQueryList = window.matchMedia('print');
      if (mediaQueryList.matches || window.navigator.userAgent.includes('Headless')) {
        this.preparePrint();
      } else {
        this.getInvoiceEmailStatus();
      }
    }, 0);
  }

  async onAllInvoicesChange(event) {
    if (this.isAllInvoices && !this.isAllLoaded) {
      await this.loadAllInvoices();
    }
  }

  /** Load invoices individually with JO and related data */
  private async loadAllInvoices() {
    this.isPageReady = false;
    const proms = this.invoiceHeaders.map(ih =>this.dbQueryService.getInvoice(ih.id));
    await Promise.all(proms);
    this.isAllLoaded = true;
    this.isPageReady = true;
  }

  async showPrintDialog() {
    const isPosted = this.invoiceHeader.invoiceStatusId === InvoiceStatusCode.InvoicePosted || this.invoiceHeader.invoiceStatusId === InvoiceStatusCode.AwaitPost;
    const isLabeled = this.invoiceHeader.isLabelled;
    const hasVoidError = this.invoiceHeader.invoiceStatusId === InvoiceStatusCode.VoidError;
    if (hasVoidError) {
      this.dialogService.showOkMessage('Cannot print', 'This invoice has been voided and cannot be printed or posted until a new label has been printed for the shipment.');
      return false;
    }
    // it is posted then we are allowed to 'Reprint' it. 
    const okToPrint = isLabeled || isPosted;
    if (!okToPrint) {
      this.dialogService.showOkMessage('Cannot print', 'This invoice cannot be printed or posted until a label has been printed for the shipment.');
      return false;
    }
    if (!this.user.isAccountingAdmin) {
      this.dialogService.showOkMessage('Not authorized', 'This is an accounting function that you are not currently authorized for.');
      return false;
    }
    // if (this.invoiceHeader.joHeader.manifestId != null) {
    //   this.dialogService.showOkMessage('Cannot print', 'This invoice cannot be printed independently because it is on a manifest.');
    //   return false;
    // }

    await InvoicePrintDialogComponent.show(this.matDialog, {
      invoiceHeader: this.invoiceHeader,
      canPreview: false,
    });

    await this.getInvoiceEmailStatus();
    return true;
  }

  preparePrint() {
    let el: HTMLElement = this.printElementRef && this.printElementRef.nativeElement;
    if (el == null) {
      el = this.elementRef && this.elementRef.nativeElement;
    }
    if (!el) {
      return;
    }
    PrintFns.printElement(el);
  }

  async getInvoiceEmailStatus() {
    this.email = await this.emailService.getInvoiceEmailStatus(this.invoiceId);
  }

  goToInvoice() {
    this.router.navigate(['/jo-invoice', this.invoiceHeader.joHeader?.id, this.invoiceId]);
  }


}
