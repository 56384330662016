<app-uni-page [parent]="this" pageTitle="Job Order Ship Preparation" [bodyTemplate]="bodyTemplate"
  [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
  <as-split unit="percent" direction="vertical">
    <as-split-area [size]="50">
      <div uni-flex-row class="my-4">
        <mat-checkbox class="ml-8" [(ngModel)]="shouldShowAllDetails" [ngModelOptions]="{standalone: true}"
          (ngModelChange)="refreshGrids()">
          Show All Item Details
        </mat-checkbox>


        <!-- <mat-form-field class="ml-8" style="width: 200px;">
          <mat-label>Refresh Page</mat-label>
          <mat-select placeholder="Select Station" [(ngModel)]="refreshSeconds" (ngModelChange)="setupRefresh()">
            <mat-option [value]="refreshOption.refreshSeconds" *ngFor="let refreshOption of this.refreshOptions">
              {{refreshOption.name}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
      <app-ag-grid [gridOptions]="johGridOptions" style="height: calc(100% - 75px)"></app-ag-grid>
    </as-split-area>
    <as-split-area [size]="50" style="display: flex; flex-direction: column;">
      <div  style="flex-grow: 4">
        <app-ag-grid [gridOptions]="itdGridOptions" [rowData]="itemDetails" ></app-ag-grid>
      </div>
    </as-split-area>
  </as-split>
</ng-template>

<ng-template #footerTemplate>
  <mat-spinner class="mt-12 ml-12" diameter="30" *ngIf="busyService.isBusy | async; else notBusy"></mat-spinner>
  <ng-template #notBusy>
    <div class="left-buttons">
      <button mat-raised-button (click)="showShipPrepScanDialog()">
        <span>Scan into Ship Prep</span>
      </button>
    </div>
    <div>
      <button mat-raised-button (click)="goToShipping()">
        <span>Go to Shipping and Invoicing</span>
      </button>
      <button mat-raised-button (click)="goBack()">
        <span>Go Back</span>
      </button>
    </div>
  </ng-template>
</ng-template>