import { GetRowIdParams, GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { UserAccountsService } from 'app/admin/user-accounts.service';
import {
  Account, InvoiceHeader, ProductType, JoDetail, JoDetailAddon, JoHeader, JoHeaderPull, JoPullBatch, JoStatus,
  Manufacturer, ReturnRequest, ReturnRequestDetail,
  ShipFrequency,
  PoDetail
} from 'app/model/entities/entity-model';
import { InvoiceStatusCode } from 'app/model/enums/invoice-status-code';
import { ItemDetailStatusCode } from 'app/model/enums/item-detail-status-code';
import { JobOrderMode, JobStatusCodeExt, JoStatusCode } from 'app/model/enums/jo-status-code';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { AgFns, GridState, ISortModel } from 'app/shared/ag-fns';
import { AuthUserSelectDialogComponent } from 'app/shared/auth-user-select-dialog.component';
import { AuthUser } from 'app/shared/auth.service';
import { DomainBaseComponent } from 'app/shared/domain-base.component';
import { DomainService } from 'app/shared/domain.service';
import { EditNotesDialogComponent } from 'app/shared/edit-notes-dialog.component';
import { FileViewDialogComponent, FileViewDialogData } from 'app/shared/file-view-dialog.component';
import { JoUtilsService } from 'app/shared/jo-utils.service';
import { NavFns } from 'app/shared/nav-fns';
import { ToastTypes } from 'app/shared/toast.service';
import { Entity, EntityState } from 'breeze-client';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-job-orders',
  templateUrl: './job-orders.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class JobOrdersComponent extends DomainBaseComponent implements OnInit {
  // @ViewChild('addRemoveBatchCell') addRemoveBatchCell: TemplateRef<any>;
  // @ViewChild('editBatchCell') editBatchCell: TemplateRef<any>;
  
  @ViewChild('invoiceCell') invoiceCell: TemplateRef<any>;
  @ViewChild('auditJoCell') auditJoCell: TemplateRef<any>;
  @ViewChild('invdButtonsCell') invdButtonsCell: TemplateRef<any>;
  @ViewChild('editJohNotesCell') editJohNotesCell: TemplateRef<any>;
  @ViewChild('editJodNotesCell') editJodNotesCell: TemplateRef<any>;
  @ViewChild('addReturnRequestCell') addReturnRequestCell: TemplateRef<any>;
  @ViewChild('viewDocCell') viewDocCell: TemplateRef<any>;

  allStatuses: JoStatus[];
  currentTabLabel: string;

  shipFrequencies: ShipFrequency[];

  joHeaders: JoHeader[];
  johGridOptions: GridOptions;

  joDetails: JoDetail[] = [];
  jodGridOptions: GridOptions;

  invoiceHeaders: InvoiceHeader[] = [];
  invhGridOptions: GridOptions;

  returnRequestDetails: ReturnRequestDetail[] = [];
  rradGridOptions: GridOptions;

  
  poDetails: PoDetail[];
  podGridOptions: GridOptions;

  private _jobOrderMode = null;
  joPullBatch: JoPullBatch;

  currentJoHeader: JoHeader;

  usersWithPullStaff: AuthUser[] = [];
  
  constructor(protected domainService: DomainService, protected route: ActivatedRoute, private matDialog: MatDialog,
    private joUtilsService: JoUtilsService, private userAccountService: UserAccountsService) {
    super(domainService);

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    // allStatuses needs to be defined before jobOrderMode;
    this.allStatuses = this.dbQueryService.getAllCached(JoStatus);
    const joStatusAll = <JoStatus>{ id: JobOrderMode.All, name: 'All' };
    this.allStatuses.unshift(joStatusAll);

    this.shipFrequencies = this.dbQueryService.getAllCached(ShipFrequency);

    this.jobOrderMode = this.route.snapshot.queryParams['mode'] || JobOrderMode.All;

    if (this.authUser.isPullAdmin) {
      this.usersWithPullStaff = await this.userAccountService.getAuthUsersWithRole('PullStaff');
    }
    
    this.joPullBatch = null;

    this.johGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJohGridReady,
      onRowSelected: this.onRowSelected,
      onModelUpdated: this.onJohModelUpdated,
      onFilterChanged: this.onFilterChanged,
      rowModelType: 'serverSide',
    });
    this.johGridOptions.onPaginationChanged = (params) => {
      if (params.newPage) {
        this.clearCurrentSelection();
      }
    };
    AgFns.captureGridRouteParams(this.johGridOptions, this.route, 'id');

    this.jodGridOptions = AgFns.initGridOptions( this, {
      onGridReady: this.onJodGridReady,
      rowSelection: 'multiple'
    }, { detailProperty: 'joDetailAddons' });

    this.podGridOptions = AgFns.initGridOptions( this, {
      onGridReady: this.onPodGridReady,
      rowSelection: 'single'
    })
    
    this.invhGridOptions = AgFns.initGridOptions( this, {
      onGridReady: this.onInvhGridReady,
      rowSelection: 'multiple'
    }, {detailProperty: 'invoiceDetails' });

    this.rradGridOptions = AgFns.initGridOptions( this, {
      onGridReady: this.onRradGridReady,
      rowSelection: 'multiple',
    }, { detailProperty: 'creditMemos' })

    this.isPageReady = true;
  }

  canDeactivate() {
    this.johGridOptions.api?.stopEditing();
    this.uow.clearEntities(JoDetail);
    this.uow.clearEntities(JoHeader);
    this.uow.clearEntities(ProductType);
    this.uow.clearEntities(Account);
    this.uow.clearEntities(Manufacturer);
    return true;
  }

  getComparator(path: string) {
    var comparator =(valueA, valueB, nodeA, nodeB, isDescending) => {
      var a = _.get(nodeA, path)
      var b = _.get(nodeB, path);
      var r = 0;
      if (a == b) r = 0;
      if (a > b) r = 1;
      else r = -1;
      return isDescending ? (r * 1) : r;
    }
    return comparator;
  }

  onPodGridReady(evt: any) {
    const colDefs = [
      // Already shown above on PurchaseOrder
      // { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'product.productType.description' },
      { headerName: 'SKU', field: 'product.productType.style' },
      { headerName: 'Features', field: 'product.featureChoicesExtract' },
      {
        headerName: 'Quantities', children: [
          { headerName: 'Order', field: 'orderQty', maxWidth: 75, type: 'numericColumn' },
          { headerName: 'Held', field: 'heldQty', maxWidth: 75, type: 'numericColumn' },
          {
            headerName: 'Rcvd', type: 'numericColumn', maxWidth: 75,
            valueGetter: params => {
              const pod = <PoDetail>params.data;
              return pod?.getReceivedQty();
            },
          },
          {
            headerName: 'Cncl', type: 'numericColumn', maxWidth: 75,
            valueGetter: params => {
              const pod = <PoDetail>params.data;
              return pod?.getCanceledQty();
            },
          },
          {
            headerName: 'Net', type: 'numericColumn', maxWidth: 75,
            valueGetter: params => {
              const pod = <PoDetail>params.data;
              return pod?.getRemainingQty();
            },
          },
          {
            headerName: 'Shipped', type: 'numericColumn', maxWidth: 75,
            valueGetter: params => {
              const pod = <PoDetail>params.data;
              return pod?.getShippedQty();
            },
          }],
      },
      { headerName: 'PO', field: 'poHeaderId' },
      { headerName: 'PO Detail', field: 'id' },
      { headerName: 'JO Detail', field: 'joDetailId' },
    ]
    const sortFields = [
      'product.productType.description',
      'product.productType.style',
      'product.choiceValue',
      'poDetailTypeId'
    ];
    const sortModel = sortFields.map(sf => {
      return { colId: sf, sort: 'asc' as const };
    });

    this.podGridOptions.getRowClass = function (params) {
      const pod = params.node.data as PoDetail;
      if (pod.getRemainingQty() == 0) {
        return 'rs-completed';
      }
    };

    AgFns.initGrid(this.podGridOptions, colDefs, sortModel, true);
  }
  

  onJohGridReady(evt: any) {
    const shipFrequencies = this.shipFrequencies;
    const colDefs = [
      { headerName: 'Job Order', field: 'id', filter: 'agNumberColumnFilter', minWidth: 90 },
      // Ugh: can't set header name in create button props because it screws up sorting
      // but we can set it later... ???
      { headerName: 'Batch Id', field: 'joHeaderPull.joPullBatchId', filter: 'agNumberColumnFilter', },
      { ...AgFns.createButtonProps('Batch Op', this.addRemoveBatch.bind(this), { 
        canDisplay: this.canAddRemoveBatch.bind(this),
        calcLabel: this.calcAddRemoveBatchLabel.bind(this),
        fixWidth: 90
       }), 
      },
      { ...AgFns.createButtonProps('', this.editBatch.bind(this), { 
          canDisplay: this.canEditBatch.bind(this),
          label:`Edit`, fixWidth: 60
         } ), 
         field: 'joHeaderPull.joPullBatchId', comparator: this.getComparator('joHeaderPull.joPullBatchId')
      },
      { ...AgFns.createButtonProps('WIP', this.goJoInProcess.bind(this), { 
        canDisplay: this.hasWIP.bind(this),
        label: 'WIP',
        calcClass: (row) => this.hasWIP(row) ? 'btn-has-data' : ''
       } )
      },
      { ...AgFns.createCellButtonProps('Invoice/Voucher', this.invoiceCell, 'invoice') },
      { headerName: 'User', field: 'joHeaderPull.joPullBatch.userInit', filter: 'agTextColumnFilter' },
      { headerName: 'Account', field: 'account.accountName', filter: 'agTextColumnFilter' },
      { headerName: 'Status', field: 'joStatus.name'  },
      { headerName: 'JO Date', field: 'joDate', filter: 'agDateColumnFilter' },
      { headerName: 'ShipFrequency',  
        editable: this.isShipFrequencyEditable.bind(this), cellStyle: this.getShipFrequencyCellStyle.bind(this), 
        minWidth: 120, sortable: false,
        ...AgFns.createDropdownEditor('shipFrequencyId', this.shipFrequencies, this.saveShipFrequencyIfChanged.bind(this) )
      },
      { headerName: 'Account pays freight', field: 'doesAccountPayFreight', editable: false, cellRenderer: AgCheckboxCellComponent},
      { headerName: 'Allow Partial Shipments', field: 'allowPartialShipments', editable: false, cellRenderer: AgCheckboxCellComponent },
      { headerName: 'Manifest', 
        ...NavFns.createIdCellClickedNavProps('manifestId', this.router, '/manifests') 
      },
      { ...AgFns.createCellButtonProps('Audit', this.auditJoCell), minWidth: 50 },
      { ...AgFns.createCellButtonProps('Notes', this.editJohNotesCell)  },
      { ...AgFns.createCellButtonProps('Add Return Request', this.addReturnRequestCell, 'addReturnRequest')  },
      { headerName: 'Handling Charge/Order', field: 'handlingChargePerOrderAmt', filter: 'agNumberColumnFilter' },
      { headerName: 'Handling Charge/Shipment', field: 'handlingChargePerShipmentAmt', filter: 'agNumberColumnFilter' },
    ];

    const sortModel =  this.getJohSortModel();
    
    AgFns.initGrid(this.johGridOptions, colDefs, sortModel);
    this.updateColumnVisibility(); 
    this.updateDatasource();
    AgFns.applyGridRouteParams(this.johGridOptions);
    
  }

  // Ship Frequency related methods - for testing only

  private isShipFrequencyEditable(p: any) {
    if (!this.user.isAccountAdmin) return false;
    const joh = p.data as JoHeader;
    if (!joh) return false;
    if (joh.joStatusId == JoStatusCode.Closed) return false;
    return true;
  }

  private getShipFrequencyCellStyle(p: any) {
    if (this.isShipFrequencyEditable(p)) {
      return AgFns.setEditableStyle(p);
    } else {
      return null;
    }
  }

  private async saveShipFrequencyIfChanged(p: any) {
    const joh = p.data as JoHeader;
    if (joh && joh.entityAspect.entityState.isModified()) {
      await this.dbSaveService.saveSelectedChanges([joh]);
    }
  }

  // -------------------------------------------

  private getJohSortModel() {
    let sortModel: ISortModel;
    if (this.jobOrderMode === JobOrderMode.All) {
      sortModel = [{ colId: 'id', sort: 'desc' }];
    } else {
      sortModel = [{ colId: 'joHeaderPull.joPullBatchId', sort: 'desc' }, { colId: 'id', sort: 'desc' }];
    }
    return sortModel;
  }

  onJodGridReady(evt: any) {
    const colDefs = [
      { headerName: 'JO Detail Id', field: 'id', cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name' },
      { headerName: 'Description', field: 'product.productType.description' },
      { headerName: 'Style', field: 'product.productType.style' },
      { headerName: 'Features', field: 'product.featureChoicesExtract' },
      { ...AgFns.createCellButtonProps('Notes', this.editJodNotesCell)  },
      { headerName: 'Order Qty', field: 'orderQty', type: 'numericColumn' },
      { headerName: 'Cancel Qty', field: 'cancelQty', type: 'numericColumn' },
      // could break this into pulled vs rcvd
      {
        headerName: 'Pulled/Rcvd Qty',
        type: 'numericColumn',
        headerTooltip: '(calc) Quantities already pulled or received',
        valueGetter: params => {
          const jod = <JoDetail>params.data;
          return jod.getItemDetailsRcvdQty();
        },
      },
      {
        headerName: 'Open PO Qty',
        type: 'numericColumn',
        headerTooltip: '(calc) PO Detail quantities not yet received or canceled',
        valueGetter: params => {
          const jod = <JoDetail>params.data;
          return jod.getPoDetailsRemainingQty();
        },
      },
      {
        headerName: 'Held PO Qty',
        type: 'numericColumn',
        headerTooltip: '(calc) PO Detail quantities held',
        valueGetter: params => {
          const jod = <JoDetail>params.data;
          return jod.getPoDetailsHeldQty();
        },
      },
      {
        headerName: 'Net Qty',
        type: 'numericColumn',
        headerTooltip:
          '(calc) The Net quantities remaining after all itemDetails and unreceived purchase order details have been accounted for',
        valueGetter: params => {
          const jod = <JoDetail>params.data;
          return jod.getRemainingQty();
        },
      },
      { headerName: 'Return Qty', field: 'returnQty', type: 'numericColumn' },
      {
        headerName: 'Shipped Qty',
        type: 'numericColumn',
        headerTooltip:
          '(calc) Number of item details shipped for this JO Detail',
        valueGetter: params => {
          const jod = <JoDetail>params.data;
          return jod.getItemDetailsShippedQty();
        },
      },

    ];
    const sortFields = [
      'product.productType.manufacturer.name',
      'product.productType.description',
      'product.productType.style',
    ];
    const sortModel = sortFields.map(sf => {
      return { colId: sf, sort: 'asc' };
    }) as ISortModel;

    this.updateJodMasterDetail(this.jodGridOptions);
    AgFns.initGrid(this.jodGridOptions, colDefs, sortModel, true);
    
  }

  updateJodMasterDetail(parentGridOptions: GridOptions) {
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      { headerName: 'Addon', field: 'addon.nameAndLocation' },
      { headerName: 'Addl. Info', field: 'additionalInfo' },
      { headerName: 'Price/Unit', field: 'unitPriceAmt' },
      { ...AgFns.createCellButtonProps('Document', this.viewDocCell)  },
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    
    detailGridOptions.getRowId = (params: GetRowIdParams) => {
      const jod = params.data as JoDetail;
      return jod.id.toString();
    },
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      refreshStrategy: 'everything',
      
      getDetailRowData: params => {
        const jod = params.data as JoDetail;
        // load docs when addons are expanded
        const jodAddonIds = jod.joDetailAddons.map(joda => joda.id);
        this.dbQueryService.getJodAddonsWithDocs(jodAddonIds).then(() => {
          params.successCallback(jod.joDetailAddons);
        })
      },
    };
  }

  onInvhGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Invoice', field: 'id', cellRenderer: 'agGroupCellRenderer',
        ...NavFns.createCellClickedCalcNavProps(this.router,
        (event) => { 
          const invh = <InvoiceHeader> event.data;
          return { navPath: `/jo-invoice/${ invh.joHeaderId }/${ invh.id }` }; 
        })
      },
      { headerName: 'Date', field: 'invoiceDate',  },
      { headerName: 'Status', field: 'invoiceStatus.name', },
    ];
    this.updateInvhMasterDetail(this.invhGridOptions);
    const sortModel = [{ colId: 'id', sort: 'desc' as const }];
    AgFns.initGrid(this.invhGridOptions, colDefs, sortModel);
  }

  updateInvhMasterDetail(parentGridOptions: GridOptions) {
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      { headerName: 'Inv. Ship Qty', field: 'shipQty', filter: 'agNumberColumnFilter',  },
      { headerName: 'Inv Redistribute Qty', field: 'redistributeQty', filter: 'agNumberColumnFilter', },
      { headerName: 'JO Detail Id', field: 'joDetail.id', filter: 'agNumberColumnFilter' },
      { headerName: 'Inv Detail Id', field: 'id', filter: 'agNumberColumnFilter' },
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      getDetailRowData: params => {
        const invh = params.data as InvoiceHeader;
        params.successCallback(invh.invoiceDetails);
      },
    };
  }

  onRradGridReady(event: any) {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'joDetail.product.productType.manufacturer.name', cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'Description', field: 'joDetail.product.productType.description' },
      { headerName: 'SKU', field: 'joDetail.product.productType.style' },
      { headerName: 'Features', field: 'joDetail.product.productfeatureChoicesExtract' },
      { headerName: 'Action', field: 'returnRequestDetailAction.name' },
      { headerName: 'Status', field: 'returnRequestDetailStatus.name' },
      { headerName: 'Qty', field: 'qty' },
      { headerName: 'Notes', field: 'notes',  cellEditor: 'agLargeTextCellEditor'},
      { headerName: 'JO Detail Id', field: 'joDetail.id',  },
    ];
    this.updateRradMasterDetail(this.rradGridOptions);
    const sortModel = [{ colId: 'returnRequestDetailAction.name', sort: 'desc' as const }];
    AgFns.initGrid(this.rradGridOptions, colDefs, sortModel);
  }

  updateRradMasterDetail(parentGridOptions: GridOptions) {
    const detailGridOptions = AgFns.createDetailGridOptions();

    detailGridOptions.columnDefs = [
      { headerName: 'Credit Memo Amount', field: 'creditMemoAmt',   },
      { headerName: 'Status', field: 'creditMemoStatus.name',   },
      { headerName: 'Notes', field: 'notes',  },
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      getDetailRowData: params => {
        const rrad = params.data as ReturnRequestDetail;
        params.successCallback(rrad.creditMemos);
      },
    };
  }

  get jobOrderMode() {
    return this._jobOrderMode;
  }
  set jobOrderMode(mode: JobStatusCodeExt ) {
    if (this.jobOrderMode !== mode) {
      this._jobOrderMode = mode;
      const api = this.johGridOptions?.api;
      if (api) {
        api.setServerSideDatasource(null);
        api.setFilterModel(null);
        this.johGridOptions.columnApi.applyColumnState( { state: this.getJohSortModel() });
        this.clearCurrentSelection();
        // need to make sure that we return to the first page after the updateDatasource call below.
        // but we don't want to do it on the original set of jobOrderMode
        const gridState = this.johGridOptions.context?.gridState;
        if (gridState != null) {
          gridState.pg = 1;
        }
      }
      
      this.updateDatasource();
      this.updateLocation();
      this.updateColumnVisibility();
    }
  }

  get currentJoStatus() {
    return this.allStatuses.find(s => s.id === this.jobOrderMode);
  }
  set currentJoStatus(value: JoStatus) {
    if (value === undefined) {
      return;
    }
    if (this.currentJoStatus !== value) {
      this.jobOrderMode = <JobStatusCodeExt> value.id;
    }
  }

  onFilterChanged() {
    this.clearCurrentSelection();
  }

  clearCurrentSelection() {
    // insure that bound vars are cleared after any sorts or filters. - because selection is lost

    // HACK: 
    // this insures that we don't reenter here after the deselectAll call below which causes a modelUpdated event
    // which calls this.  The problem is when returning to the page this method is called before the 'key' row is selected
    // but then is called again after the row is selected ( because deselectAll causes modelUpdated after a timeout) and this 
    // 2nd call clears the row that has just been selected. UGH.
    if (this.currentJoHeader === null) {
      return;
    }
    this.currentJoHeader = null;

    this.joDetails = [];
    this.invoiceHeaders = [];
    this.returnRequestDetails = [];
    this.poDetails = [];
    
    this.updateLocation();
    this.johGridOptions.api?.deselectAll();
    
  }

  isModeIncomplete() {
    return this.jobOrderMode === JobOrderMode.OpenProcessIncomplete;
  }

  async updateDatasource() {
    const gridApi = this.johGridOptions && this.johGridOptions.api;
    if (gridApi == null) {
      return;
    }
    const ds = AgFns.buildDatasource(() => this.dbQueryService.createJoHeaderModeQuery(this.jobOrderMode));

    gridApi.setServerSideDatasource(ds);
  }

  updateLocation() {
    const urlTree = this.router.createUrlTree(['/job-orders'], {
      queryParams: {
        mode: this.jobOrderMode,
      },
    });
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.johGridOptions, this.currentJoHeader?.id.toString());
    this.domainService.location.replaceState(url);
  }

  updateColumnVisibility() {
    const colApi = this.johGridOptions?.columnApi;
    if (!colApi) {
      return;
    }
    const batchFieldNames = ['batch', 'joHeaderPull.joPullBatch.userInit'];
    colApi.setColumnsVisible(batchFieldNames, this.jobOrderMode == JobOrderMode.OpenProcessIncomplete || this.jobOrderMode === JobOrderMode.OpenProcessComplete);
    colApi.setColumnsVisible(['joStatus.name'], this.jobOrderMode === JobOrderMode.All);
    colApi.setColumnsVisible(['add'], this.jobOrderMode === JobOrderMode.OpenProcessIncomplete);
    colApi.setColumnsVisible(['remove'], this.jobOrderMode === JobOrderMode.OpenProcessIncomplete);
    colApi.setColumnsVisible(['invoice'], this.jobOrderMode === JobOrderMode.OpenProcessComplete);
    colApi.setColumnsVisible(['addReturnRequest'], this.jobOrderMode == JobOrderMode.OpenProcessComplete || this.jobOrderMode === JobOrderMode.Closed);

    if (this.jobOrderMode === JobOrderMode.OpenProcessComplete) {
      const lastColIx = colApi.getColumns().length - 1;
      colApi.moveColumn('batch', lastColIx);
      colApi.moveColumn('joHeaderPull.joPullBatch.userInit', lastColIx);
    } else if (this.jobOrderMode == JobOrderMode.OpenProcessIncomplete) {
      colApi.moveColumn('batch', 1);
      colApi.moveColumn('joHeaderPull.joPullBatch.userInit', 2);
    }
    colApi.autoSizeAllColumns();
  }

  getTitle() {
    if (this.jobOrderMode === JobOrderMode.OpenProcessIncomplete) {
      const batchId =  this.joPullBatchId ?? ' [New] ';
      const batchUser = this.joPullBatch ? ' - For Pull Staff: ' + this.joPullBatch.userInit : '';
      const batchText = `Current Batch: ${batchId}  ${batchUser}`;
      return ` - Open/Process Incomplete status only - ` + batchText;
    } else if (this.jobOrderMode === JobOrderMode.OpenProcessComplete) {
      return ` - Open/Process Complete status only`;
    } else if (this.jobOrderMode === JobOrderMode.Closed) {
      return ` - Closed only`;
    } else {
      return ' - All statuses';
    }
  }

  async onRowSelected(e: RowSelectedEvent) {
    if (this.opIsPending) {
      return;
    }
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    const joh = e.data as JoHeader;
    if (!joh) {
      return;
    }
    this.currentJoHeader = joh;

    this.updateLocation();

    if (this.jobOrderMode === JobOrderMode.OpenProcessIncomplete) {
      if (joh.joHeaderPull != null) {
        if (joh.joHeaderPull.joPullBatch.canUserEdit(this.authUser)) {
          this.joPullBatch = joh.joHeaderPull.joPullBatch;
        }
      }
    }
    const promises = [ this.queryJoDetails(joh) ] as any[];
    if (this.currentTabLabel === 'Invoices') {
      promises.push( this.queryInvoices(joh));
    } else if (this.currentTabLabel === 'Returns') {
      promises.push( this.queryReturnRequestDetails(joh));
    }

    await Promise.all(promises);
    this.poDetails = _.flatMap(this.joDetails.map(jod => jod.poDetails));
  }
  async queryJoDetails(joh: JoHeader) {
    // Can't use breeze.isNavigationLoaded here because we need to check for deeper
    // navigation than just joDetails.

    if (!joh['areJoDetailsLoaded']) {
      await AgFns.busyGrid(this.jodGridOptions, this.busyService, async () => {
        this.joDetails = await this.dbQueryService.getJoDetails(joh.id);
      });
      joh['areJoDetailsLoaded'] = true;
    } else {
      this.joDetails = joh.joDetails;
    }
    this.poDetails = _.flatMap(this.joDetails.map(jod => jod.poDetails));
    return this.joDetails;
  }

  async queryInvoices(joh: JoHeader) {
    if (!joh['areInvoiceHeadersLoaded']) {
      await AgFns.busyGrid(this.invhGridOptions, this.busyService, async () => {
        this.invoiceHeaders = await this.dbQueryService.getInvoiceHeadersByJoHeaderId(joh.id);
      });
      joh['areInvoiceHeadersLoaded'] = true;
    } else {
      this.invoiceHeaders = joh.invoiceHeaders.slice(0);
    }
    return this.invoiceHeaders;
  }

  async queryReturnRequestDetails(joh: JoHeader) {
    if (!joh['areReturnsLoaded']) {
      await AgFns.busyGrid(this.rradGridOptions, this.busyService, async () => {
        const returnRequests = await this.dbQueryService.getReturnRequestsByJoHeaderId(joh.id);
      });
      joh['areReturnsLoaded'] = true;
    } 
    
    this.returnRequestDetails = _.flatten(joh.returnRequests.map(rra => rra.returnRequestDetails));
    return this.returnRequestDetails;
  }
  
  get joPullBatchId() {
    return this.joPullBatch ? this.joPullBatch.id : null;
  }

  onJohModelUpdated(params) {
    this.clearCurrentSelection();
  }

  hasJohNotes(joh: JoHeader) {
    return joh && joh.joNotes.length > 0;
  }

  async editJohNotes(joh: JoHeader) {
    await EditNotesDialogComponent.show(this.matDialog, { header: joh });
    
  }

  hasJodNotes(jod: JoDetail) {
    return jod && jod.joNotes.length > 0;
  }

  async editJodNotes(jod: JoDetail) {
    await EditNotesDialogComponent.show(this.matDialog, { detail: jod });
  }

  goJoAudit(joh: JoHeader) {
    if (this.updateCurrentJoh(joh)) {
      const params = NavFns.buildNavIdParams(joh.id);
      this.router.navigate(['/jo-audit'], params); 
    }
  }

  goPrintableInvoice(invh: InvoiceHeader) {
    if (invh != null) {
      this.router.navigate(['/printable-invoice', invh.id ]);
    }
  }

  async createNewBatch() {
    this.joPullBatch = null;
    this.dialogService.toast('New batch created');
  }

  canAddRemoveBatch(joh: JoHeader) {
    return this.authUser.isPullAdmin && joh != null && joh.joStatusId === JoStatusCode.OpenProcessIncomplete
  }

  async addRemoveBatch(joh: JoHeader) {
    if (joh.joHeaderPull == null) {
      return await this.addToBatch(joh)
    } else {
      return await this.removeFromBatch(joh);
    }
  }

  calcAddRemoveBatchLabel(joh: JoHeader) {
    if (joh.joHeaderPull == null) {
      return 'Add';
    } else {
      return 'Remove';
    }
  }

  // canAddToBatch(joh: JoHeader) {
  //   return this.authUser.isPullAdmin && joh != null && joh.joStatusId === JoStatusCode.OpenProcessIncomplete && joh.joHeaderPull == null;
  // }

  // canRemoveFromBatch(joh: JoHeader) {
  //   return (
  //     this.authUser.isPullAdmin && 
  //     joh != null &&
  //     joh.joStatusId === JoStatusCode.OpenProcessIncomplete &&
  //     joh.joHeaderPull != null 
  //     // joh.joHeaderPull.joPullBatch.canUserEdit(this.authUser)
  //   );
  // }

  async addToBatch(joh: JoHeader) {
    return this.wrapPending( async () => {
      // check if status is ok
      // TODO: create EnumGenerator for lookup tables.
      if (joh.joStatusId !== JoStatusCode.OpenProcessIncomplete) {
        this.dialogService.toast({
          message: 'Only Job Orders with a Status of: Open/Process Incomplete may be added to a batch',
          type: ToastTypes.Warning,
        });
        return false;
      }

      await this.queryJoDetails(joh);
      if (joh.joDetails.length === 0) {
        this.dialogService.toast({
          message: 'Cannot add this Job Order because it has no details',
          type: ToastTypes.Warning,
        });
        return false;
      }

      let joHeaderPull = joh.joHeaderPull;
      // check if joHeader is already part of another group

      if (joHeaderPull) {
        if (this.joPullBatchId && joHeaderPull.joPullBatchId === this.joPullBatchId) {
          // just adding it again - ignore.
          return true;
        }
      }

      if (this.joPullBatchId == null) {
        const aus = await AuthUserSelectDialogComponent.show(this.matDialog, { 
          authUsers: this.usersWithPullStaff,
        })
        if (aus.length != 1) {
          return false;
        }
        const pullStaffAu = aus[0];
        this.joPullBatch = this.uow.createEntity(JoPullBatch, { userInit: pullStaffAu.initials, modTs: new Date() });
      } else {
        this.joPullBatch.modTs = new Date();
      }

      joHeaderPull = this.uow.createEntity(JoHeaderPull, { joHeaderId: joh.id, joPullBatchId: this.joPullBatchId });

      await this.dbSaveService.saveChanges();
      // next line is needed to insure that joHeader.joPullBatchId column is refreshed.
      this.johGridOptions.api?.refreshCells();
      return true;
    });
  }

  

  async removeFromBatch(joh: JoHeader) {
    return this.wrapPending( async () => {
      const res = await this.dialogService.askYesNo('Remove this Job Order from its batch', 'Are you sure?');
      if (res.index !== 0) {
        return;
      }
      this.dialogService.toast({ message: 'Removing Job Order from Pull batch' });
      await this.joUtilsService.deleteJoHeaderFromBatch(joh.id);
      this.dialogService.toast({ message: 'Job Order removal complete' });
      // next line is needed to insure that joHeader.joPullBatchId column is refreshed.
      this.joPullBatch = null;
      this.johGridOptions.api?.refreshCells();
      // this.johGridApi.redrawRows();
    });
  }

  canEditBatch(joh: JoHeader) {
    return this.authUser.isPullStaff && joh != null && joh.joHeaderPull != null && joh.joHeaderPull.joPullBatch.canUserEdit(this.authUser);
  }

  async editBatch(joh: JoHeader) {
    return this.wrapPending( async () => {
      this.updateCurrentJoh(joh);
      const joPullBatchId = joh?.joHeaderPull?.joPullBatchId;
      if (joPullBatchId != null) {
        this.router.navigate(['/jo-pull-to-wip', joPullBatchId]);
      }
    });
  }

  canViewInvoices(joh: JoHeader) {
    return this.hasInvoices(joh) && this.areAllInvoicesPosted(joh);
  }

  canEditInvoices(joh: JoHeader) {
    return this.hasInvoices(joh) && !this.areAllInvoicesPosted(joh);
  }

  hasInvoices(joh: JoHeader) {
    return joh != null && joh.invoiceHeaders != null && joh.invoiceHeaders.length > 0;
  }

  areAllInvoicesPosted(joh: JoHeader) {
    return joh != null && joh.invoiceHeaders != null
      && joh.invoiceHeaders.every(invh => invh.invoiceStatusId === InvoiceStatusCode.InvoicePosted);
  }

  hasWIP(joh: JoHeader) {
    // need to determine if there are any itemDetail associated with this invoice
    const jods = joh && joh.joDetails;
    if (jods == null) {
      return false;
    }
    const itds = _.flatMap(jods, jod => jod.itemDetails);
    const ok = itds.some(itd =>
      itd.itemDetailStatusId > ItemDetailStatusCode.InInventory && itd.itemDetailStatusId < ItemDetailStatusCode.Shipped
    );
    return ok;
  }

  canAddEditReturnRequest(joh: JoHeader) {
    return joh && joh.joStatusId != JoStatusCode.OpenProcessIncomplete 
      && joh.invoiceHeaders?.filter(invh => invh.invoiceStatusId == InvoiceStatusCode.InvoicePosted).length > 0;
  }

  hasAddonDocs(jodAddon: JoDetailAddon) {
    return jodAddon?.addon.addonDocMaps.length > 0;
  }

  async viewAddonDocs(jodAddon: JoDetailAddon) {
    // const files = jode.addon.addonDocs.map(d => {
    //   const byteArray = stringToByteArray(d.doc);
    //   const file = new File([byteArray], d.fileName, { type: d.mimeType });
    //   return file;
    // });

    const data: FileViewDialogData = { 
      title: 'View Addon Document',
      docs: jodAddon.addon.addonDocMaps.map(x => x.addonDoc) 
    };
    await FileViewDialogComponent.show(this.matDialog, data);

  }

  async addEditReturnRequest(joh: JoHeader) {
    this.AgFns.busyGrid(this.johGridOptions, this.busyService, async () => {
      await this.queryReturnRequestDetails(joh);
      
      
      this.router.navigate(['/jo-return-req-auth'], { queryParams: { joHeaderId: joh.id.toString() }});
    });
    
  }

  async onTabChanged(evt: any) {
    this.currentTabLabel = evt.tab.textLabel;
    // hack: because of ag-grid issues with button redraw on ngIf'd tab.
    if (this.currentTabLabel == 'Details') {
      // no need to query they are always available
      this.joDetails = this.joDetails.slice(0);
    }
    if (this.currentTabLabel == 'Purchase Orders') {
      this.poDetails = this.poDetails.slice(0);
    }
    if (this.currentTabLabel === 'Invoices') {
      if (this.currentJoHeader != null) {
        await this.queryInvoices(this.currentJoHeader);
      }
    } else if (this.currentTabLabel === 'Returns') {
      if (this.currentJoHeader != null) {
        await this.queryReturnRequestDetails(this.currentJoHeader);
      }
    }
  }

  async goJoInProcess(joh: JoHeader) {
    if (this.updateCurrentJoh(joh)) {
      const params = NavFns.buildNavIdParams(joh.id, 'id');
      this.router.navigate(['/jo-in-process'], params);
    }
  }

  async goInvoicesForJo(joh: JoHeader, editOrView: 'E' | 'V') {
    if (this.updateCurrentJoh(joh)) {
      const filter = (editOrView == 'E')
        ? (invh) => invh.invoiceStatusId !== InvoiceStatusCode.InvoicePosted
        : (invh) => invh.invoiceStatusId == InvoiceStatusCode.InvoicePosted;
      const invh = _.find(joh.invoiceHeaders, filter);
      this.router.navigate(['/jo-invoice', joh.id, invh?.id ?? 0]);
    }
  }

  updateCurrentJoh(joh: JoHeader) {
    if (joh == null) {
      return false;
    }
    if (joh !== this.currentJoHeader) {
      this.currentJoHeader = joh;
      this.updateLocation();
    }
    return true;
  }

  async onJohCellValueChanged(event) {
    // if (event != null) {
    //   if (event.oldValue != null || event.newValue != null) {
    //     this.currentProductType.eOQModTs = new Date(Date.now());
    //     this.ptGridOptions.api.redrawRows();
    //   }
    // }

    if (event?.column?.colId == 'shipFrequencyId') {
      // if (event.newValue != EOQTypeCode.None) {
      //   // await this.getProductSummaries(this.currentProductType.id);
      //   // this.prodGridOptions.api.redrawRows();
      //   // AgFns.autoSizeAllColumns(this.prodGridOptions);
      // }
      
    }
    
    
      // this.prodGridOptions.api.stopEditing();
    
  }


  goReturnRequest() {
    this.router.navigate(['/jo-return-req-auth']);
  }

  goShipping() {
    this.router.navigate(['/jo-multi-ship']);
  }

  goAudit() {
    this.router.navigate(['/jo-audit']);
  }

  goManifests() {
    this.router.navigate(['/manifests']);
  }

  goInvoices() {
    this.router.navigate(['/invoices']);
  }


}
